// Models
import {
  grChecklistCreateLoader,
  grChecklistLoader,
  stockAdjCreateLoader,
  stockAdjDetailLoader,
  workOrderCreateLoader,
  workOrderDraftLoader,
} from "@/loader";
import { config } from "@/models/constant/routes.constant";
import { RouteConfig } from "vue-router";

const index: RouteConfig = {
  path: config.LOGISTIC_ROUTE.path,
  name: config.LOGISTIC_ROUTE.name,
  meta: config.LOGISTIC_ROUTE.meta,
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: config.CLOSEPERIODELOGISTIC_ROUTE.path,
      name: config.CLOSEPERIODELOGISTIC_ROUTE.name,
      meta: config.CLOSEPERIODELOGISTIC_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./ClosePeriode.vue"),
    },
    {
      path: config.GOODS_RECEIPT_CHECKLIST_LIST_ROUTE.path,
      name: config.GOODS_RECEIPT_CHECKLIST_LIST_ROUTE.name,
      meta: config.GOODS_RECEIPT_CHECKLIST_LIST_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./goods-receipt-checklist/ListGoodReceiveChecklist.vue"
        ),
    },
    {
      path: config.GOODS_RECEIPT_CHECKLIST_CREATE_ROUTE.path,
      name: config.GOODS_RECEIPT_CHECKLIST_CREATE_ROUTE.name,
      meta: config.GOODS_RECEIPT_CHECKLIST_CREATE_ROUTE.meta,
      beforeEnter: grChecklistCreateLoader,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./goods-receipt-checklist/FormPage.vue"
        ),
    },
    {
      path: config.GOODS_RECEIPT_CHECKLIST_EDIT_ROUTE.path,
      name: config.GOODS_RECEIPT_CHECKLIST_EDIT_ROUTE.name,
      meta: config.GOODS_RECEIPT_CHECKLIST_EDIT_ROUTE.meta,
      beforeEnter: grChecklistLoader,
      props: true,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./goods-receipt-checklist/FormPage.vue"
        ),
    },
    {
      path: config.GOODS_RECEIPT_CHECKLIST_DETAIL_ROUTE.path,
      name: config.GOODS_RECEIPT_CHECKLIST_DETAIL_ROUTE.name,
      meta: config.GOODS_RECEIPT_CHECKLIST_DETAIL_ROUTE.meta,
      beforeEnter: grChecklistLoader,
      props: true,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./goods-receipt-checklist/DetailPage.vue"
        ),
    },
    {
      path: config.STOCK_REPORT_INDEX_ROUTE.path,
      name: config.STOCK_REPORT_INDEX_ROUTE.name,
      meta: config.STOCK_REPORT_INDEX_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./reports/StockReport/IndexPage.vue"
        ),
    },
    {
      path: config.REPORTS_CUSTOMER_MAINTENANCE_ROUTE.path,
      name: config.REPORTS_CUSTOMER_MAINTENANCE_ROUTE.name,
      meta: config.REPORTS_CUSTOMER_MAINTENANCE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/CustomerMaintenance.vue"),
    },
    {
      path: config.MASTER_PART_ROUTE.path,
      name: config.MASTER_PART_ROUTE.name,
      meta: config.MASTER_PART_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./product/IndexPage.vue"),
    },
    {
      path: config.MASTER_PART_CREATE_ROUTE.path,
      name: config.MASTER_PART_CREATE_ROUTE.name,
      meta: config.MASTER_PART_CREATE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./product/FormPage.vue"),
    },
    {
      path: config.MASTER_PART_EDIT_ROUTE.path,
      name: config.MASTER_PART_EDIT_ROUTE.name,
      meta: config.MASTER_PART_EDIT_ROUTE.meta,
      props: true,
      component: () =>
        import(/* webpackPrefetch: true */ "./product/FormPage.vue"),
    },
    {
      path: config.PRODUCT_CATEGORY_INDEX_ROUTE.path,
      name: config.PRODUCT_CATEGORY_INDEX_ROUTE.name,
      meta: config.PRODUCT_CATEGORY_INDEX_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./product-category/IndexPage.vue"),
    },
    {
      path: config.PRODUCT_CATEGORY_CREATE_ROUTE.path,
      name: config.PRODUCT_CATEGORY_CREATE_ROUTE.name,
      meta: config.PRODUCT_CATEGORY_CREATE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./product-category/FormPage.vue"),
    },
    {
      path: config.PRODUCT_CATEGORY_EDIT_ROUTE.path,
      name: config.PRODUCT_CATEGORY_EDIT_ROUTE.name,
      meta: config.PRODUCT_CATEGORY_EDIT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./product-category/FormPage.vue"),
    },
    {
      path: config.WAREHOUSE_INDEX_ROUTE.path,
      name: config.WAREHOUSE_INDEX_ROUTE.name,
      meta: config.WAREHOUSE_INDEX_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./warehouse/IndexPage.vue"),
    },
    {
      path: config.BRANCH_INDEX_ROUTE.path,
      name: config.BRANCH_INDEX_ROUTE.name,
      meta: config.BRANCH_INDEX_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./branch/IndexPage.vue"),
    },
    {
      path: config.RACK_INDEX_ROUTE.path,
      name: config.RACK_INDEX_ROUTE.name,
      meta: config.RACK_INDEX_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./rack/IndexPage.vue"),
    },
    {
      path: config.WAREHOUSETRANSFER_ROUTE.path,
      name: config.WAREHOUSETRANSFER_ROUTE.name,
      meta: config.WAREHOUSETRANSFER_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./warehouse-transfer/CreateWarehouseTransfer.vue"
        ),
    },
    {
      path: config.WAREHOUSETRANSFER_UPDATE_ROUTE.path,
      name: config.WAREHOUSETRANSFER_UPDATE_ROUTE.name,
      meta: config.WAREHOUSETRANSFER_UPDATE_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./warehouse-transfer/UpdateWarehouseTransfer.vue"
        ),
    },
    {
      path: config.WAREHOUSETRANSFER_RECEIVE_ROUTE.path,
      name: config.WAREHOUSETRANSFER_RECEIVE_ROUTE.name,
      meta: config.WAREHOUSETRANSFER_RECEIVE_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./warehouse-transfer/ReceiveWarehouseTransfer.vue"
        ),
    },
    {
      path: config.WAREHOUSETRANSFER_DETAIL_ROUTE.path,
      name: config.WAREHOUSETRANSFER_DETAIL_ROUTE.name,
      meta: config.WAREHOUSETRANSFER_DETAIL_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./warehouse-transfer/DetailWarehouseTransfer.vue"
        ),
    },
    {
      path: config.WAREHOUSETRANSFERLIST_ROUTE.path,
      name: config.WAREHOUSETRANSFERLIST_ROUTE.name,
      meta: config.WAREHOUSETRANSFERLIST_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./warehouse-transfer/WarehouseTransferList.vue"
        ),
    },
    {
      path: config.RETURNFROMCUSTOMER_ROUTE.path,
      name: config.RETURNFROMCUSTOMER_ROUTE.name,
      meta: config.RETURNFROMCUSTOMER_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./return-form-customer/ListReturnFromCustomer.vue"
        ),
    },
    {
      path: config.CSF_LIST_ROUTE.path,
      name: config.CSF_LIST_ROUTE.name,
      meta: config.CSF_LIST_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./work-order/ListCSF.vue"),
    },
    {
      path: config.TRANSFER_TO_ASSET_ROUTE.path,
      name: config.TRANSFER_TO_ASSET_ROUTE.name,
      meta: config.TRANSFER_TO_ASSET_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./transfer-to-asset/TransferToAssetList.vue"
        ),
    },
    {
      path: config.CREATE_TRANSFER_TO_ASSET_ROUTE.path,
      name: config.CREATE_TRANSFER_TO_ASSET_ROUTE.name,
      meta: config.CREATE_TRANSFER_TO_ASSET_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./transfer-to-asset/TransferToAssetForm.vue"
        ),
    },
    {
      path: config.VIEW_TRANSFER_TO_ASSET_ROUTE.path,
      name: config.VIEW_TRANSFER_TO_ASSET_ROUTE.name,
      meta: config.VIEW_TRANSFER_TO_ASSET_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./transfer-to-asset/TransferToAssetForm.vue"
        ),
    },
    {
      path: config.RETURNFROMCUSTOMERCREATE_ROUTE.path,
      name: config.RETURNFROMCUSTOMERCREATE_ROUTE.name,
      meta: config.RETURNFROMCUSTOMERCREATE_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./return-form-customer/ReturnFromCustomer.vue"
        ),
    },
    {
      path: config.RETURNFROMCUSTOMEREDIT_ROUTE.path,
      name: config.RETURNFROMCUSTOMEREDIT_ROUTE.name,
      meta: config.RETURNFROMCUSTOMEREDIT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./return-form-customer/ReturnFromCustomer.vue"
        ),
    },
    {
      path: config.STOCK_ADJUSTMENT_INDEX_ROUTE.path,
      name: config.STOCK_ADJUSTMENT_INDEX_ROUTE.name,
      meta: config.STOCK_ADJUSTMENT_INDEX_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./stock-adjustment/StockAdjustmentList.vue"
        ),
    },
    {
      path: config.STOCK_ADJUSTMENT_CREATE_ROUTE.path,
      name: config.STOCK_ADJUSTMENT_CREATE_ROUTE.name,
      meta: config.STOCK_ADJUSTMENT_CREATE_ROUTE.meta,
      beforeEnter: stockAdjCreateLoader,
      component: () =>
        import(/* webpackPrefetch: true */ "./stock-adjustment/FormPage.vue"),
    },
    {
      path: config.STOCK_ADJUSTMENT_EDIT_ROUTE.path,
      name: config.STOCK_ADJUSTMENT_EDIT_ROUTE.name,
      meta: config.STOCK_ADJUSTMENT_EDIT_ROUTE.meta,
      props: true,
      beforeEnter: stockAdjDetailLoader,
      component: () =>
        import(/* webpackPrefetch: true */ "./stock-adjustment/FormPage.vue"),
    },
    {
      path: config.STOCK_ADJUSTMENT_DETAIL_ROUTE.path,
      name: config.STOCK_ADJUSTMENT_DETAIL_ROUTE.name,
      meta: config.STOCK_ADJUSTMENT_DETAIL_ROUTE.meta,
      props: true,
      beforeEnter: stockAdjDetailLoader,
      component: () =>
        import(/* webpackPrefetch: true */ "./stock-adjustment/DetailPage.vue"),
    },
    {
      path: config.REPORT_STOCK_ADJUSMENT_ROUTE.path,
      name: config.REPORT_STOCK_ADJUSMENT_ROUTE.name,
      meta: config.REPORT_STOCK_ADJUSMENT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./reports/ReportStockAdjustment.vue"
        ),
    },
    {
      path: config.WORK_ORDER_CREATE_ROUTE.path,
      name: config.WORK_ORDER_CREATE_ROUTE.name,
      meta: config.WORK_ORDER_CREATE_ROUTE.meta,
      beforeEnter: workOrderCreateLoader,
      component: () =>
        import(/* webpackPrefetch: true */ "./work-order/FormPage.vue"),
    },
    {
      path: config.WORK_ORDER_DETAIL_ROUTE.path,
      name: config.WORK_ORDER_DETAIL_ROUTE.name,
      meta: config.WORK_ORDER_DETAIL_ROUTE.meta,
      props: true,
      beforeEnter: workOrderDraftLoader,
      component: () =>
        import(/* webpackPrefetch: true */ "./work-order/DetailPage.vue"),
    },
    {
      path: config.WORK_ORDER_LIST_ROUTE.path,
      name: config.WORK_ORDER_LIST_ROUTE.name,
      meta: config.WORK_ORDER_LIST_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./work-order/IndexPage.vue"),
    },
    {
      path: config.RESULT_WORK_ORDER_LIST_ROUTE.path,
      name: config.RESULT_WORK_ORDER_LIST_ROUTE.name,
      meta: config.RESULT_WORK_ORDER_LIST_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./result-work-order/ResultWorkOrder.vue"
        ),
    },
    {
      path: config.RESULT_WORK_ORDER_CREATE_ROUTE.path,
      name: config.RESULT_WORK_ORDER_CREATE_ROUTE.name,
      meta: config.RESULT_WORK_ORDER_CREATE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./result-work-order/FormPage.vue"),
    },
    {
      path: config.RESULT_WORK_ORDER_VIEW_ROUTE.path,
      name: config.RESULT_WORK_ORDER_VIEW_ROUTE.name,
      meta: config.RESULT_WORK_ORDER_VIEW_ROUTE.meta,
      props: true,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./result-work-order/DetailPage.vue"
        ),
    },
    {
      path: config.PRODUCT_INVENTORY_ROUTE.path,
      name: config.PRODUCT_INVENTORY_ROUTE.name,
      meta: config.PRODUCT_INVENTORY_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./product-inventory/IndexPage.vue"),
    },
    {
      path: config.REPORTKARTU_ROUTE.path,
      name: config.REPORTKARTU_ROUTE.name,
      meta: config.REPORTKARTU_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/ReportValuation.vue"),
    },
    {
      path: config.FIFO_REPORT_ROUTE.path,
      name: config.FIFO_REPORT_ROUTE.name,
      meta: config.FIFO_REPORT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/FifoReport.vue"),
    },
    {
      path: config.SPAREPART_REQUEST_FORM_ROUTE.path,
      name: config.SPAREPART_REQUEST_FORM_ROUTE.name,
      meta: config.SPAREPART_REQUEST_FORM_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./sparepart-request/SparepartRequest.vue"
        ),
    },
    {
      path: config.CREATE_SPAREPART_REQUEST_FORM_ROUTE.path,
      name: config.CREATE_SPAREPART_REQUEST_FORM_ROUTE.name,
      meta: config.CREATE_SPAREPART_REQUEST_FORM_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./sparepart-request/CreateSparepartRequest.vue"
        ),
    },
    {
      path: config.CREATE_WORK_ORDER_FROM_SPARE_PART_REQUEST.path,
      name: config.CREATE_WORK_ORDER_FROM_SPARE_PART_REQUEST.name,
      meta: config.CREATE_WORK_ORDER_FROM_SPARE_PART_REQUEST.meta,
      props: true,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./sparepart-request/CreateWorkOrder.vue"
        ),
    },
    {
      path: config.SPARE_PART_REQUEST_EDIT_ROUTE.path,
      name: config.SPARE_PART_REQUEST_EDIT_ROUTE.name,
      meta: config.SPARE_PART_REQUEST_EDIT_ROUTE.meta,
      props: true,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./sparepart-request/CreateSparepartRequest.vue"
        ),
    },
    {
      path: config.SPARE_PART_REQUEST_DETAIL_ROUTE.path,
      name: config.SPARE_PART_REQUEST_DETAIL_ROUTE.name,
      meta: config.SPARE_PART_REQUEST_DETAIL_ROUTE.meta,
      props: true,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./sparepart-request/DetailSparePartRequest.vue"
        ),
    },
    {
      path: config.MAINTENANCE_REPORT_ROUTE.path,
      name: config.MAINTENANCE_REPORT_ROUTE.name,
      meta: config.MAINTENANCE_REPORT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/ReportMaintenance.vue"),
    },
  ],
};

export default index;
