import { useTaxRate } from "@/hooks/tax";
import { Mutations } from "@/models/constant/enums/mutations.enum";
import { DATE_FORMAT_YYYY_MM_DD } from "@/models/constants/date.constant";
import { IPreferencesResponseDto } from "@/models/interface/preference";
import { taxBaseVariableService } from "@/services/tax-base-variable.service";
import store from "@/store";
import moment from "moment";
import { NavigationGuard } from "vue-router";

export const salesOrderCreateLoader: NavigationGuard = async (__, _, next) => {
  try {
    const salesInclusiveTaxRate: IPreferencesResponseDto | undefined =
      store.getters["preferenceStore/GET_PREFERENCE_BY_KEY"](
        "feature_sales_inclusive_tax_rate"
      );
    if (salesInclusiveTaxRate && salesInclusiveTaxRate.value) {
      const response = await useTaxRate(salesInclusiveTaxRate.value);
      store.commit("preferenceStore/setSalesInclusiveTaxRate", response);
    } else {
      store.commit("preferenceStore/setSalesInclusiveTaxRate", undefined);
    }
    const taxBase = await taxBaseVariableService.getVariable({
      isLuxury: false,
      transactionDate: moment().format(DATE_FORMAT_YYYY_MM_DD),
    });
    store.commit("salesOrderStore/setForm", {
      taxBaseVariable: taxBase.value ?? 1,
    });
    next();
  } finally {
    store.commit(Mutations.SET_LOADING_COMPONENT, false);
  }
};
