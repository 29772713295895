import { Api } from "@/models/class/api.class";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import {
  TaxBaseVariableDetailDto,
  TaxBaseVariableDto,
  TaxBaseVariableListDto,
  TaxBaseVariableParams,
} from "@/models/interface/tax-base-variable";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class TaxBaseVariableService extends HttpClient {
  findAll(): Promise<TaxBaseVariableListDto[]> {
    return this.get<TaxBaseVariableListDto[]>(Api.TaxBaseVariable)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  findOne(id: string): Promise<TaxBaseVariableDetailDto> {
    return this.get<TaxBaseVariableListDto>(`${Api.TaxBaseVariable}/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  create(payload: TaxBaseVariableDto): Promise<boolean> {
    return this.post<boolean, TaxBaseVariableDto>(Api.TaxBaseVariable, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  update(id: string, payload: TaxBaseVariableDto): Promise<boolean> {
    return this.put<boolean, TaxBaseVariableDto>(
      `${Api.TaxBaseVariable}/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getVariable(
    params: TaxBaseVariableParams
  ): Promise<TaxBaseVariableDetailDto> {
    return this.get<TaxBaseVariableDetailDto>(Api.TaxBaseVariableTransaction, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  deleteVariable(id: string): Promise<TaxBaseVariableDetailDto> {
    return this.delete<TaxBaseVariableDetailDto>(`${Api.TaxBaseVariable}/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const taxBaseVariableService = new TaxBaseVariableService();
