var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "fieldset",
      [
        _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_general")) + " ")]),
        _c(
          "a-form-model",
          {
            ref: "form",
            attrs: {
              model: _vm.store.form,
              rules: _vm.formRules,
              "wrapper-col": { span: 12 },
              "label-col": { span: 8 },
              "label-align": "left"
            }
          },
          [
            _c(
              "a-row",
              { attrs: { gutter: [16, 16] } },
              [
                _c(
                  "a-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_category"),
                          prop: "categoryName"
                        }
                      },
                      [
                        _c("SelectProductCategory", {
                          on: { "update:meta": _vm.onChangeProductCategory },
                          model: {
                            value: _vm.store.form.categoryName,
                            callback: function($$v) {
                              _vm.$set(_vm.store.form, "categoryName", $$v)
                            },
                            expression: "store.form.categoryName"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: _vm.$t("lbl_type"), prop: "type" } },
                      [
                        _c("SelectProductType", {
                          on: { change: _vm.onChangeProductType },
                          model: {
                            value: _vm.store.form.type,
                            callback: function($$v) {
                              _vm.$set(_vm.store.form, "type", $$v)
                            },
                            expression: "store.form.type"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: { label: _vm.$t("lbl_part_name"), prop: "name" }
                      },
                      [
                        _c("a-input", {
                          attrs: { "allow-clear": "" },
                          model: {
                            value: _vm.store.form.name,
                            callback: function($$v) {
                              _vm.$set(_vm.store.form, "name", $$v)
                            },
                            expression: "store.form.name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: { label: _vm.$t("lbl_part_code"), prop: "code" }
                      },
                      [
                        _c("a-input", {
                          attrs: {
                            disabled: !_vm.store.detailProduct.updatable,
                            "allow-clear": ""
                          },
                          model: {
                            value: _vm.store.form.code,
                            callback: function($$v) {
                              _vm.$set(_vm.store.form, "code", $$v)
                            },
                            expression: "store.form.code"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_description"),
                          prop: "description"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "extra",
                            fn: function() {
                              return [
                                _c("character-length", {
                                  attrs: {
                                    value: _vm.store.form.description || ""
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("a-textarea", {
                          attrs: { rows: 4 },
                          model: {
                            value: _vm.store.form.description,
                            callback: function($$v) {
                              _vm.$set(_vm.store.form, "description", $$v)
                            },
                            expression: "store.form.description"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: _vm.$t("lbl_brand"), prop: "merk" } },
                      [
                        _c("master-product-merk-select", {
                          model: {
                            value: _vm.store.form.merk,
                            callback: function($$v) {
                              _vm.$set(_vm.store.form, "merk", $$v)
                            },
                            expression: "store.form.merk"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: _vm.$t("lbl_uom"), prop: "baseUnit" } },
                      [
                        _c("SelectProductUom", {
                          on: { "update:meta": _vm.onChangeUom },
                          model: {
                            value: _vm.store.form.baseUnit,
                            callback: function($$v) {
                              _vm.$set(_vm.store.form, "baseUnit", $$v)
                            },
                            expression: "store.form.baseUnit"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_minimum_stock"),
                          prop: "minimumStock"
                        }
                      },
                      [
                        _c("a-input-number", {
                          attrs: {
                            parser: _vm.reverseFormatNumber,
                            formatter: _vm.formatterNumber,
                            precision: _vm.storeBaseDecimalPlace,
                            min: 0
                          },
                          model: {
                            value: _vm.store.form.minimumStock,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.store.form,
                                "minimumStock",
                                _vm._n($$v)
                              )
                            },
                            expression: "store.form.minimumStock"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_sales_tax_rate"),
                          "label-align": "right",
                          prop: "productAccount.salesTax"
                        }
                      },
                      [
                        _c("SelectTaxVatOut", {
                          attrs: { "label-in-value": "" },
                          scopedSlots: _vm._u([
                            {
                              key: "option",
                              fn: function(ref) {
                                var option = ref.option
                                return [
                                  _vm._v(" " + _vm._s(option.label) + " ")
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.store.form.productAccount.salesTax,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.store.form.productAccount,
                                "salesTax",
                                $$v
                              )
                            },
                            expression: "store.form.productAccount.salesTax"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_purchase_tax_rate"),
                          "label-align": "right",
                          prop: "productAccount.purchaseTax"
                        }
                      },
                      [
                        _c("SelectTaxVatIn", {
                          attrs: { "label-in-value": "" },
                          scopedSlots: _vm._u([
                            {
                              key: "option",
                              fn: function(ref) {
                                var option = ref.option
                                return [
                                  _vm._v(" " + _vm._s(option.label) + " ")
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.store.form.productAccount.purchaseTax,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.store.form.productAccount,
                                "purchaseTax",
                                $$v
                              )
                            },
                            expression: "store.form.productAccount.purchaseTax"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          "label-align": "right",
                          label: _vm.$t("lbl_barcode"),
                          prop: "barcode"
                        }
                      },
                      [
                        _c("a-input", {
                          attrs: { "allow-clear": "" },
                          model: {
                            value: _vm.store.form.barcode,
                            callback: function($$v) {
                              _vm.$set(_vm.store.form, "barcode", $$v)
                            },
                            expression: "store.form.barcode"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          "label-align": "right",
                          label: _vm.$t("lbl_product_or_service_code"),
                          prop: "serviceCode"
                        }
                      },
                      [
                        _c("a-input", {
                          attrs: { "allow-clear": "" },
                          model: {
                            value: _vm.store.form.serviceCode,
                            callback: function($$v) {
                              _vm.$set(_vm.store.form, "serviceCode", $$v)
                            },
                            expression: "store.form.serviceCode"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          "label-align": "right",
                          label: _vm.$t("lbl_default_location_receipt"),
                          prop: "locationReceive"
                        }
                      },
                      [
                        _c("SelectRack", {
                          attrs: { "value-as": "id" },
                          model: {
                            value: _vm.store.form.locationReceive,
                            callback: function($$v) {
                              _vm.$set(_vm.store.form, "locationReceive", $$v)
                            },
                            expression: "store.form.locationReceive"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_track_as_inventory"),
                          prop: "trackAsInventory",
                          "label-align": "right"
                        }
                      },
                      [
                        _c("a-switch", {
                          attrs: { disabled: _vm.isTypeStockable },
                          model: {
                            value: _vm.store.form.trackAsInventory,
                            callback: function($$v) {
                              _vm.$set(_vm.store.form, "trackAsInventory", $$v)
                            },
                            expression: "store.form.trackAsInventory"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_track_as_asset"),
                          prop: "trackAsAsset",
                          "label-align": "right"
                        }
                      },
                      [
                        _c("a-switch", {
                          model: {
                            value: _vm.store.form.trackAsAsset,
                            callback: function($$v) {
                              _vm.$set(_vm.store.form, "trackAsAsset", $$v)
                            },
                            expression: "store.form.trackAsAsset"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_track_as_purchase"),
                          prop: "trackAsPurchase",
                          "label-align": "right"
                        }
                      },
                      [
                        _c("a-switch", {
                          model: {
                            value: _vm.store.form.trackAsPurchase,
                            callback: function($$v) {
                              _vm.$set(_vm.store.form, "trackAsPurchase", $$v)
                            },
                            expression: "store.form.trackAsPurchase"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_track_as_sell"),
                          prop: "trackAsSell",
                          "label-align": "right"
                        }
                      },
                      [
                        _c("a-switch", {
                          model: {
                            value: _vm.store.form.trackAsSell,
                            callback: function($$v) {
                              _vm.$set(_vm.store.form, "trackAsSell", $$v)
                            },
                            expression: "store.form.trackAsSell"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_active"),
                          prop: "active",
                          "label-align": "right"
                        }
                      },
                      [
                        _c("a-switch", {
                          model: {
                            value: _vm.store.form.active,
                            callback: function($$v) {
                              _vm.$set(_vm.store.form, "active", $$v)
                            },
                            expression: "store.form.active"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "fieldset",
      [
        _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_image")) + " ")]),
        !!_vm.store.detailProduct.image && !_vm.allowEditImage
          ? [
              _c(
                "a-space",
                { attrs: { direction: "horizontal" } },
                [
                  _c("figure", [
                    _c("img", {
                      staticClass: "img",
                      attrs: {
                        src: _vm.store.detailProduct.image,
                        alt: "product image"
                      }
                    })
                  ]),
                  _c("a-button", {
                    attrs: { type: "primary", icon: "edit" },
                    on: { click: _vm.editImage }
                  })
                ],
                1
              )
            ]
          : _c("ImageUploader", {
              attrs: { module: "product" },
              on: { onSuccess: _vm.onUploadSuccess }
            })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }