var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          !_vm.allowEdit
            ? _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleAddRow }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "danger" },
                          on: { click: _vm.handleDeleteRow }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-table", {
                attrs: {
                  size: "small",
                  "data-source": _vm.storeAPForm.products,
                  columns: _vm.columnsTable,
                  scroll: { x: "calc(2100px + 50%)" },
                  "row-class-name": function(_, index) {
                    return index % 2 ? "bg-white" : "bg-gray-light"
                  },
                  "row-selection": {
                    selectedRowKeys: _vm.selectedRowKeys,
                    onChange: _vm.onSelectRow
                  },
                  pagination: {
                    showTotal: function(total) {
                      return _vm.$t("lbl_total_items", { total: total })
                    }
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "partNumber",
                    fn: function(text, record) {
                      return [
                        record.generated || _vm.allowEdit
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    record.partNumber
                                      ? record.partNumber.label
                                      : "-"
                                  ) +
                                  " "
                              )
                            ])
                          : _c("a-select", {
                              staticClass: "w-100",
                              attrs: {
                                value: record.partNumber,
                                "label-in-value": "",
                                "show-search": "",
                                "allow-clear": "",
                                "filter-option": false,
                                options: record.isSearchProductServiceCode
                                  ? record.productServiceCodeOptions
                                  : _vm.productServiceCodeOptions,
                                loading: record.loadingProductServiceCode,
                                placeholder: _vm.$t("common.select-text", {
                                  text: _vm.$t("lbl_part_number")
                                }),
                                "dropdown-match-select-width": false
                              },
                              on: {
                                search: function($event) {
                                  return _vm.onSearchPartService(
                                    record,
                                    "code",
                                    $event
                                  )
                                },
                                change: function($event) {
                                  return _vm.onChangePartService(
                                    record,
                                    "code",
                                    $event
                                  )
                                }
                              }
                            })
                      ]
                    }
                  },
                  {
                    key: "partName",
                    fn: function(text, record) {
                      return [
                        record.generated || _vm.allowEdit
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    record.partName
                                      ? record.partName.label
                                      : "-"
                                  ) +
                                  " "
                              )
                            ])
                          : _c("a-select", {
                              staticClass: "w-100",
                              attrs: {
                                value: record.partName,
                                "label-in-value": "",
                                "show-search": "",
                                "allow-clear": "",
                                "filter-option": false,
                                options: record.isSearchProductServiceName
                                  ? record.productServiceNameOptions
                                  : _vm.productServiceNameOptions,
                                loading: record.loadingProductServiceName,
                                placeholder: _vm.$t("common.select-text", {
                                  text: _vm.$t("lbl_part_name")
                                }),
                                "dropdown-match-select-width": false
                              },
                              on: {
                                search: function($event) {
                                  return _vm.onSearchPartService(
                                    record,
                                    "name",
                                    $event
                                  )
                                },
                                change: function($event) {
                                  return _vm.onChangePartService(
                                    record,
                                    "name",
                                    $event
                                  )
                                }
                              }
                            })
                      ]
                    }
                  },
                  {
                    key: "brand",
                    fn: function(text, record) {
                      return [
                        record.generated || _vm.allowEdit
                          ? _c("span", [_vm._v(_vm._s(record.brand || "-"))])
                          : _c("a-select", {
                              staticClass: "w-100",
                              attrs: {
                                value: record.brand,
                                "show-search": "",
                                "allow-clear": "",
                                "filter-option": false,
                                options: record.isSearchBrand
                                  ? record.brandOptions
                                  : _vm.brandOptions,
                                loading: record.loadingBrand,
                                placeholder: _vm.$t("common.select-text", {
                                  text: _vm.$t("lbl_brand")
                                }),
                                "dropdown-match-select-width": false
                              },
                              on: {
                                search: function($event) {
                                  return _vm.onSearchBrand(record, $event)
                                },
                                change: function($event) {
                                  return _vm.onChangeBrand(record, $event)
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "dropdownRender",
                                    fn: function(menu) {
                                      return [
                                        _c("ListProductBrand", {
                                          attrs: { items: menu }
                                        }),
                                        _c("div", { staticClass: "mt-2" }),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              padding: "8px",
                                              cursor: "pointer"
                                            },
                                            on: {
                                              mousedown: function(e) {
                                                return e.preventDefault()
                                              },
                                              click: function($event) {
                                                return _vm.addNewBrand(record)
                                              }
                                            }
                                          },
                                          [
                                            _c("a-icon", {
                                              attrs: { type: _vm.iconBtnBrand }
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("lbl_add")) +
                                                " "
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                      ]
                    }
                  },
                  {
                    key: "qty",
                    fn: function(text, record) {
                      return [
                        _c("a-input-number", {
                          attrs: {
                            placeholder: _vm.$t(_vm.formRules.qty.placeholder),
                            value: record.qty,
                            formatter: _vm.formatterNumber,
                            parser: _vm.reverseFormatNumber,
                            min: 0,
                            precision: _vm.storeBaseDecimalPlace,
                            "allow-clear": "",
                            disabled: _vm.allowEdit
                          },
                          on: {
                            change: function(e) {
                              return _vm.onChangeQty(e, record)
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "price",
                    fn: function(text, record) {
                      return [
                        _c("a-input-number", {
                          staticClass: "w-100",
                          attrs: {
                            placeholder: _vm.$t(
                              _vm.formRules.price.placeholder
                            ),
                            value: record.price,
                            formatter: _vm.formatterNumber,
                            parser: _vm.reverseFormatNumber,
                            min: 0,
                            precision: _vm.storeBaseDecimalPlace,
                            "allow-clear": "",
                            disabled: _vm.allowEdit
                          },
                          on: {
                            change: function(e) {
                              return _vm.onChangePrice(
                                e,
                                record.no - 1,
                                "table"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "expenseAccount",
                    fn: function(text, record) {
                      return [
                        record.generated || _vm.allowEdit
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    record.expenseAccount
                                      ? record.expenseAccount.label
                                      : "-"
                                  ) +
                                  " "
                              )
                            ])
                          : _c("a-select", {
                              staticClass: "w-100",
                              attrs: {
                                value: record.expenseAccount,
                                "label-in-value": "",
                                "show-search": "",
                                "allow-clear": "",
                                "filter-option": false,
                                disabled: _vm.allowEdit,
                                options: record.isSearchExpenseAccount
                                  ? record.expenseAccountOptions
                                  : _vm.expenseAccountOptions,
                                loading: record.loadingExpenseAccount,
                                placeholder: _vm.$t("common.select-text", {
                                  text: _vm.$t("lbl_expense_account")
                                }),
                                "dropdown-match-select-width": false
                              },
                              on: {
                                search: function($event) {
                                  return _vm.onSearchExpenseAccount(
                                    record,
                                    $event
                                  )
                                },
                                change: function($event) {
                                  return _vm.onChangeExpenseAccount(
                                    record,
                                    $event
                                  )
                                }
                              }
                            })
                      ]
                    }
                  },
                  {
                    key: "incomeTax",
                    fn: function(text, record) {
                      return [
                        _vm.allowEdit
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  record.incomeTax
                                    ? record.incomeTax.label
                                    : "-"
                                )
                              )
                            ])
                          : _c("a-select", {
                              staticClass: "w-100",
                              attrs: {
                                value: record.incomeTax,
                                "label-in-value": "",
                                "show-search": "",
                                "allow-clear": "",
                                "filter-option": false,
                                disabled: _vm.allowEdit,
                                options: record.isSearchIncomeTax
                                  ? record.incomeTaxOptions
                                  : _vm.incomeTaxOptions,
                                loading: record.loadingIncomeTax,
                                placeholder: _vm.$t("common.select-text", {
                                  text: _vm.$t("lbl_income_tax")
                                }),
                                "dropdown-match-select-width": false
                              },
                              on: {
                                search: function($event) {
                                  return _vm.onSearchIncomeTax(record, $event)
                                },
                                change: function($event) {
                                  return _vm.onChangeIncomeTax(record, $event)
                                }
                              }
                            })
                      ]
                    }
                  },
                  {
                    key: "taxCode",
                    fn: function(text, record) {
                      return [
                        _vm.allowEdit
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    record.taxCode ? record.taxCode.label : "-"
                                  ) +
                                  " "
                              )
                            ])
                          : _c("a-select", {
                              staticClass: "w-100",
                              attrs: {
                                value: record.taxCode,
                                "label-in-value": "",
                                "show-search": "",
                                "allow-clear": "",
                                "filter-option": false,
                                disabled: _vm.allowEdit,
                                options: record.isSearchTaxCode
                                  ? record.taxCodeOptions
                                  : _vm.taxCodeOptions,
                                loading: record.loadingTaxCode,
                                placeholder: _vm.$t("common.select-text", {
                                  text: _vm.$t("lbl_income_tax")
                                }),
                                "dropdown-match-select-width": false
                              },
                              on: {
                                search: function($event) {
                                  return _vm.onSearchTaxCode(record, $event)
                                },
                                change: function($event) {
                                  return _vm.onChangeTaxCode(record, $event)
                                }
                              }
                            })
                      ]
                    }
                  },
                  {
                    key: "description",
                    fn: function(text, record) {
                      return [
                        _vm.allowEdit
                          ? _c("span", [
                              _vm._v(
                                " " + _vm._s(record.description || "-") + " "
                              )
                            ])
                          : _c("a-textarea", {
                              attrs: {
                                value: record.description,
                                "allow-clear": "",
                                disabled: _vm.allowEdit,
                                placeholder: _vm.$t("common.insert-text", {
                                  text: _vm.$t("lbl_description")
                                })
                              },
                              on: {
                                change: function(e) {
                                  return _vm.onChangeDescription(
                                    record,
                                    e.target.value
                                  )
                                }
                              }
                            })
                      ]
                    }
                  },
                  {
                    key: "additionalCost",
                    fn: function(text, record) {
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.showAdditionalCostModal(record)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  record.totalAdditionalCost !== 0
                                    ? _vm.currencyFilter(
                                        record.totalAdditionalCost
                                      )
                                    : _vm.$t("common.add-text", {
                                        text: _vm.$t("lbl_additional_cost")
                                      })
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "operation",
                    fn: function(text, record) {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "ant-dropdown-link",
                            on: {
                              click: function($event) {
                                return _vm.showMore(record)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("lbl_more")) + " ")]
                        )
                      ]
                    }
                  },
                  {
                    key: "footer",
                    fn: function() {
                      return [
                        _c(
                          "a-row",
                          {
                            staticClass: "flex-column align-items-md-end",
                            attrs: { type: "flex" }
                          },
                          [
                            _c("a-col", { attrs: { span: 10 } }, [
                              _c(
                                "table",
                                {
                                  staticClass: "footer-table",
                                  attrs: { "aria-describedby": "footer table" }
                                },
                                [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-bold text-right pr-3"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("lbl_total")) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      [
                                        _c("a-input-number", {
                                          staticClass: "w-100",
                                          attrs: {
                                            value: _vm.storeAPForm.totalGross,
                                            disabled: "",
                                            placeholder: _vm.$t("lbl_total"),
                                            formatter: _vm.formatterNumber,
                                            parser: _vm.reverseFormatNumber,
                                            min: 0,
                                            precision: _vm.storeBaseDecimalPlace
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-bold text-right pr-3"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "lbl_total_additional_cost"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      [
                                        _c("a-input-number", {
                                          staticClass: "w-100",
                                          attrs: {
                                            value:
                                              _vm.storeAPForm
                                                .totalAdditionalCost,
                                            disabled: "",
                                            placeholder: _vm.$t(
                                              "lbl_total_additional_cost"
                                            ),
                                            formatter: _vm.formatterNumber,
                                            parser: _vm.reverseFormatNumber,
                                            min: 0,
                                            precision: _vm.storeBaseDecimalPlace
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-bold text-right pr-3"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("lbl_total_prepayment")
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      [
                                        _c("a-input-number", {
                                          staticClass: "w-100",
                                          attrs: {
                                            value:
                                              _vm.storeAPForm
                                                .prePaymentUsedTotal,
                                            disabled: "",
                                            placeholder: _vm.$t(
                                              "lbl_total_prepayment"
                                            ),
                                            formatter: _vm.formatterNumber,
                                            parser: _vm.reverseFormatNumber,
                                            min: 0,
                                            precision: _vm.storeBaseDecimalPlace
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-bold text-right pr-3"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("lbl_discount")) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "a-row",
                                          {
                                            attrs: {
                                              gutter: [16, 16],
                                              type: "flex"
                                            }
                                          },
                                          [
                                            _c(
                                              "a-col",
                                              { attrs: { flex: "1" } },
                                              [
                                                _c("a-input-number", {
                                                  staticClass: "w-100",
                                                  attrs: {
                                                    value:
                                                      _vm.storeAPForm
                                                        .additionalDiscountPercent,
                                                    formatter:
                                                      _vm.formatterPercent,
                                                    parser:
                                                      _vm.reverseFormatPercent,
                                                    min: 0,
                                                    max: 100,
                                                    precision:
                                                      _vm.storeBaseDecimalPlace,
                                                    disabled: _vm.allowEdit
                                                  },
                                                  on: {
                                                    change: function(e) {
                                                      return _vm.onChangeAdditionalDiscount(
                                                        e,
                                                        "percent"
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-col",
                                              { attrs: { flex: "1" } },
                                              [
                                                _c("a-input-number", {
                                                  staticClass: "w-100",
                                                  attrs: {
                                                    value:
                                                      _vm.storeAPForm
                                                        .additionalDiscountAmount,
                                                    formatter:
                                                      _vm.formatterNumber,
                                                    parser:
                                                      _vm.reverseFormatNumber,
                                                    min: 0,
                                                    precision:
                                                      _vm.storeBaseDecimalPlace,
                                                    disabled: _vm.allowEdit
                                                  },
                                                  on: {
                                                    change: function(e) {
                                                      return _vm.onChangeAdditionalDiscount(
                                                        e,
                                                        "amount"
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-bold text-right pr-3"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("lbl_total_tax")) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      [
                                        _c("a-input-number", {
                                          staticClass: "w-100",
                                          attrs: {
                                            value: _vm.storeAPForm.totalTax,
                                            disabled: "",
                                            placeholder: _vm.$t(
                                              "lbl_total_tax"
                                            ),
                                            formatter: _vm.formatterNumber,
                                            parser: _vm.reverseFormatNumber,
                                            min: 0,
                                            precision: _vm.storeBaseDecimalPlace
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-bold text-right pr-3"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("lbl_grand_total")) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      [
                                        _c("a-input-number", {
                                          staticClass: "w-100",
                                          attrs: {
                                            value: _vm.storeAPForm.grandTotal,
                                            disabled: "",
                                            placeholder: _vm.$t(
                                              "lbl_grand_total"
                                            ),
                                            formatter: _vm.formatterNumber,
                                            parser: _vm.reverseFormatNumber,
                                            min: 0,
                                            precision: _vm.storeBaseDecimalPlace
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            centered: false,
            "dialog-style": { top: "20px" },
            maskClosable: false,
            width: 920,
            title: _vm.$t("lbl_details")
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSaveModal }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.modal.show,
            callback: function($$v) {
              _vm.$set(_vm.modal, "show", $$v)
            },
            expression: "modal.show"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              { attrs: { model: _vm.detailFormState } },
              "a-form-model",
              _vm.formItemLayout,
              false
            ),
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.partNumber.label)
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              value: _vm.detailFormState.partNumber,
                              name: _vm.formRules.partNumber.name,
                              disabled: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.partName.label) }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              value: _vm.detailFormState.partName,
                              name: _vm.formRules.partName.name,
                              disabled: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.partMerk.label) }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              value: _vm.detailFormState.partMerk,
                              name: _vm.formRules.partMerk.name,
                              disabled: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t(_vm.formRules.qty.label) } },
                        [
                          _c("a-input-number", {
                            attrs: {
                              value: _vm.detailFormState.qty,
                              name: _vm.formRules.qty.name,
                              placeholder: _vm.$t("common.insert-text", {
                                text: _vm.$t("lbl_quantity")
                              }),
                              formatter: _vm.formatterNumber,
                              parser: _vm.reverseFormatNumber,
                              min: 0,
                              precision: _vm.storeBaseDecimalPlace,
                              disabled: _vm.allowEdit
                            },
                            on: {
                              change: function(e) {
                                return (_vm.detailFormState.qty = e)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t(_vm.formRules.uom.label) } },
                        [
                          _c("a-input", {
                            attrs: {
                              value: _vm.detailFormState.uom,
                              disabled: ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t(_vm.formRules.price.label) } },
                        [
                          _c("a-input-number", {
                            staticClass: "w-100",
                            attrs: {
                              value: _vm.detailFormState.price,
                              name: _vm.formRules.price.name,
                              placeholder: _vm.$t("common.insert-text", {
                                text: _vm.$t("lbl_price")
                              }),
                              formatter: _vm.formatterNumber,
                              parser: _vm.reverseFormatNumber,
                              min: 0,
                              precision: _vm.storeBaseDecimalPlace,
                              disabled: _vm.allowEdit
                            },
                            on: {
                              change: function(e) {
                                return _vm.onChangePrice(
                                  e,
                                  _vm.modal.index,
                                  "modal"
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.discountValue.label)
                          }
                        },
                        [
                          _c(
                            "a-row",
                            { attrs: { gutter: [8, 8] } },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    { staticClass: "mb-0 pb-0" },
                                    [
                                      _c("a-input-number", {
                                        staticClass: "w-100",
                                        attrs: {
                                          value:
                                            _vm.detailFormState.discountPercent,
                                          name:
                                            _vm.formRules.discountPercent.name,
                                          placeholder: _vm.$t(
                                            _vm.formRules.discountPercent
                                              .placeholder
                                          ),
                                          formatter: _vm.formatterPercent,
                                          parser: _vm.reverseFormatPercent,
                                          min: 0,
                                          precision: _vm.storeBaseDecimalPlace,
                                          disabled: _vm.allowEdit
                                        },
                                        on: {
                                          change: function(e) {
                                            return _vm.onChangeDiscount(
                                              e,
                                              "percent"
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    { staticClass: "mb-0 pb-0" },
                                    [
                                      _c("a-input-number", {
                                        staticClass: "w-100",
                                        attrs: {
                                          value:
                                            _vm.detailFormState.discountValue,
                                          name:
                                            _vm.formRules.discountValue.name,
                                          placeholder: _vm.$t(
                                            _vm.formRules.discountValue
                                              .placeholder
                                          ),
                                          formatter: _vm.formatterNumber,
                                          parser: _vm.reverseFormatNumber,
                                          min: 0,
                                          precision: _vm.storeBaseDecimalPlace,
                                          disabled: _vm.allowEdit
                                        },
                                        on: {
                                          change: function(e) {
                                            return _vm.onChangeDiscount(
                                              e,
                                              "amount"
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formRules.incomeTaxRateValue.label
                            )
                          }
                        },
                        [
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 16] } },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    { staticClass: "mb-0 pb-0" },
                                    [
                                      _c("a-input-number", {
                                        staticClass: "w-100",
                                        attrs: {
                                          value:
                                            _vm.detailFormState
                                              .incomeTaxPercent,
                                          name:
                                            _vm.formRules.incomeTaxPercent.name,
                                          formatter: _vm.formatterPercent,
                                          parser: _vm.reverseFormatPercent,
                                          min: 0,
                                          precision: _vm.storeBaseDecimalPlace,
                                          disabled: ""
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    { staticClass: "mb-0 pb-0" },
                                    [
                                      _c("a-input-number", {
                                        staticClass: "w-100",
                                        attrs: {
                                          value:
                                            _vm.detailFormState
                                              .incomeTaxRateValue,
                                          name:
                                            _vm.formRules.incomeTaxRateValue
                                              .name,
                                          formatter: _vm.formatterNumber,
                                          parser: _vm.reverseFormatNumber,
                                          min: 0,
                                          precision: _vm.storeBaseDecimalPlace,
                                          disabled: ""
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.baseAmountDpp.label)
                          }
                        },
                        [
                          _c("a-input-number", {
                            staticClass: "w-100",
                            attrs: {
                              value: _vm.detailFormState.baseAmountDpp,
                              name: _vm.formRules.baseAmountDpp.name,
                              formatter: _vm.formatterNumber,
                              parser: _vm.reverseFormatNumber,
                              min: 0,
                              precision: _vm.storeBaseDecimalPlace,
                              disabled: ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ModalAdditionalCost", {
        attrs: {
          costs: _vm.additionalCostModal.props.record,
          "read-only": _vm.allowEdit
        },
        on: { save: _vm.onSaveAdditionalCost },
        model: {
          value: _vm.additionalCostModal.visible,
          callback: function($$v) {
            _vm.$set(_vm.additionalCostModal, "visible", $$v)
          },
          expression: "additionalCostModal.visible"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }