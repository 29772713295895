
















import SearchBuilder from "@/builder/SearchBuilder";
import { debounceProcess } from "@/helpers/debounce";
import useAssetCategory from "@/hooks/useAssetCategory";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DataListAssetCategory } from "@/models/interface/assets.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { LabelInValue } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class SelectAssetCategory extends Vue {
  @Prop({ required: false, default: undefined })
  value!: string | LabelInValue | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  labelInValue!: boolean;

  options: Option<DataListAssetCategory>[] = [];

  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    const params = new RequestQueryParams();
    this.fetchOptions(params);
  }

  fetchOptions(params: RequestQueryParamsModel): void {
    const { findAll, toOptions } = useAssetCategory();
    const builder = new SearchBuilder();

    const q: string[] = [builder.push(["book.type", "Commercial"]).build()];
    if (params.search) {
      q.push(params.search);
    }
    params.search = q.join(builder.AND);

    this.loading = true;
    findAll(params)
      .then(({ data }) => {
        this.options = toOptions(data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string | LabelInValue): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { filterBy } = useAssetCategory();
    const params = new RequestQueryParams();
    params.search = filterBy({ categoryId: val });

    this.fetchOptions(params);
  }

  findOption(
    value?: string | LabelInValue
  ): Option<DataListAssetCategory> | undefined {
    if (!value) {
      return;
    }

    if (typeof value === "string") {
      return this.options.find(e => value === e.value);
    } else {
      return this.options.find(e => value.key === e.key);
    }
  }
}
