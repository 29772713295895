
































































import SearchBuilder from "@/builder/SearchBuilder";
import { DisplayBoolean } from "@/components/DisplayBoolean";
import { APagination } from "@/hooks";
import useBlob from "@/hooks/useBlob";
import { useTrucking } from "@/hooks/useTrucking";
import { TruckingMasterDriverMapper } from "@/mapper/TruckingMasterDriver.mapper";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { TruckingMasterDriverResponseDto } from "@/models/interface/trucking-master-driver";
import { SorterProps } from "@/types";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    DisplayBoolean,
  },
})
export default class MasterDriver extends Vue {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  loading = {
    find: false,
    download: false,
  };

  pagination = {
    page: +this.$route?.query.p || DEFAULT_PAGE,
    limit: +this.$route?.query.l || DEFAULT_PAGE_SIZE,
    reset(): void {
      this.page = DEFAULT_PAGE;
    },
  };

  masterDrivers: Pagination<TruckingMasterDriverResponseDto> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  masterDriverTableColumns = [
    {
      title: this.$t("lbl_driver_code"),
      dataIndex: "driverCode",
      key: "driverCode",
      sorter: true,
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_driver_name"),
      dataIndex: "driverName",
      key: "driverName",
      sorter: true,
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_bank_name"),
      dataIndex: "bankName",
      key: "bankName",
      sorter: true,
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_account_name"),
      dataIndex: "accountName",
      key: "accountName",
      sorter: true,
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_account_number"),
      dataIndex: "accountNumber",
      key: "accountNumber",
      sorter: true,
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      sorter: true,
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_active"),
      dataIndex: "active",
      key: "active",
      sorter: true,
      scopedSlots: { customRender: "boolean" },
    },
  ];

  inputSearch = (this.$route?.query.q as string) || "";

  onChangeTable(pagination: APagination, _filter, sorts: SorterProps): void {
    const { current, pageSize } = pagination;
    const { columnKey, order } = sorts;

    this.pagination.page = current;

    if (pageSize !== this.pagination.limit) {
      this.pagination.page = DEFAULT_PAGE;
    }

    this.pagination.limit = pageSize;

    const params = this.buildQueryParams();

    if (order && order === "ascend") {
      params.sorts = `${columnKey}:asc`;
    } else if (order && order === "descend") {
      params.sorts = `${columnKey}:desc`;
    }

    this.fetchData(params);
    this.updateQuerySearch();
  }

  fetchData(params?: RequestQueryParams): void {
    const { findListMasterDrivers } = useTrucking();

    this.loading.find = true;
    findListMasterDrivers(params)
      .then(res => {
        this.masterDrivers = res;
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  buildQueryParams(): RequestQueryParams {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();

    params.sorts = "driverCode:asc";
    params.limit = this.pagination.limit;
    params.page = this.pagination.page - ONE;

    if (this.inputSearch) {
      params.search = builder
        .push(["driverCode", this.inputSearch], {
          like: "both",
        })
        .or()
        .push(["driverName", this.inputSearch], {
          like: "both",
        })
        .build();
    }

    return params;
  }

  resetData(): void {
    this.pagination.reset();
    this.inputSearch = "";
    this.updateQuerySearch();

    const params = this.buildQueryParams();
    this.fetchData(params);
  }

  updateQuerySearch(): void {
    this.$router.replace({
      name: "trucking.master-driver",
      query: {
        q: this.inputSearch,
        p: this.pagination.page.toString(),
        l: this.pagination.limit.toString(),
      },
    });
  }

  handleSearch(): void {
    this.pagination.reset();

    const params = this.buildQueryParams();
    this.fetchData(params);
    this.updateQuerySearch();
  }

  handleDownload(): void {
    const { downloadListMasterDrivers } = useTrucking();
    const { toDownload } = useBlob();

    this.loading.download = true;
    const params = TruckingMasterDriverMapper.paginationParamToParamDownload(
      this.buildQueryParams(),
      this.$store.state.authStore.authData.companyName,
      this.inputSearch
    );
    downloadListMasterDrivers(params)
      .then(res => {
        toDownload(res, "master_driver.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  mounted(): void {
    const params = this.buildQueryParams();
    this.fetchData(params);
  }
}
