import { aRPrepaymentCreateLoader, aRPrepaymentDetailLoader } from "@/loader";
import { arInvoiceCreateLoader } from "@/loader/ArInvoiceLoader";
// Models
import { config } from "@/models/constant/routes.constant";
import { RouteConfig } from "vue-router";

const joinInvoiceForm = import(
  /* webpackPrefetch: true */ "./join-invoices/JoinInvoicesForm.vue"
);

const index: RouteConfig = {
  path: config.ACCOUNTRECEIVABLES_ROUTE.path,
  name: config.ACCOUNTRECEIVABLES_ROUTE.name,
  meta: config.ACCOUNTRECEIVABLES_ROUTE.meta,
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: config.CLOSEPERIODE_ROUTE.path,
      name: config.CLOSEPERIODE_ROUTE.name,
      meta: config.CLOSEPERIODE_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./ClosePeriode.vue"),
    },
    {
      path: config.REPORTAGING_ROUTE.path,
      name: config.REPORTAGING_ROUTE.name,
      meta: config.REPORTAGING_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/ReportAging.vue"),
    },
    {
      path: config.REPORT_INVOICE_CONTROLLING_ROUTE.path,
      name: config.REPORT_INVOICE_CONTROLLING_ROUTE.name,
      meta: config.REPORT_INVOICE_CONTROLLING_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./reports/invoice-controlling/IndexPage.vue"
        ),
    },
    {
      path: config.REPORT_TAX_INVOICE_ROUTE.path,
      name: config.REPORT_TAX_INVOICE_ROUTE.name,
      meta: config.REPORT_TAX_INVOICE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/ReportTaxInvoice.vue"),
    },
    {
      path: config.INVOICES_ROUTE.path,
      name: config.INVOICES_ROUTE.name,
      meta: config.INVOICES_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./Invoices.vue"),
    },
    {
      path: config.INVOICES_DETAIL_RECURING_ROUTE.path,
      name: config.INVOICES_DETAIL_RECURING_ROUTE.name,
      meta: config.INVOICES_DETAIL_RECURING_ROUTE.meta,
      beforeEnter: arInvoiceCreateLoader,
      component: () =>
        import(/* webpackPrefetch: true */ "./invoice-ar/InvoiceArIndex.vue"),
    },
    {
      path: config.INVOICES_CREATE_RECURING_ROUTE.path,
      name: config.INVOICES_CREATE_RECURING_ROUTE.name,
      meta: config.INVOICES_CREATE_RECURING_ROUTE.meta,
      beforeEnter: arInvoiceCreateLoader,
      component: () =>
        import(/* webpackPrefetch: true */ "./invoice-ar/InvoiceArIndex.vue"),
    },
    {
      path: config.INVOICES_DETAIL_ROUTE.path,
      name: config.INVOICES_DETAIL_ROUTE.name,
      meta: config.INVOICES_DETAIL_ROUTE.meta,
      beforeEnter: arInvoiceCreateLoader,
      component: () =>
        import(/* webpackPrefetch: true */ "./invoice-ar/InvoiceArIndex.vue"),
    },
    {
      path: config.INVOICES_CREATE_ROUTE.path,
      name: config.INVOICES_CREATE_ROUTE.name,
      meta: config.INVOICES_CREATE_ROUTE.meta,
      beforeEnter: arInvoiceCreateLoader,
      component: () =>
        import(/* webpackPrefetch: true */ "./invoice-ar/InvoiceArIndex.vue"),
    },
    {
      path: config.INVOICE_RECURRING_BAP_ROUTE.path,
      name: config.INVOICE_RECURRING_BAP_ROUTE.name,
      meta: config.INVOICE_RECURRING_BAP_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./InvoicesRecuringBap.vue"),
    },
    {
      path: config.RECEIPTAR_ROUTE.path,
      name: config.RECEIPTAR_ROUTE.name,
      meta: config.RECEIPTAR_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./ar-receipt/ReceiptAr.vue"),
    },
    {
      path: config.REPORTS_ACCOUNT_RECEIVABLES_ROUTE.path,
      name: config.REPORTS_ACCOUNT_RECEIVABLES_ROUTE.name,
      meta: config.REPORTS_ACCOUNT_RECEIVABLES_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./ar-receipt/ReceiptArForm.vue"),
    },
    {
      path: config.RECEIPT_AR_CREATE_ROUTE.path,
      name: config.RECEIPT_AR_CREATE_ROUTE.name,
      meta: config.RECEIPT_AR_CREATE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./ar-receipt/ReceiptArForm.vue"),
    },
    {
      path: config.RECEIPT_AR_VIEW_ROUTE.path,
      name: config.RECEIPT_AR_VIEW_ROUTE.name,
      meta: config.RECEIPT_AR_VIEW_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./ar-receipt/ReceiptArForm.vue"),
    },
    {
      path: config.AR_PREPAYMENT_INDEX_ROUTE.path,
      name: config.AR_PREPAYMENT_INDEX_ROUTE.name,
      meta: config.AR_PREPAYMENT_INDEX_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./invoice-prepayment/IndexPage.vue"
        ),
    },
    {
      path: config.AR_PREPAYMENT_CREATE_ROUTE.path,
      name: config.AR_PREPAYMENT_CREATE_ROUTE.name,
      meta: config.AR_PREPAYMENT_CREATE_ROUTE.meta,
      beforeEnter: aRPrepaymentCreateLoader,
      component: () =>
        import(/* webpackPrefetch: true */ "./invoice-prepayment/FormPage.vue"),
    },
    {
      path: config.AR_PREPAYMENT_DETAIL_ROUTE.path,
      name: config.AR_PREPAYMENT_DETAIL_ROUTE.name,
      meta: config.AR_PREPAYMENT_DETAIL_ROUTE.meta,
      beforeEnter: aRPrepaymentDetailLoader,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./invoice-prepayment/DetailPage.vue"
        ),
    },
    {
      path: config.JOIN_INVOICES_ROUTE.path,
      name: config.JOIN_INVOICES_ROUTE.name,
      meta: config.JOIN_INVOICES_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./join-invoices/JoinInvoiceList.vue"
        ),
    },
    {
      path: config.JOIN_INVOICES_CREATE_ROUTE.path,
      name: config.JOIN_INVOICES_CREATE_ROUTE.name,
      meta: config.JOIN_INVOICES_CREATE_ROUTE.meta,
      component: () => joinInvoiceForm,
    },
    {
      path: config.JOIN_INVOICES_VIEW_ROUTE.path,
      name: config.JOIN_INVOICES_VIEW_ROUTE.name,
      meta: config.JOIN_INVOICES_VIEW_ROUTE.meta,
      component: () => joinInvoiceForm,
    },
    {
      path: config.PRINT_INVOICE_CUSTOM_ROUTE.path,
      name: config.PRINT_INVOICE_CUSTOM_ROUTE.name,
      meta: config.PRINT_INVOICE_CUSTOM_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./PrintInvoiceCustom.vue"),
    },
    {
      path: config.LIST_INPUT_PPH_ROUTE.path,
      name: config.LIST_INPUT_PPH_ROUTE.name,
      meta: config.LIST_INPUT_PPH_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./ListInputPph.vue"),
    },
    {
      path: config.CREATE_INPUT_PPH_ROUTE.path,
      name: config.CREATE_INPUT_PPH_ROUTE.name,
      meta: config.CREATE_INPUT_PPH_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./CreateInputPph.vue"),
    },
    {
      path: config.VIEW_INPUT_PPH_ROUTE.path,
      name: config.VIEW_INPUT_PPH_ROUTE.name,
      meta: config.VIEW_INPUT_PPH_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./CreateInputPph.vue"),
    },
    {
      path: config.PPHCUSTOMER_ROUTE.path,
      name: config.PPHCUSTOMER_ROUTE.name,
      meta: config.PPHCUSTOMER_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/PphCustomer.vue"),
    },
    {
      path: config.RECEIVABLE_MUTATION_CUSTOMER_REPORT_ROUTE.path,
      name: config.RECEIVABLE_MUTATION_CUSTOMER_REPORT_ROUTE.name,
      meta: config.RECEIVABLE_MUTATION_CUSTOMER_REPORT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./reports/ReceivableMutationReport.vue"
        ),
    },
    {
      path: config.AR_RECEIPT_REPORT_ROUTE.path,
      name: config.AR_RECEIPT_REPORT_ROUTE.name,
      meta: config.AR_RECEIPT_REPORT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/ReportArReceipt.vue"),
    },
  ],
};

export default index;
