import { displayNeg } from "@/helpers/common";
import { Row, useCalculator, useProduct, useRemoveRows, useTax } from "@/hooks";
import {
  DECIMAL_PLACES_CURRENCY,
  ONE,
} from "@/models/constant/global.constant";
import { Nullable } from "@/models/constant/interface/common.interface";
import SALES_ORDER_STATUS from "@/models/enums/sales-order.enum";
import { SalesOrderTypeEnum } from "@/models/enums/SalesOrderType.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { InternalContractGetDTO } from "@/models/interface/internal-contract";
import { IPreferencesResponseDto } from "@/models/interface/preference";
import {
  SalesOrderLineRequestDto,
  SalesOrderResponseDto,
} from "@/models/interface/sales-order";
import {
  initDetail,
  initForm,
  initLine,
} from "@/store/resources/salesOrder.resource";
import { LabelInValue } from "@/types";
import { SalesOrderUtils } from "@/utils/SalesOrderUtils";
import Decimal from "decimal.js-light";
import { Moment } from "moment";

export type FormValue = Nullable<{
  documentNumber: string; // so number
  salesType: SalesOrderTypeEnum;
  internalContractId: string;
  internalContractNumber: string;
  branchId: string;
  branchName: string;
  date: Moment;
  customerId: string;
  customerName: string;
  shipTo: string; // diambil dari customer
  billTo: string; // diambil dari customer
  salesPerson: LabelInValue | undefined;
  taxCalculation: TAX_CALCULATION; // kirim label nya
  customerPoNumber: string; // free text
  deliveryDate: Moment;
  currencyCode: string; // kirim code nya
  currencyRate: number;
  top: number;
  notes: string; // kasih validasi max 2500
  status: SALES_ORDER_STATUS;
  closeReason: string;
  deletedSalesOrderLineIds?: string[]; // used to delete row
}> & { isLuxury: boolean; taxBaseVariable: number };

/**
 * @description dipakai untuk model tabel product row sales order
 */
export type SalesOrderLine = Row<
  Omit<SalesOrderLineRequestDto, "taxId">,
  string
> & {
  unitCode: string;
  productName: string;
  productCode: string;
  locationName: string;
  uomName: string;
  taxRate: number;
  taxValue: number;
  taxableValue: number; // nilai dasar pengenaan pajak
  grossValue: number; // qty * price
  taxCode: LabelInValue | undefined;
};

export type State = {
  form: FormValue;
  soLines: SalesOrderLine[];
  soDetail: SalesOrderResponseDto;
};
export type FieldGuard<TKey extends keyof FormValue> = FormValue[TKey];

const state: State = {
  form: initForm(),
  soLines: [], // product sales order line
  soDetail: initDetail(),
};
const mutations = {
  setForm: (st: State, payload: Partial<FormValue>): void => {
    const copy = { ...st.form };
    st.form = {
      ...copy,
      ...payload,
    };
  },
  setSoLines: (st: State, payload: SalesOrderLine[]): void => {
    st.soLines = payload;
  },
  setSoDetail: (st: State, payload: SalesOrderResponseDto): void => {
    st.soDetail = payload;
  },
};
const actions = {
  resetStore: (context): void => {
    const { commit } = context;
    commit("setForm", initForm());
    commit("setSoDetail", initDetail());
    commit("setSoLines", []);
  },
  // hapus row
  // perlu cek yang ada secure id dan ga ada
  // hitung ulang
  deleteSoLine: (context, payload: string[]): void => {
    const { getters, dispatch, commit, state } = context;
    const local: State = state;
    const lines: SalesOrderLine[] = getters.getSoLines as SalesOrderLine[];
    const { newSource, deletedRows } = useRemoveRows<SalesOrderLine>(
      lines,
      payload
    );

    commit("setForm", {
      deletedSalesOrderLineIds: [
        ...(local.form.deletedSalesOrderLineIds || []),
        ...deletedRows.map<string>(item => item.id),
      ],
    });
    commit("setSoLines", newSource);
    dispatch("calcSoLines");
  },
  addSoLine: async (context): Promise<void> => {
    const { state, getters, rootGetters } = context;
    const local: State = state;
    const { findById, findByTaxRateAndType } = useTax();
    const row = initLine();
    row.customerLocation = local.form.shipTo || "";

    // jika add unit ASSET SALE,
    // default tax nya ngambil dari preference
    // feature_sales_tax_rate (bisa kosong kalo belum setup preference)
    const salesTax: IPreferencesResponseDto | undefined =
      rootGetters["preferenceStore/getFeatSalesTaxRate"];
    try {
      if (
        getters.isAssetSale &&
        !getters.isTaxNone &&
        salesTax &&
        salesTax.value
      ) {
        const tax = await findById(salesTax.value);
        row.taxCode = { label: tax.code, key: tax.id };
        row.taxRate = tax.rate || 0;
      } else if (getters.isTaxNone) {
        const taxOption = await findByTaxRateAndType(0, "VAT_OUT");
        const [tax] = taxOption.data;
        if (tax) {
          row.taxCode = {
            label: tax.code,
            key: tax.id,
          };
          row.taxRate = tax.rate ?? 0;
        } else {
          row.taxCode = undefined;
          row.taxRate = 0;
        }
      }

      local.soLines.push(row);
    } catch (error) {
      row.taxCode = undefined;
      row.taxRate = 0;
    }
  },
  setEachCustomerLoc: (context, payload: string): void => {
    const { state } = context;
    const local: State = state;
    local.soLines.forEach(item => {
      item.customerLocation = payload;
    });
  },
  restEachTaxCodeLine: async (context): Promise<void> => {
    const { state } = context;
    const local: State = state;
    const { findByTaxRateAndType } = useTax();
    const taxOption = await findByTaxRateAndType(0, "VAT_OUT");
    local.soLines.forEach(item => {
      item.taxCode = {
        label: taxOption.data[0]?.code ?? "",
        key: taxOption.data[0]?.id ?? "",
      };
      item.taxRate = taxOption.data[0]?.rate ?? 0;
    });
  },
  calcSoLines: ({ state, rootState }): void => {
    const local: State = state;
    const salesInclusiveTaxRate: number =
      rootState.preferenceStore.salesInclusiveTaxRate ?? 0;
    local.soLines.forEach(row => {
      SalesOrderUtils.countPrice({
        row,
        salesInclusiveTaxRate,
        taxType: local.form.taxCalculation ?? TAX_CALCULATION.NONE,
        isLuxury: local.form.isLuxury,
        taxBaseVariable: local.form.taxBaseVariable,
      });
    });
  },
  /**
   * @description do calculation of sales order lines
   * @deprecated
   */
  calcSoLines2: (context): void => {
    const { state, getters, rootState } = context;
    const local: State = state;
    const salesInclusiveTaxRate: number =
      rootState.preferenceStore.salesInclusiveTaxRate ?? 0;
    local.soLines.forEach(row => {
      // gross value exclusive luxury / non luxury
      // price * qty - discount

      // gross value inclusive luxury
      // (price * qty - discount) / (tax rate / 100 + 1)

      // gross value inclusive non luxury
      // (price * qty - discount) / (sales inclusive rate / 100 + 1)

      const grossValue = new Decimal(row.price || 0)
        .times(row.qty || 0)
        .minus(row.discountValue || 0)
        .toDecimalPlaces(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP);

      if (getters.isTaxExcl || getters.isTaxNone) {
        row.grossValue = grossValue.toNumber();
      } else if (getters.isTaxIncl) {
        const taxRate = new Decimal(row.taxRate || 0).dividedBy(100).plus(1);
        row.grossValue = grossValue
          .dividedBy(taxRate)
          .toDecimalPlaces(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP)
          .toNumber();
      } else if (getters.isTaxIncl) {
        const taxRate = new Decimal(salesInclusiveTaxRate)
          .dividedBy(100)
          .plus(1);
        row.grossValue = grossValue
          .dividedBy(taxRate)
          .toDecimalPlaces(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP)
          .toNumber();
      }

      // gross value - discount value
      const taxBase: number = new Decimal(row.grossValue)
        .minus(row.discountValue || 0)
        .toDecimalPlaces(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP)
        .toNumber();

      row.taxValue = 0;

      if (getters.isTaxExcl) {
        row.taxableValue = taxBase;
        row.taxValue = new Decimal(row.taxRate || 0)
          .dividedBy(100)
          .times(taxBase)
          .toDecimalPlaces(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP)
          .toNumber();
      } else if (getters.isTaxIncl) {
        const taxPercent = new Decimal(row.taxRate || 0).dividedBy(100);
        const divider = taxPercent.plus(ONE);

        // GROSS SETELAH POTONG DISKON / ( 1 + PERCENT PAJAK )
        row.taxableValue = new Decimal(taxBase)
          .dividedBy(divider)
          .toDecimalPlaces(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP)
          .toNumber();
        row.taxValue = new Decimal(taxBase)
          .minus(row.taxableValue)
          .toDecimalPlaces(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP)
          .toNumber();
      } else if (getters.isTaxNone) {
        // row.taxableValue = grossValue;
        row.taxableValue = new Decimal(row.price || 0)
          .times(row.qty || 0)
          .minus(row.discountValue || 0)
          .toDecimalPlaces(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP)
          .toNumber();
      }

      if (getters.isTaxNone) {
        row.subTotal = taxBase;
      } else {
        row.subTotal = new Decimal(row.taxableValue)
          .plus(row.taxValue)
          .toDecimalPlaces(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP)
          .toNumber();
      }
    });
  },
  setLinesIc: async (
    context,
    payload: InternalContractGetDTO
  ): Promise<void> => {
    const { commit, state, getters, rootGetters, dispatch } = context;
    const local: State = state;
    const { internalContractDetailList, rentPeriod } = payload;
    const { findById } = useTax();

    const preference: IPreferencesResponseDto | undefined =
      rootGetters["preferenceStore/getFeatSalesTaxRate"];

    let taxCode: { name: string; value: string } | undefined;
    let taxRate = 0;
    try {
      if (preference && preference.value) {
        const taxId = preference.value;
        if (taxId) {
          const { code = "", rate = 0 } = await findById(taxId);
          taxCode = { name: code, value: taxId };
          taxRate = rate || 0;
        }
      }
    } catch (error) {
      taxCode = undefined;
      taxRate = 0;
    }

    const lines: SalesOrderLine[] = internalContractDetailList.map(item => {
      const price: number = new Decimal(item.rentPrice || 0)
        .times(rentPeriod || 0)
        .toNumber();
      const row: SalesOrderLine = initLine();
      row.productCode = item.unitCode;
      row.productName = item.unitCode;
      row.assetId = item.unitId || "";
      row.backupUnit = item.unitBackup || false;
      row.customerLocation = item.location || "";
      row.internalContractLineId = item.id || "";
      row.locationId = item.assetLocation.id || "";
      row.locationName = item.assetLocation.name;
      row.serialNumber = item.serialNumber || "";
      row.uomId = item.uomId || "";
      row.uomName = item.uomUnit || "";

      row.price = price || 0;
      row.qty = item.qty || 0;
      row.qtyAvailable = 0;

      row.taxCode = undefined;
      if (taxCode) {
        row.taxCode = { key: taxCode.value, label: taxCode.name };
      }
      row.taxRate = getters.isTaxNone ? 0 : taxRate;

      return row;
    });
    commit("setSoLines", [...local.soLines, ...lines]);
    dispatch("calcSoLines");
  },
  setLinesProdService: async (
    context,
    payload: InternalContractGetDTO
  ): Promise<void> => {
    const { state, commit, dispatch, getters } = context;
    const { productServices = [], rentPeriod = 0 } = payload;
    const { findById: findDetailProduct } = useProduct();
    const { findById: findDetailTax } = useTax();
    const local: State = state;

    const lines: SalesOrderLine[] = await Promise.all(
      (productServices || []).map(async item => {
        const row: SalesOrderLine = initLine();

        if (!getters.isTaxNone) {
          const { salesTaxId = "" } = await findDetailProduct(item.productId);

          // the sales tax id would be empty if
          // it is not setup yet
          if (salesTaxId) {
            const { rate = 0, code = "" } = await findDetailTax(salesTaxId);
            row.taxRate = rate || 0;
            row.taxCode = { label: code, key: salesTaxId };
          }
        }

        row.customerLocation = item.location || "";
        row.productId = item.productId || "";
        row.productCode = item.productCode || "";
        row.productName = item.productName || "";
        row.uomId = item.uomId || "";
        row.uomName = item.uomUnit || "";
        row.qty = item.qty || 0;
        row.price = new Decimal(rentPeriod || 0)
          .times(item.rentPrice || 0)
          .toNumber();
        row.serialNumber = item.serialNumber || "";
        row.internalContractLineId = item.id;

        return row;
      })
    );

    commit("setSoLines", [...local.soLines, ...lines]);
    dispatch("calcSoLines");
  },
  clearLines: (context): void => {
    const { commit } = context;
    commit("setSoLines", []);
  },
};
const getters = {
  /**
   * the asset id should have value if SALES RENT / ASSET SALE
   * the product id should have value if PRODUCT SALE / OTHER
   */
  isLinesInvalid: (state: State): boolean => {
    const isInvalidProduct = !!state.soLines.find(
      line => !line.assetId && !line.productId
    );
    return isInvalidProduct;
  },
  isStatusDraft: (state: State): boolean => {
    return state.soDetail.states === SALES_ORDER_STATUS.DRAFT;
  },
  isStatusClosed: (state: State): boolean => {
    return state.soDetail.states === SALES_ORDER_STATUS.CLOSED;
  },
  isSubmitted: (state: State): boolean => {
    return state.soDetail.states === SALES_ORDER_STATUS.SUBMITTED;
  },
  allowCancel: (state: State): boolean => {
    const list = [SALES_ORDER_STATUS.DRAFT, SALES_ORDER_STATUS.SUBMITTED];
    return list.includes(state.soDetail.states);
  },
  allowSubmit: (state: State, getters): boolean => {
    return getters.isStatusDraft;
  },
  allowClose: (state: State): boolean => {
    return state.soDetail.states === SALES_ORDER_STATUS.SUBMITTED;
  },
  allowUpdate: (state: State, getters): boolean => {
    return !getters.isSalesRent && getters.isStatusDraft;
  },
  getSoLines: (state: State): SalesOrderLine[] => {
    return state.soLines;
  },
  isSalesRent: (state: State): boolean => {
    return state.form.salesType === SalesOrderTypeEnum.RENT;
  },
  isSalesProductSale: (state: State): boolean => {
    return state.form.salesType === SalesOrderTypeEnum.PRODUCT_SALE;
  },
  isSalesOther: (state: State): boolean => {
    return state.form.salesType === SalesOrderTypeEnum.OTHERS;
  },
  isAssetSale: (state: State): boolean => {
    return state.form.salesType === SalesOrderTypeEnum.ASSET_SALE;
  },
  isTaxNone: (state: State): boolean => {
    return state.form.taxCalculation === TAX_CALCULATION.NONE;
  },
  isTaxExcl: (state: State): boolean => {
    return state.form.taxCalculation === TAX_CALCULATION.EXCLUSIVE;
  },
  isTaxIncl: (state: State): boolean => {
    return state.form.taxCalculation === TAX_CALCULATION.INCLUSIVE;
  },
  /**
   * @description alias for taxable value
   * the sum of the `taxableValue` on each line
   */
  getTotalDpp: (state: State): number => {
    const { sum } = useCalculator();
    const total = sum(state.soLines.map(e => e.taxableValue || 0));
    return displayNeg(total);
  },
  getTotalGross: (state: State): number => {
    const { sum } = useCalculator();
    const total = sum(state.soLines.map(e => e.grossValue || 0));
    return displayNeg(total);
  },
  /**
   * @description the sum of the `taxValue` on each line
   */
  getTotalTax: (state: State): number => {
    const { sum } = useCalculator();
    const total = sum(state.soLines.map(e => e.taxValue || 0));
    return displayNeg(total);
  },
  /**
   * @description the sum of the `discountValue` on each line
   */
  getTotalDiscount: (state: State): number => {
    const { sum } = useCalculator();
    const total = sum(state.soLines.map(e => e.discountValue || 0));
    return displayNeg(total);
  },
  /**
   * @description total dpp + total tax - total discount
   */
  getGrandTotal: (state: State): number => {
    const { sum } = useCalculator();
    const totals: Array<number> = state.soLines.map(e => e.subTotal || 0);
    const grandTotal: number = displayNeg(sum(totals));
    return grandTotal;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
