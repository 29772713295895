







































































































































































































































































































































































































import { SearchBuilder } from "@/builder";
import CharacterLength from "@/components/CharacterLength/CharacterLength.vue";
import { debounce, debounceProcess } from "@/helpers/debounce";
import {
  useBank,
  useBranch,
  useCashInOut,
  useCoa,
  useContactData,
  useCurrency,
  useDeleteRows,
  useDisabledFromTomorrow,
  usePreferences,
} from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import {
  CashInOutLineDetail,
  DetailCashOut,
  RequestCashOut,
} from "@/models/interface/cashManagement.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseListOfCurrency } from "@/models/interface/settings.interface";
import { cashManagementServices } from "@/services/cashmanagement.service";
import { LabelInValue } from "@/types";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { FormModel } from "ant-design-vue";
import { UploadFile } from "ant-design-vue/types/upload";
import { Decimal } from "decimal.js-light";
import moment, { Moment } from "moment";
import printJS from "print-js";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { mapState } from "vuex";

enum TRANSFER_TO_FROM_VALUE {
  SUPPLIER = "SUPPLIER",
  CUSTOMER = "CUSTOMER",
  NONE = "NONE",
}

enum TRANSACTION_TYPE {
  CASH_IN = "CASH_IN",
  CASH_OUT = "CASH_OUT",
}

enum DOCUMENT_STATUS {
  CANCELLED = "CANCELLED",
  DRAFT = "DRAFT",
  SETTLED = "SETTLED",
  SUBMITTED = "SUBMITTED",
}

type Row = {
  key: number;
  optAccounts: Option[];
  isSearching: boolean;
  isLoading: boolean;
  account: LabelInValue | undefined;
} & Partial<CashInOutLineDetail>;

const ALLOWED_CANCEL_STATUS = [
  DOCUMENT_STATUS.DRAFT,
  DOCUMENT_STATUS.SUBMITTED,
];

const ALLOWED_UPDATE_STATUS = [DOCUMENT_STATUS.DRAFT];

const ALLOWED_SUBMIT_STATUS = [DOCUMENT_STATUS.DRAFT];

@Component({
  components: { CharacterLength },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
})
export default class CashInOutForm extends Mixins(MNotificationVue) {
  @Ref("CashForm") cashForm!: FormModel;

  TRANSFER_TO_FROM_VALUE = TRANSFER_TO_FROM_VALUE;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  useDisabledFromTomorrow = useDisabledFromTomorrow;
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;
  formModel = {
    journalId: "",
    journalNumber: "",
    status: null as DOCUMENT_STATUS | null,
    transferSource: "",
    documentNo: "",
    branch: undefined as LabelInValue | undefined,
    type: "",
    transactionDate: null as Moment | null,
    currency: undefined as LabelInValue | undefined,
    rates: 1,
    companyBank: undefined as LabelInValue | undefined,
    cashInOutTransfer: TRANSFER_TO_FROM_VALUE.NONE,
    customerSupplierBank: "",
    referenceNo: "",
    supplierOrCustomer: undefined as LabelInValue | undefined,
    chequeNo: "",
    chequeBankName: "",
    description: "",
    attachment: "",
    cashInOutLineDelete: [] as string[],
  };
  formTotal = {
    currencyTotalValue: 0,
  };

  formRules = {
    branch: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    type: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    transactionDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    currency: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    companyBank: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    cashInOutTransfer: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    description: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  loading = {
    update: false,
    submit: false,
    saveDraft: false,
    cancel: false,
    print: false,
    contact: false,
    type: false,
    currency: false,
    bank: false,
    branch: false,
  };

  disabled = {
    transferSource: false,
    branch: false,
    description: false,
    type: false,
    transactionDate: false,
    currency: false,
    rate: false,
    bankName: false,
    transferToFrom: false,
    contact: false,
    contactbank: false,
    chequeNumber: false,
    bankNameCheque: false,
    file: false,
    table: false,
  };

  show = {
    rates: false,
  };

  optTypes: Option[] = [];
  optCurrencies: Option[] = [];
  optCompanyBanks: Option[] = [];
  optContacts: Option[] = [];
  optContactBanks: Option[] = [];
  optAccounts: Option[] = [];
  optBranchs: Option[] = [];

  fileList: UploadFile[] = [];

  selectedRowKeys: number[] = [];
  dataSource: Row[] = [];
  columns = [
    {
      title: this.$t("lbl_account"),
      key: "account",
      scopedSlots: { customRender: "account" },
      width: "33%",
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "amount",
      key: "amount",
      scopedSlots: { customRender: "amount" },
      width: "33%",
    },
    {
      title: this.$t("lbl_notes"),
      dataIndex: "notes",
      key: "notes",
      scopedSlots: { customRender: "notes" },
      width: "33%",
    },
  ];

  docId = "";

  get isModeCreate(): boolean {
    return this.$route.meta.mode === Mode.CREATE;
  }

  get showContactField(): boolean {
    const source = this.formModel.cashInOutTransfer;
    return (
      source === TRANSFER_TO_FROM_VALUE.CUSTOMER ||
      source === TRANSFER_TO_FROM_VALUE.SUPPLIER
    );
  }

  get allowSubmit(): boolean {
    return (
      ALLOWED_SUBMIT_STATUS.includes(
        this.formModel.status as DOCUMENT_STATUS
      ) || this.allowSaveDraft
    );
  }

  get allowUpdate(): boolean {
    return ALLOWED_UPDATE_STATUS.includes(
      this.formModel.status as DOCUMENT_STATUS
    );
  }

  get allowSaveDraft(): boolean {
    return !this.docId;
  }

  get allowCancel(): boolean {
    return ALLOWED_CANCEL_STATUS.includes(
      this.formModel.status as DOCUMENT_STATUS
    );
  }

  get allowPrint(): boolean {
    return this.isSubmited;
  }

  get sumAmountLines(): number {
    const mapped = this.dataSource.map<number>(item => item.amount || 0);
    if (mapped.length) {
      return mapped.reduce((a, b) => {
        return new Decimal(b).plus(a).toNumber();
      });
    }

    return 0;
  }

  get contactLabel() {
    if (this.toCustomer) {
      return {
        contact: this.$t("lbl_customer_name").toString(),
        bank: this.$t("lbl_customer_bank").toString(),
      };
    } else {
      return {
        contact: this.$t("lbl_supplier_name").toString(),
        bank: this.$t("lbl_supplier_bank").toString(),
      };
    }
  }

  get toCustomer(): boolean {
    return this.formModel.cashInOutTransfer === TRANSFER_TO_FROM_VALUE.CUSTOMER;
  }

  get toSupplier(): boolean {
    return this.formModel.cashInOutTransfer === TRANSFER_TO_FROM_VALUE.SUPPLIER;
  }

  get isSubmited(): boolean {
    return this.formModel.status === DOCUMENT_STATUS.SUBMITTED;
  }

  created() {
    this.onSearchBranch = debounceProcess(this.onSearchBranch, 500);
    this.onSearchContact = debounceProcess(this.onSearchContact, 500);
    this.onSearchCurrency = debounceProcess(this.onSearchCurrency, 500);
    this.onSearchCompanyBank = debounceProcess(this.onSearchCompanyBank, 500);

    this.fetchTypes();
    this.fetchCurrencies(new RequestQueryParams());
    this.docId = this.$route.params.id;
    this.getAccountList();
    this.getBranchList();

    if (this.docId) this.getDetailDoc(this.docId);
  }

  getDetailDoc(id: string): void {
    cashManagementServices.detailCashOut(id).then(response => {
      this.setForm(response);
      this.setDataSource(response);
      this.appendAttachment(response);
      this.toggleCurrencyRates(response.currencyCode);
      this.setDisabledForm(response);
      this.setContactBankOptions(response.supplierOrCustomerId);
    });
  }

  setDataSource({ cashInOutLines }: DetailCashOut): void {
    this.dataSource = cashInOutLines.map<Row>((item, i) => ({
      ...item,
      key: i,
      isLoading: false,
      isSearching: false,
      optAccounts: [],
      account: {
        key: item.accountId,
        label: `${item.accountCode} - ${item.accountDescription}`,
      },
    }));
  }

  setDisabledForm({ status }: DetailCashOut): void {
    if (status !== DOCUMENT_STATUS.DRAFT) {
      for (const key in this.disabled) {
        this.disabled[key] = true;
      }
    }
  }

  appendAttachment({ attachment }: DetailCashOut): void {
    if (!attachment) return;
    const { fileList } = this;
    this.fileList = [
      ...fileList,
      {
        uid: "1",
        name: attachment.split("/")[attachment.split("/").length - 1],
        status: "done",
        url: attachment,
        size: 0,
        type: "",
      },
    ];
  }

  setForm(detail: DetailCashOut): void {
    this.formModel = {
      journalId: detail.journalId || "",
      journalNumber: detail.journalNo || "",
      status: detail.status as DOCUMENT_STATUS,
      transferSource: detail.transferSource || "",
      documentNo: detail.documentNo || "",
      branch: { label: detail.branchName, key: detail.branchId },
      type: detail.type || "",
      transactionDate: detail.transactionDate
        ? moment(detail.transactionDate)
        : null,
      currency: {
        key: detail.currencyId,
        label: detail.currencyCode,
      },
      rates: detail.rates || 1,
      companyBank: { label: detail.companyBankName, key: detail.companyBankId },
      cashInOutTransfer: (detail.cashInOutTransfer ||
        "") as TRANSFER_TO_FROM_VALUE,
      customerSupplierBank: detail.customerSupplierBank || "",
      referenceNo: detail.referenceNo || "",
      supplierOrCustomer: {
        label: detail.supplierOrCustomerName,
        key: detail.supplierOrCustomerId,
      },
      chequeNo: detail.chequeNo || "",
      chequeBankName: detail.chequeBankName || "",
      description: detail.description || "",
      attachment: detail.attachment || "",
      cashInOutLineDelete: [],
    };

    this.formTotal = {
      currencyTotalValue: detail.totalAmount || 0,
    };

    this.preFetchContacts(this.formModel.cashInOutTransfer);
  }

  onSelectChange(selectedRowKeys: number[]): void {
    this.selectedRowKeys = selectedRowKeys;
  }

  async fetchTypes(): Promise<void> {
    try {
      const { findAllTypes, toTypeOptions } = useCashInOut();
      const params = new RequestQueryParams();
      this.loading.type = true;
      const response = await findAllTypes(params);
      this.optTypes = toTypeOptions(response);
    } finally {
      this.loading.type = false;
    }
  }

  onSearchCurrency(search?: string): void {
    const searchBy = search
      ? `currencyCode~*${search}*_OR_description~*${search}*`
      : "";
    this.fetchCurrencies({ search: searchBy });
  }

  onChangeCurrency(value: LabelInValue): void {
    this.findCurrencyById(value.key);
    this.preFetchCompanyBanks(new RequestQueryParams());
  }

  findCurrencyById(id: string): void {
    if (!id) return;
    const { findById } = useCurrency();
    findById(id).then(response => {
      this.toggleCurrencyRates(response.currencyCode);
      this.setCurrencyRates(response.currencyCode);
    });
  }

  setCurrencyRates(currencyCode: string): void {
    this.fetchCurrencyRates(currencyCode).then(conversion => {
      const [curr] = conversion.data;
      this.formModel.rates = curr?.rate ?? 1;
    });
  }

  async fetchCurrencyRates(
    fromCurrency: string
  ): Promise<ResponseListOfCurrency> {
    const { findByKey } = usePreferences();
    const response = findByKey("feature_base_currency");
    const { findConversion } = useCurrency();
    const conversion = await findConversion(fromCurrency, response?.name ?? "");
    return conversion;
  }

  toggleCurrencyRates(currencyCode: string): void {
    this.show.rates = currencyCode !== "IDR";
  }

  async fetchCurrencies(params: RequestQueryParamsModel): Promise<void> {
    try {
      const { findAll, toOptions } = useCurrency();
      this.loading.currency = true;
      const response = await findAll(params);
      this.optCurrencies = toOptions(response);
    } finally {
      this.loading.currency = false;
    }
  }

  onSearchCompanyBank(search?: string): void {
    let searchBy = "";
    if (search) {
      searchBy = `bankAccName~*${search}*_OR_bankAccNumber~*${search}*_OR_bankName~*${search}*`;
    }
    this.preFetchCompanyBanks({ search: searchBy });
  }

  preFetchCompanyBanks(params: RequestQueryParamsModel): void {
    const defaultQuery = [`currency.secureId~${this.formModel.currency?.key}`];
    if (params.search) defaultQuery.unshift(params.search);
    params.search = defaultQuery.join("_AND_");
    this.fetchCompanyBanks(params);
  }

  fetchCompanyBanks(params: RequestQueryParamsModel): void {
    const { findAll, toOptions } = useBank();
    this.loading.bank = true;
    findAll(params)
      .then(response => {
        this.optCompanyBanks = toOptions(response.data);
      })
      .finally(() => {
        this.loading.bank = false;
      });
  }

  onChangeTransferToFrom(): void {
    const source = this.formModel.cashInOutTransfer;
    if (
      source === TRANSFER_TO_FROM_VALUE.CUSTOMER ||
      source === TRANSFER_TO_FROM_VALUE.SUPPLIER
    ) {
      // reset
      this.formModel.supplierOrCustomer = undefined;
      this.formModel.customerSupplierBank = "";
      this.formModel.transferSource = "";
      this.preFetchContacts(source);
    }
  }

  onChangeContact(value: LabelInValue): void {
    this.formModel.customerSupplierBank = "";
    this.setContactBankOptions(value.key);
  }

  /**
   * get available bank options from detail contact
   */
  setContactBankOptions(contactId: string): void {
    if (!contactId) return;
    const { toBankOptions, findById } = useContactData();
    findById(contactId).then(response => {
      this.optContactBanks = toBankOptions(response);
    });
  }

  onSearchContact(value = ""): void {
    const builder = new SearchBuilder();
    let q = "";
    if (value) {
      q = builder
        .push(["firstName", value], { like: "both" })
        .or()
        .push(["lastName", value], { like: "both" })
        .build();
    }
    debounce(() => {
      if (this.toCustomer) {
        this.fetchCustomers({ search: q });
      } else if (this.toSupplier) {
        this.fetchSuppliers({ search: q });
      }
    });
  }

  preFetchContacts(source: TRANSFER_TO_FROM_VALUE): void {
    const params = new RequestQueryParams();
    if (source === TRANSFER_TO_FROM_VALUE.CUSTOMER) this.fetchCustomers(params);
    else this.fetchSuppliers(params);
  }

  fetchCustomers(params: RequestQueryParamsModel): void {
    const { findCustomers, toOptionsName } = useContactData();
    this.loading.contact = true;
    findCustomers(params)
      .then(response => {
        this.optContacts = toOptionsName(response);
      })
      .finally(() => {
        this.loading.contact = false;
      });
  }

  fetchSuppliers(params: RequestQueryParamsModel): void {
    const { findSuppliers, toOptionsName } = useContactData();
    this.loading.contact = true;
    findSuppliers(params)
      .then(response => {
        this.optContacts = toOptionsName(response);
      })
      .finally(() => {
        this.loading.contact = false;
      });
  }

  handleChange(info) {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    this.fileList = fileList;
    if (info.file.status === "done") {
      this.$notification.success({
        description: `${info.file.name} file uploaded successfully`,
        message: "Success",
        duration: 30,
      });
    } else if (info.file.status === "error") {
      this.$notification.error({
        description: `${info.file.name} file upload failed.`,
        message: "Error",
        duration: 30,
      });
    }
  }

  customRequest(options) {
    const data = new FormData();
    data.append("file", options.file);
    cashManagementServices
      .createUploadFile(data, "asset")
      .then(response => {
        options.onSuccess(options);
        this.formModel.attachment = response.url;
      })
      .catch(err => {
        options.onError(err, options);
      });
  }

  beforeUpload(file) {
    const isLt5M = file.size;
    const exceeded = isLt5M >= 5242880;
    if (exceeded) {
      this.$notification.error({
        description: "File must smaller than 5MB!",
        message: "Error",
        duration: 30,
      });
      return false;
    }
    return true;
  }

  addRow(): void {
    const newRow: Row = {
      key: new Date().valueOf(),
      accountId: "",
      amount: 0,
      notes: "",
      account: undefined,
      isLoading: false,
      isSearching: false,
      optAccounts: [],
    };

    const { dataSource } = this;
    this.dataSource = [...dataSource, newRow];
  }

  confirmDelete(): void {
    if (this.selectedRowKeys.length > 0) {
      this.$confirm({
        title: this.$t("lbl_modal_delete_title_confirm"),
        content: this.$t("lbl_modal_delete_info", {
          count: this.selectedRowKeys.length,
        }),
        onOk: () => {
          this.deleteRow();
        },
        onCancel() {
          return;
        },
      });
    } else {
      this.showNotifError("lbl_modal_delete_error_description");
    }
  }

  deleteRow(): void {
    const { newSource, deletedRows } = useDeleteRows(
      this.dataSource,
      this.selectedRowKeys
    );
    this.dataSource = newSource;
    this.selectedRowKeys = [];
    deletedRows.forEach(item => {
      if (item.id) this.formModel.cashInOutLineDelete.push(item.id);
    });
    this.updateTotalAmount();
  }

  onChangeAmount(): void {
    this.updateTotalAmount();
  }

  updateTotalAmount(): void {
    this.formTotal.currencyTotalValue = this.sumAmountLines;
  }

  handlePrintDetail(): void {
    this.loading.print = true;
    cashManagementServices
      .printDetail(this.$route.params.id)
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res]));
        printJS(url);
      })
      .finally(() => (this.loading.print = false));
  }

  handlePrintSummary(): void {
    this.loading.print = true;
    cashManagementServices
      .printSummary(this.$route.params.id)
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res]));
        printJS(url);
      })
      .finally(() => (this.loading.print = false));
  }

  validateForm(type: "submit" | "update"): void {
    this.cashForm.validate((valid: boolean) => {
      if (!valid) return;
      else if (type === "update") this.handleUpdate();
      else if (type === "submit" && this.docId) this.handleUpdate(true);
      else if (type === "submit") this.handleSubmit();
    });
  }

  handleUpdate(shouldSubmit = false): void {
    const payload = this.buildPayload();
    if (shouldSubmit) payload.status = DOCUMENT_STATUS.SUBMITTED;
    this.updateCashInOut(this.docId, payload);
  }

  handleSubmit(): void {
    const payload = this.buildPayload();
    payload.status = DOCUMENT_STATUS.SUBMITTED;
    this.postCashInOut(payload, "submit");
  }

  handleBack(): void {
    const leasingId = this.$route.query.leasing as string | null;
    if (leasingId) {
      this.$router.push({
        name: "account-payables.leasing.detail",
        params: { id: leasingId },
      });
    } else {
      this.$router.push({
        name: "cash-management.cash-in-out",
      });
    }
  }

  handleCancel(): void {
    this.loading.cancel = true;
    cashManagementServices
      .cancelCashOut(this.docId)
      .then(item => {
        this.handleBack();
        this.listDeletedId = [];
        this.showNotifSuccess("notif_cancel_success", {
          documentNumber: item.documentNo,
        });
      })
      .finally(() => {
        this.loading.cancel = false;
      });
  }

  buildPayload(): RequestCashOut {
    return {
      attachment: this.formModel.attachment,
      branchId: this.formModel.branch?.key ?? "",
      cashInOutLineDTOS: this.dataSource.map(item => ({
        accountId: item.account?.key ?? "",
        amount: item.amount ?? 0,
        notes: item.notes ?? "",
        lineId: item.id ?? "",
      })),
      journalNumber: "",
      cashInOutLineDelete: this.formModel.cashInOutLineDelete,
      cashInOutTransfer: this.formModel.cashInOutTransfer,
      chequeBankName: this.formModel.chequeBankName,
      chequeNo: this.formModel.chequeNo,
      companyBankId: this.formModel.companyBank?.key ?? "",
      currencyId: this.formModel.currency?.key ?? "",
      customerSupplierBank: this.formModel.customerSupplierBank,
      description: this.formModel.description,
      rates: this.formModel.rates,
      referenceNo: this.formModel.referenceNo,
      status: this.formModel.status as DOCUMENT_STATUS,
      supplierOrCustomerId: this.formModel.supplierOrCustomer?.key ?? "",
      totalAmount: this.formTotal.currencyTotalValue,
      transactionDate: this.formModel.transactionDate?.format() || null,
      transferSource: this.formModel.transferSource,
      type: this.formModel.type as TRANSACTION_TYPE,
    };
  }

  handleSaveDraft(): void {
    this.loading.saveDraft = true;
    const payload = this.buildPayload();
    payload.status = DOCUMENT_STATUS.DRAFT;
    this.postCashInOut(payload, "saveDraft");
  }

  postCashInOut(payload: RequestCashOut, loader: "saveDraft" | "submit"): void {
    this.loading[loader] = true;
    cashManagementServices
      .createCashOut(payload)
      .then(response => {
        this.$router.replace(
          "/cash-management/transactions/cash-in-out/edit/" + response.id
        );
        this.showNotifSuccess("notif_create_success", {
          documentNumber: response.documentNo || "",
        });
      })
      .finally(() => (this.loading[loader] = false));
  }

  updateCashInOut(id: string, payload: RequestCashOut): void {
    this.loading.update = true;
    cashManagementServices
      .updateCashOut(id, payload)
      .then(item => {
        this.formModel.cashInOutLineDelete = [];
        this.$router.push("/cash-management/transactions/cash-in-out/");
        this.showNotifSuccess("notif_update_success", {
          documentNumber: item.documentNo || "",
        });
      })
      .finally(() => {
        this.loading.update = false;
      });
  }

  async getAccountList(params?: RequestQueryParams) {
    const { findAllChildAccount, toOptions } = useCoa();
    const response = await findAllChildAccount(params);
    this.optAccounts = toOptions(response.data);
  }

  onSearchAccount(row: Row, value?: string) {
    const { filterBy, findAllChildAccount, toOptions } = useCoa();
    row.isSearching = true;
    debounce(() => {
      const params = new RequestQueryParams();
      params.search = filterBy({ code: value, description: value });
      row.isLoading = true;
      findAllChildAccount(params)
        .then(response => {
          row.optAccounts = toOptions(response.data);
        })
        .finally(() => (row.isLoading = false));
    }, 500);
  }

  async getBranchList(params?: RequestQueryParamsModel) {
    const { findAll, toOptions } = useBranch();
    this.loading.branch = true;
    const response = await findAll(params);
    this.optBranchs = toOptions(response.data);
    this.loading.branch = false;
  }

  onSearchBranch(value?: string) {
    const { searchBy } = useBranch();
    const params = new RequestQueryParams();
    params.search = searchBy({ name: value });
    this.getBranchList(params);
  }
}
