import {
  StockAdjRow,
  StockAdjustmentRequestCreateDTO,
  StockAdjustmentResponseDTO,
} from "@/models/interface/stock-adjustment";
import { assetsServices } from "@/services/assets.service";
import { stockAdjustmentService } from "@/services/stock-adjustment.service";
import { formatterNumber } from "@/validator/globalvalidator";
import Decimal from "decimal.js-light";

export const useCountQtyDifference = (row: StockAdjRow): string => {
  const physicalQty = row.physicalQty ?? 0;
  const qtyTotal = row.qty ?? 0;
  let diff = new Decimal(qtyTotal).minus(physicalQty).times(-1);

  if (qtyTotal < 0 && physicalQty < 0) {
    diff = new Decimal(qtyTotal).add(physicalQty);
  }

  return diff.isPositive()
    ? `+${formatterNumber(diff.toNumber())}`
    : formatterNumber(diff.toNumber());
};

export const useStockAdjustment = () => {
  const uploadAttachment = (file: File) => {
    const module = "logistic";
    const formData = new FormData();
    formData.append("file", file);
    return assetsServices.createUploadFile(formData, module);
  };

  const findById = (id: string): Promise<StockAdjustmentResponseDTO> => {
    return stockAdjustmentService.getStockAdjustmentById(id);
  };

  const createDraft = (
    payload: StockAdjustmentRequestCreateDTO
  ): Promise<StockAdjustmentResponseDTO> => {
    return stockAdjustmentService.createStockAdjustment(payload);
  };

  const submit = (
    docId: string | "-",
    payload: StockAdjustmentRequestCreateDTO
  ): Promise<StockAdjustmentResponseDTO> => {
    return stockAdjustmentService.submitStockAdjustment(payload, docId);
  };

  const update = (
    id: string,
    payload: StockAdjustmentRequestCreateDTO
  ): Promise<StockAdjustmentResponseDTO> => {
    return stockAdjustmentService.updateStockAdjustment(payload, id);
  };

  const viewAttachment = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    link.setAttribute("rel", "noopener noreferrer");
    link.click();
    link.remove();
  };

  const isRowsInvalid = (rows: Array<StockAdjRow>): boolean => {
    const isEmpty: boolean = rows.length === 0;
    const emptyProduct = !!rows.find(row => !row.productCode);

    return isEmpty || emptyProduct;
  };

  return {
    isRowsInvalid,
    createDraft,
    findById,
    update,
    uploadAttachment,
    submit,
    viewAttachment,
  };
};
