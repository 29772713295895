























































































































































import SelectAssetCategory from "@/components/custom/select/SelectAssetCategory.vue";
import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCustomer from "@/components/custom/select/SelectCustomer.vue";
import SelectUnitCode from "@/components/custom/select/SelectUnitCode.vue";
import dateFormat from "@/filters/date.filter";
import quantityFilter from "@/filters/quantity.filter";
import { useContactData } from "@/hooks/contact";
import useBlob from "@/hooks/useBlob";
import useDate from "@/hooks/useDate";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  AddressDataDto,
  ListContactDataDto,
} from "@/models/interface/contact-data";
import { ReportMaintenanceFormState } from "@/models/interface/sales-order/reports/maintenance/ReportMaintenanceFormState.interface";
import { ReportMaintenanceResponseDTO } from "@/models/interface/sales-order/reports/maintenance/ReportMaintenanceResponseDto.interface";
import { reportMaintenanceService } from "@/services/report-maintenance.service";
import { currencyFormat } from "@/validator/globalvalidator";
import { FormModel } from "ant-design-vue";
import { Component, Ref, Vue } from "vue-property-decorator";

@Component({
  components: {
    SelectBranch,
    SelectUnitCode,
    SelectCustomer,
    SelectAssetCategory,
  },
})
export default class ReportMaintenance extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  @Ref("formModel")
  formModel!: FormModel;

  formState: ReportMaintenanceFormState = new ReportMaintenanceFormState();

  customerLocationOptions: Option<AddressDataDto>[] = [];

  loading = {
    find: false,
    download: false,
  };

  columns = [
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "assetCode",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "customerName",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      key: "branchName",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_rwo_total"),
      dataIndex: "totalRwo",
      key: "totalRwo",
      customRender: text => currencyFormat(text),
    },
    {
      title: this.$t("lbl_po_total"),
      dataIndex: "totalPo",
      key: "poTotal",
      customRender: text => currencyFormat(text),
    },
  ];

  rwoColumn = [
    {
      title: this.$t("lbl_document_number"),
      dataIndex: "rwoNumber",
      key: "rwoNumber",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_date"),
      dataIndex: "rwoDate",
      key: "rwoDate",
      customRender: text => dateFormat(text),
    },
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "rwoPartNumber",
      key: "rwoPartNumber",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "rwoPartName",
      key: "rwoPartName",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "rwoUom",
      key: "rwoUom",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "rwoQty",
      key: "rwoQty",
      customRender: text => quantityFilter(text),
    },
    {
      title: this.$t("lbl_cogs"),
      dataIndex: "rwoCogs",
      key: "rwoCogs",
      customRender: text => currencyFormat(text),
    },
    {
      title: this.$t("lbl_total"),
      dataIndex: "rwoTotal",
      key: "rwoTotal",
      customRender: text => currencyFormat(text),
    },
  ];

  poColumn = [
    {
      title: this.$t("lbl_document_number"),
      dataIndex: "poNumber",
      key: "poNumber",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_date"),
      dataIndex: "poDate",
      key: "poDate",
      customRender: text => dateFormat(text),
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "polDescription",
      key: "polDescription",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "poPartNumber",
      key: "poPartNumber",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "poPartName",
      key: "poPartName",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "poUom",
      key: "poUom",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "poQty",
      key: "poQty",
      customRender: text => quantityFilter(text),
    },
    {
      title: this.$t("lbl_cogs"),
      dataIndex: "poPrice",
      key: "poPrice",
      customRender: text => currencyFormat(text),
    },
    {
      title: this.$t("lbl_total"),
      dataIndex: "poTotal",
      key: "poTotal",
      customRender: text => currencyFormat(text),
    },
  ];

  dataSource: ReportMaintenanceResponseDTO = {
    grandTotalRwo: 0,
    grandTotalPo: 0,
    data: [],
  };

  buildQueryParams(): RequestQueryParams {
    const { toStartDay, toEndDay } = useDate();
    const params = new RequestQueryParams();

    const { assetCategory, branch, unitCode, rwoDate, poDate, customer } =
      this.formState;
    if (assetCategory?.key) {
      params.unitCategory = assetCategory.key;
    }

    if (branch?.key) {
      params.branchId = branch.key;
    }

    if (customer?.key) {
      params.customerSecureId = customer.key;
    }

    if (unitCode?.key) {
      params.assetId = unitCode.key;
    }

    if (rwoDate && rwoDate.length) {
      const [start, end] = rwoDate;
      params.rwoStartDate = toStartDay(start).format();
      params.rwoEndDate = toEndDay(end).format();
    }

    if (poDate && poDate.length) {
      const [start, end] = poDate;
      params.poStartDate = toStartDay(start).format();
      params.poEndDate = toEndDay(end).format();
    }

    return params;
  }

  mapDownloadParams(): string {
    const defaultParam = "ALL";

    let poStartDate = defaultParam;
    let poEndDate = defaultParam;
    let rwoStartDate = defaultParam;
    let rwoEndDate = defaultParam;

    if (this.formState.poDate && this.formState.poDate.length > 1) {
      const [start, end] = this.formState.poDate;
      poStartDate = start.format();
      poEndDate = end.format();
    }

    if (this.formState.rwoDate && this.formState.rwoDate.length > 1) {
      const [start, end] = this.formState.rwoDate;
      rwoStartDate = start.format();
      rwoEndDate = end.format();
    }

    const params: string[] = [
      this.$store.state.authStore.authData.companyName,
      this.formState.branch?.label ?? defaultParam,
      this.formState.assetCategory?.label ?? defaultParam,
      this.formState.unitCode?.label ?? defaultParam,
      this.formState.customer?.label ?? defaultParam,
      poStartDate,
      poEndDate,
      rwoStartDate,
      rwoEndDate,
    ];

    return params.join(",");
  }

  handleCustomerChange(customer: Option<ListContactDataDto> | undefined): void {
    const { toContactLocationOptions } = useContactData();
    this.customerLocationOptions = toContactLocationOptions(
      customer?.meta?.addressDataList || []
    );
  }

  handleReset(): void {
    this.formState = new ReportMaintenanceFormState();
  }

  handleFind(): void {
    this.loading.find = true;
    reportMaintenanceService
      .list(this.buildQueryParams())
      .then(response => {
        this.dataSource = response;
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  handleDownload(): void {
    const { toDownload } = useBlob();
    const params = this.buildQueryParams();
    params.params = this.mapDownloadParams();

    this.loading.download = true;
    reportMaintenanceService
      .download(params)
      .then(response => {
        toDownload(response, "report_maintenance.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }
}
