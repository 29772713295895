export enum PREFERENCE_PURCHASE_KEY {
  IMPORT_COST_INCOME_TAX = "purchase_import_cost_pph_22",
  IMPORT_COST_VAT_TAX = "purchase_import_cost_ppn",
}

export enum PREFERENCE_FEATURE_KEY {
  FEATURE_DECIMAL_POINT = "feature_decimal_point",
  FEATURE_BASE_CURRENCY = "feature_base_currency",
}

export enum PREFERENCE_ACCOUNT_SETUP_KEY {
  ACCOUNT_RECEIVABLE = "account_setup_account_receiviables",
  ACCOUNT_SETUP_UNBILLED_PAYABLES = "account_setup_unbilled_payables",
  ACCOUNT_SETUP_ACCOUNT_PAYABLES = "account_setup_account_payables",
  ACCOUNT_SETUP_PREPAYMENT_AR = "account_setup_prepayment_ar",
}

const PreferenceKey = {
  ACCOUNT_INVENTORY_IN_TRANSIT: "account_inventory_in_transit",
  ACCOUNT_SETUP_ACCOUNT_PAYABLES: "account_setup_account_payables",
  ACCOUNT_SETUP_ACCOUNT_RECEIVIABLES: "account_setup_account_receiviables",
  ACCOUNT_SETUP_CURRENT_MONTH_PROFIT: "account_setup_current_month_profit",
  ACCOUNT_SETUP_CURRENT_YEAR_PROFIT: "account_setup_current_year_profit",
  ACCOUNT_SETUP_EXCHANGE_RATE_VARIANCE_GAIN:
    "account_setup_exchange_rate_variance_gain",
  ACCOUNT_SETUP_EXCHANGE_RATE_VARIANCE_LOSS:
    "account_setup_exchange_rate_variance_loss",
  ACCOUNT_SETUP_INVENTORY: "account_setup_inventory",
  ACCOUNT_SETUP_INVENTORY_NON_STOCK: "account_setup_inventory_non_stock",
  ACCOUNT_SETUP_INVENTORY_WASTE: "account_setup_inventory_waste",
  ACCOUNT_SETUP_INVOICE_PRICE_VARIANCE: "account_setup_invoice_price_variance",
  ACCOUNT_SETUP_OPENING_BALANCE_EQUITY: "account_setup_opening_balance_equity",
  ACCOUNT_SETUP_PREPAYMENT_AP: "account_setup_prepayment_ap",
  ACCOUNT_SETUP_PREPAYMENT_AR: "account_setup_prepayment_ar",
  ACCOUNT_SETUP_PREPAYMENT_DEALER: "account_setup_prepayment_dealer",
  ACCOUNT_SETUP_PURCHASE_BAD_DEBT: "account_setup_purchase_bad_debt",
  ACCOUNT_SETUP_PURCHASE_COGS: "account_setup_purchase_cogs",
  ACCOUNT_SETUP_PURCHASE_DISCOUNT: "account_setup_purchase_discount",
  ACCOUNT_SETUP_PURCHASE_PRICE_VARIANCE:
    "account_setup_purchase_price_variance",
  ACCOUNT_SETUP_PURCHASE_RETURN: "account_setup_purchase_return",
  ACCOUNT_SETUP_PURCHASE_SHIPPING: "account_setup_purchase_shipping",
  ACCOUNT_SETUP_RETAINED_EARNING: "account_setup_retained_earning",
  ACCOUNT_SETUP_ROUNDING_ACCOUNT_GAIN: "account_setup_rounding_account_gain",
  ACCOUNT_SETUP_ROUNDING_ACCOUNT_LOSS: "account_setup_rounding_account_loss",
  ACCOUNT_SETUP_SALES_BAD_DEBT: "account_setup_sales_bad_debt",
  ACCOUNT_SETUP_SALES_DELIVERY: "account_setup_sales_delivery",
  ACCOUNT_SETUP_SALES_DISCOUNT: "account_setup_sales_discount",
  ACCOUNT_SETUP_SALES_RETURN: "account_setup_sales_return",
  ACCOUNT_SETUP_SALES_REVENUE: "account_setup_sales_revenue",
  ACCOUNT_SETUP_STOCK_ADJUSTMENT: "account_setup_stock_adjustment",
  ACCOUNT_SETUP_STOCK_VARIANCE: "account_setup_stock_variance",
  ACCOUNT_SETUP_UNBILLED_PAYABLES: "account_setup_unbilled_payables",
  ACCOUNT_SETUP_UNBILLED_RECEIVABLES: "account_setup_unbilled_receivables",
  ACCOUNT_SETUP_UNBILLED_SALES: "account_setup_unbilled_sales",
  ACCOUNT_SETUP_UNEARNED_REVENUE: "account_setup_unearned_revenue",
  ACCOUNT_SETUP_VAT_IN: "account_setup_vat_in",
  ACCOUNT_SETUP_VAT_OUT: "account_setup_vat_out",
  FEATURE_APPROVAL: "feature_approval",
  FEATURE_ASSET_MANAGEMENT: "feature_asset_management",
  FEATURE_BASE_CURRENCY: "feature_base_currency",
  FEATURE_DECIMAL_FORMAT: "feature_decimal_format",
  FEATURE_DECIMAL_POINT: "feature_decimal_point",
  FEATURE_DEFAULT_ASSIGNEE: "feature_default_assignee",
  FEATURE_DISCOUNT_BEFORE_AFTER_TAX: "feature_discount_before_after_tax",
  FEATURE_MULTI_BRANCH: "feature_multi_branch",
  FEATURE_MULTI_CURRENCY: "feature_multi_currency",
  FEATURE_PURCHASE_TAX_RATE: "feature_purchase_tax_rate",
  FEATURE_PURCHASE_INCLUSIVE_TAX_RATE: "feature_purchase_inclusive_tax_rate",
  FEATURE_SALES_TAX_RATE: "feature_sales_tax_rate",
  FEATURE_SALES_INCLUSIVE_TAX_RATE: "feature_sales_inclusive_tax_rate",
  FEATURE_TAX_INCLUSIVE: "feature_tax_inclusive",
  INVENTORY_COSTING_METHOD: "inventory_costing_method",
  INVENTORY_MULTI_UOM: "inventory_multi_uom",
  INVENTORY_MULTI_WAREHOUSE: "inventory_multi_warehouse",
  INVENTORY_SERIAL_NUMBER: "inventory_serial_number",
  INVENTORY_SHOW_PRODUCT_STOCK: "inventory_show_product_stock",
  PURCHASE_PURCHASE_RETURN: "purchase_purchase_return",
  PURCHASE_QUOTATION: "purchase_quotation",
  PURCHASE_TOP: "purchase_top",
  SALES_COMMISION_COUNTING_FROM: "sales_commision_counting_from",
  SALES_DISABLE_SELL_WHEN_NOT_ENOUGH_QTY:
    "sales_disable_sell_when_not_enough_qty",
  SALES_JOIN_INVOICE: "sales_join_invoice",
  SALES_ORDER_RETURN: "sales_order_return",
  SALES_QUOTATION_AND_SALES_ORDER: "sales_quotation_and_sales_order",
  SALES_TOP: "sales_top",
  TRUCKING_ASSIGNEE: "trucking_assignee",
  TRUCKING_DOWN_PAYMENT: "trucking_down_payment",
  TRUCKING_REVENUE: "trucking_revenue",
  TRUCKING_TAX: "trucking_tax",
  TRUCKING_RECEIVABLE_ACCOUNT: "trucking_receivable_account",
  TRUCKING_DEFAULT_BRANCH: "trucking_default_branch",
} as const;

export type EnumPreferenceKey =
  (typeof PreferenceKey)[keyof typeof PreferenceKey];
