var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c("legend", [
        _c(
          "div",
          { staticClass: "heading" },
          [
            _c("span", [_vm._v(_vm._s(_vm.$t("lbl_tax_base_variable")))]),
            _vm.$can("create", "tax-base-variable")
              ? _c(
                  "a-button",
                  { attrs: { type: "primary" }, on: { click: _vm.addRecord } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("common.add-text", {
                            text: _vm.$t("lbl_variable")
                          })
                        ) +
                        " "
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c("a-table", {
        attrs: {
          "data-source": _vm.dataSource,
          columns: _vm.columns,
          size: "small",
          pagination: false,
          "row-class-name": function(_record, index) {
            return index % 2 ? "bg-white" : "bg-gray-light"
          },
          scroll: { x: "100%" },
          loading: _vm.loading
        },
        scopedSlots: _vm._u([
          {
            key: "description",
            fn: function(text, record) {
              return [
                record.isEdit
                  ? _c("a-input", {
                      attrs: {
                        "allow-clear": "",
                        placeholder: _vm.$t("common.insert-text", {
                          text: _vm.$t("lbl_description")
                        })
                      },
                      model: {
                        value: record.description,
                        callback: function($$v) {
                          _vm.$set(record, "description", $$v)
                        },
                        expression: "record.description"
                      }
                    })
                  : _c("span", [_vm._v(_vm._s(record.description || "-"))])
              ]
            }
          },
          {
            key: "variable",
            fn: function(text, record) {
              return [
                record.isEdit
                  ? _c("a-input-number", {
                      staticClass: "w-100",
                      attrs: {
                        placeholder: _vm.$t("common.insert-text", {
                          text: _vm.$t("lbl_variable")
                        }),
                        "data-testid": "tax-base-variable-variable"
                      },
                      model: {
                        value: record.variable,
                        callback: function($$v) {
                          _vm.$set(record, "variable", $$v)
                        },
                        expression: "record.variable"
                      }
                    })
                  : _c("span", [_vm._v(_vm._s(record.variable))])
              ]
            }
          },
          {
            key: "effectiveDate",
            fn: function(text, record) {
              return [
                record.isEdit
                  ? _c("a-date-picker", {
                      staticClass: "w-100",
                      attrs: {
                        "allow-clear": "",
                        placeholder: _vm.$t("common.select-text", {
                          text: _vm.$t("lbl_start_effective_date")
                        }),
                        "data-testid": "tax-base-variable-effective-date",
                        format: _vm.DEFAULT_DATE_FORMAT,
                        "disabled-date": _vm.effectiveDateRules
                      },
                      model: {
                        value: record.effectiveDate,
                        callback: function($$v) {
                          _vm.$set(record, "effectiveDate", $$v)
                        },
                        expression: "record.effectiveDate"
                      }
                    })
                  : _c("span", [
                      _vm._v(_vm._s(_vm._f("date")(record.effectiveDate)))
                    ])
              ]
            }
          },
          {
            key: "isLuxuryGoods",
            fn: function(text, record) {
              return [
                record.isEdit
                  ? _c("a-switch", {
                      attrs: {
                        "data-testid": "tax-base-variable-is-luxury-goods"
                      },
                      model: {
                        value: record.isLuxuryGoods,
                        callback: function($$v) {
                          _vm.$set(record, "isLuxuryGoods", $$v)
                        },
                        expression: "record.isLuxuryGoods"
                      }
                    })
                  : _c("DisplayBoolean", {
                      attrs: { value: record.isLuxuryGoods, mode: "yes" }
                    })
              ]
            }
          },
          {
            key: "action",
            fn: function(_, record) {
              return [
                _c(
                  "a-space",
                  [
                    record.isEdit
                      ? [
                          _c(
                            "a-tooltip",
                            { attrs: { title: _vm.$t("lbl_save") } },
                            [
                              _c("a-button", {
                                attrs: {
                                  icon: "check",
                                  type: "primary",
                                  disabled:
                                    record.variable === null ||
                                    record.effectiveDate == null,
                                  loading: record.loadingCreate
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleSave(record)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          !record.isNew
                            ? [
                                _c(
                                  "a-tooltip",
                                  { attrs: { title: _vm.$t("lbl_close") } },
                                  [
                                    _c("a-button", {
                                      attrs: { icon: "close" },
                                      on: {
                                        click: function($event) {
                                          return _vm.closeEdit(record)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ]
                      : !record.isRunning &&
                        record.id !== null &&
                        _vm.$can("update", "tax-base-variable")
                      ? [
                          _c(
                            "a-tooltip",
                            { attrs: { title: _vm.$t("lbl_edit") } },
                            [
                              _c("a-button", {
                                attrs: { icon: "edit", type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.editRecord(record)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    !record.isRunning && _vm.$can("delete", "tax-base-variable")
                      ? [
                          _c(
                            "a-tooltip",
                            { attrs: { title: _vm.$t("lbl_delete") } },
                            [
                              _c("a-button", {
                                attrs: {
                                  icon: "delete",
                                  type: "danger",
                                  loading: record.loadingDelete
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteRecord(record)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : record.isRunning
                      ? [_vm._v(" " + _vm._s(_vm.$t("lbl_active")) + " ")]
                      : _vm._e()
                  ],
                  2
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }