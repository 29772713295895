import { LabelInValue } from "@/types";
import { Moment } from "moment";

export class ReportMaintenanceFormState {
  branch: LabelInValue | undefined = undefined;
  customer: LabelInValue | undefined = undefined;
  unitCode: LabelInValue | undefined = undefined;
  rwoDate: Moment[] | null = null;
  poDate: Moment[] | null = null;
  assetCategory: LabelInValue | undefined = undefined;
}
