






import TaxBaseVariableForm from "@/components/TaxBaseVariable/TaxBaseVariableForm.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    TaxBaseVariableForm,
  },
})
export default class PreferenceTaxBaseVariable extends Vue {}
