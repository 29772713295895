import {
  LogisticStockAdjustmentState,
  LogisticStockAdjustmentType,
} from "@/models/enums/stock-adjustment.enum";
import {
  StockAdjustmentLineRequestCreateDTO,
  StockAdjustmentRequestCreateDTO,
  StockAdjustmentResponseDTO,
} from "@/models/interface/stock-adjustment";

export const buildDetailDto = (): StockAdjustmentResponseDTO => ({
  attachment: "",
  branchId: "",
  branchName: "",
  createdDate: "",
  date: "",
  documentNumber: "",
  id: "",
  journalNumber: "",
  modifiedDate: "",
  notes: "",
  postingDate: "",
  state: "" as LogisticStockAdjustmentState,
  stockAdjustmentLines: [],
  submittedDate: "",
  type: "" as LogisticStockAdjustmentType,
  warehouseLocation: "",
  warehouseLocationId: "",
});

export const buildCreateDto = (): StockAdjustmentRequestCreateDTO => ({
  attachment: "",
  deletedLineIds: [],
  notes: "",
  stockAdjustmentLines: [],
  type: "" as LogisticStockAdjustmentType,
  warehouseLocationId: "",
});

export const buildLineCreateDto = (): StockAdjustmentLineRequestCreateDTO => ({
  physicalQty: 0,
  productId: "",
  qty: 0,
  id: "",
  uomId: "",
  serialNumber: null,
});
