

















































































































import { SearchBuilder } from "@/builder";
import SelectProduct from "@/components/custom/select/SelectProduct.vue";
import { useProduct } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  ProductDetailDto,
  ProductResponseDto,
} from "@/models/interface/master-product";
import { FormValue, StockAdjRow } from "@/models/interface/stock-adjustment";
import { ColumnDef } from "@/types";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";

@Component({
  components: {
    SelectProduct,
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) => st.preferenceStore.baseDecimalPlace,
    }),
    ...mapGetters({
      form: "stockAdjStore/getForm",
    }),
  },
  methods: {
    ...mapActions({
      addRow: "stockAdjStore/addStockAdjRow",
      deleteRow: "stockAdjStore/deleteStockAdjRow",
    }),
  },
})
export default class FormTableAdjustment extends Mixins(MNotificationVue) {
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  storeBaseDecimalPlace!: number;
  form!: FormValue;
  addRow!: () => void;
  deleteRow!: (payload: Array<number>) => void;

  selectedRowKeys: Array<number> = [];

  columns: ColumnDef[] = [
    {
      // user pilih dari master product
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      scopedSlots: { customRender: "productCode" },
    },
    {
      // user pilih dari master product
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      scopedSlots: { customRender: "productName" },
    },
    {
      // autofill dari product yang dipilih
      //   statis
      title: this.$t("lbl_brand"),
      dataIndex: "productMerk",
      width: "150px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      width: "200px",
      scopedSlots: { customRender: "type" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      width: "200px",
      scopedSlots: { customRender: "serialNumber" },
    },
    {
      // user input
      title: this.$t("lbl_qty_adjustment"),
      dataIndex: "qty",
      width: "200px",
      scopedSlots: { customRender: "qtyAdjustment" },
    },
    {
      // user input
      title: this.$t("lbl_uom"),
      dataIndex: "uomName",
      width: "150px",
      scopedSlots: { customRender: "uom" },
    },
  ];

  @Watch("form.warehouseLocation", { deep: true })
  handleWarehouseLocationChange(): void {
    this.form.stockAdjustmentRows.forEach(row => {
      if (row.productCode?.key) {
        this.getSerialNumberOptions(row.productCode.key, row);
      }
    });
  }

  created(): void {
    if (!this.$route.params.id) return;
    this.initSerialNumberOptions();
  }

  initSerialNumberOptions(): void {
    this.form.stockAdjustmentRows.forEach((row: StockAdjRow) => {
      if (row.type === "decrement") {
        this.getSerialNumberOptions(row.productCode?.key ?? "", row);
      }
    });
  }

  onChangeProduct(
    e: Option<ProductResponseDto> | undefined,
    row: StockAdjRow
  ): void {
    row.productCode = undefined;
    row.productName = undefined;
    row.productMerk = e?.meta?.merk || "";
    row.uom = undefined;
    row.serialNumber = null;

    if (e && e.meta) {
      row.productCode = {
        label: e.meta.code,
        key: e.meta.id,
      };
      row.productName = {
        label: e.meta.name,
        key: e.meta.id,
      };
    }

    if (!e?.meta?.id) return;
    this.getDetailProduct(e.meta.id, ({ baseUnit, baseUnitId }) => {
      if (baseUnit && baseUnitId) {
        row.uom = {
          key: baseUnitId,
          label: baseUnit,
        };
        row.uomOptions = [
          { key: baseUnitId, label: baseUnit, value: baseUnitId },
        ];
      }
    });

    if (row.type === "increment") return;
    this.getSerialNumberOptions(e.meta.id, row);
  }

  getSerialNumberOptions(productId: string, row: StockAdjRow): void {
    const builder = new SearchBuilder();
    const queries: string[] = [];

    queries.push(builder.push(["productId", productId]).build());
    if (this.form.warehouseLocation?.key) {
      queries.push(
        builder.push(["locationId", this.form.warehouseLocation.key]).build()
      );
    }

    const params: RequestQueryParamsModel = {
      search: queries.join(SearchBuilder.AND),
    };
    row.loadingSerialNumber = true;
    this.getSerialNumberList(params)
      .then(response => {
        row.serialNumberOptions = response;
      })
      .catch(error => {
        console.log("error", error);
        row.serialNumberOptions = [];
      })
      .finally(() => {
        row.loadingSerialNumber = false;
      });
  }

  getDetailProduct(id: string, cb: (payload: ProductDetailDto) => void): void {
    const { findById } = useProduct();
    findById(id).then(cb);
  }

  handleDeleteRow(): void {
    this.showConfirmationDeleteItems(() => {
      this.deleteRow(this.selectedRowKeys);
      this.selectedRowKeys = [];
    });
  }

  onSelectChange(keys: Array<number>): void {
    this.selectedRowKeys = keys;
  }

  onChangeType(val: "increment" | "decrement", row: StockAdjRow): void {
    row.serialNumber = null;
    if (val === "increment") {
      row.maxQty = undefined;
      row.minQty = 0;
    } else {
      row.maxQty = 0;
      row.minQty = undefined;
      if (!row.productCode) return;
      this.getSerialNumberOptions(row.productCode.key, row);
    }
  }

  async getSerialNumberList(
    params?: RequestQueryParamsModel
  ): Promise<Option[]> {
    try {
      const { findAllSnForSales } = useProduct();
      const response = await findAllSnForSales(params);
      return response.map(item => ({
        label: item.serialNumber,
        key: item.serialNumber,
        value: item.serialNumber,
      }));
    } catch {
      return [];
    }
  }
}
