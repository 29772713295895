import { generateUUID } from "@/helpers/uuid";
import { Moment } from "moment";

export type TaxBaseVariableFormState = {
  key: string;
  id: string | null;
  no: number;
  variable: number;
  effectiveDate: Moment | null;
  isLuxuryGoods: boolean;
  description: string | null;
  isRunning: boolean;
  isEdit: boolean;
  isNew: boolean;
  loadingCreate: boolean;
  loadingDelete: boolean;
};

export function buildTaxVariableFormState(): TaxBaseVariableFormState {
  return {
    key: generateUUID(),
    id: null,
    no: 1,
    variable: 0,
    effectiveDate: null,
    isLuxuryGoods: false,
    description: null,
    isRunning: false,
    isEdit: false,
    isNew: false,
    loadingCreate: false,
    loadingDelete: false,
  };
}
