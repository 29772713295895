import SALES_ORDER_STATUS from "@/models/enums/sales-order.enum";
import { SalesOrderTypeEnum } from "@/models/enums/SalesOrderType.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import {
  SalesOrderCreateRequestDto,
  SalesOrderLineRequestDto,
} from "@/models/interface/sales-order";

export function buildSalesOrderLineRequestDto(): SalesOrderLineRequestDto {
  return {
    assetId: "",
    assetParentId: "",
    backupUnit: false,
    customerLocation: "",
    discountValue: 0,
    id: "",
    internalContractLineId: "",
    locationId: "",
    percentDiscount: 0,
    price: 0,
    productId: "",
    qty: 0,
    qtyAvailable: 0,
    serialNumber: "",
    subTotal: 0,
    taxId: "",
    uomId: "",
  };
}

export function buildSalesOrderCreateDto(): SalesOrderCreateRequestDto {
  return {
    branchWarehouseId: "",
    currency: "",
    currencyRate: 0,
    customerBillToAddress: "",
    customerId: "",
    customerPoNumber: "",
    customerShipToAddress: "",
    date: "",
    deliveryDate: "",
    grandTotal: 0,
    internalContractId: "",
    notes: "",
    salesId: "",
    salesOrderLines: [],
    salesType: "" as SalesOrderTypeEnum,
    state: SALES_ORDER_STATUS.DRAFT,
    taxCalculation: "" as TAX_CALCULATION,
    termOfPayment: 0,
    total: 0,
    totalDiscount: 0,
    totalTax: 0,
    isLuxury: false,
  };
}
