import { Api } from "@/models/class/api.class";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { ReportMaintenanceResponseDTO } from "@/models/interface/sales-order/reports/maintenance/ReportMaintenanceResponseDto.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class ReportMaintenanceService extends HttpClient {
  list(params: RequestQueryParamsModel): Promise<ReportMaintenanceResponseDTO> {
    return this.get<ReportMaintenanceResponseDTO>(Api.ReportMaintenance, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  download(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.ReportMaintenanceDownload, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const reportMaintenanceService = new ReportMaintenanceService();
