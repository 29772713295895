import { SearchBuilder } from "@/builder";
import { isNotUnique } from "@/helpers/common";
import { Option } from "@/models/class/option.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { ProductTypeEnum } from "@/models/enums/ProductType.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  ProductAccountDto,
  ProductCreateRequestDto,
  ProductDetailDto,
  ProductResponseDto,
  ProductStockResponseDto,
  ProductSupplierDto,
  ProductUomConversionDto,
  ProductUpdateRequestDto,
} from "@/models/interface/master-product";
import { uomData } from "@/models/interface/product.interface";
import { ProductPurchaseResponseDto } from "@/models/interface/ProductPurchaseResponse.interface";
import { logisticServices } from "@/services/logistic.service";
import { productService } from "@/services/product.service";
import authStore from "@/store/auth.store";
import {
  FormValue,
  ProductSupplierRow,
  UomConversionRow,
} from "@/store/product.store";
import {
  initFormProduct,
  initProductAccountSelect,
  ProductAccountSelectType,
} from "@/store/resources/product.resource";
import { useFindMasterType } from "./master-type";

type FilterField = Partial<{
  partNumber: string;
  description: string;
  code: string;
  name: string;
  categoryName: string;
  type: string;
}>;

type ProductInStockField = Partial<{
  categoryName: string;
  code: string;
  name: string;
  type: string;
  desc: string;
}>;

const useProduct = () => {
  const create = (payload: ProductCreateRequestDto) => {
    return productService.createProduct(payload);
  };

  const update = (id: string, payload: ProductUpdateRequestDto) => {
    return productService.updateProduct(payload, id);
  };

  const findById = (id: string) => {
    return productService.getDetailProduct(id);
  };

  const findAll = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<ProductResponseDto>> => {
    return logisticServices.getListProduct(params);
  };

  const findAllRemastered = (params?: RequestQueryParamsModel) => {
    return productService.getListMasterProduct(params);
  };

  const searchByRemastered = (field: FilterField): string => {
    const builder = new SearchBuilder();
    const q: string[] = [];

    if (field.partNumber) {
      q.push(
        builder
          .push(["partNumber", field.partNumber], {
            like: "both",
          })
          .build()
      );
    }

    if (field.description) {
      q.push(
        builder
          .push(["description", field.description], { like: "both" })
          .build()
      );
    }

    if (field.name) {
      q.push(builder.push(["partName", field.name], { like: "both" }).build());
    }

    if (field.categoryName) {
      q.push(
        builder.push(["category", field.categoryName], { like: "both" }).build()
      );
    }

    if (field.type) {
      q.push(
        builder.push(["productType", field.type], { like: "both" }).build()
      );
    }

    return q.join(builder.OR);
  };

  const searchProductInStock = ({
    code = "",
    name = "",
    desc = "",
    type = "",
    categoryName = "",
  }: ProductInStockField): string => {
    const q: string[] = [];

    if (code) {
      q.push(
        new SearchBuilder()
          .push(["productCode", code], { like: "both" })
          .build()
      );
    }

    if (name) {
      q.push(
        new SearchBuilder()
          .push(["productName", name], { like: "both" })
          .build()
      );
    }

    if (desc) {
      q.push(
        new SearchBuilder()
          .push(["description", desc], { like: "both" })
          .build()
      );
    }

    if (type) {
      q.push(
        new SearchBuilder().push(["type", type], { like: "both" }).build()
      );
    }

    if (categoryName) {
      q.push(
        new SearchBuilder()
          .push(["categoryName", categoryName], { like: "both" })
          .build()
      );
    }

    return q.join(new SearchBuilder().OR);
  };

  const searchBy = (field: FilterField): string => {
    const builder = new SearchBuilder();
    const q: string[] = [];

    if (field.partNumber) {
      q.push(
        builder
          .push(["productSuppliers.partNumber", field.partNumber], {
            like: "both",
          })
          .build()
      );
    }

    if (field.description) {
      q.push(
        builder
          .push(["description", field.description], { like: "both" })
          .build()
      );
    }

    if (field.code) {
      q.push(builder.push(["code", field.code], { like: "both" }).build());
    }

    if (field.name) {
      q.push(builder.push(["name", field.name], { like: "both" }).build());
    }

    if (field.categoryName) {
      q.push(
        builder
          .push(["category.name", field.categoryName], { like: "both" })
          .build()
      );
    }

    if (field.type) {
      q.push(builder.push(["type", field.type], { like: "both" }).build());
    }

    return q.join(builder.OR);
  };

  const download = (param: RequestQueryParamsModel) => {
    return productService.downloadMasterProduct(param);
  };

  const buildDownloadParam = (): string => {
    const q: string[] = [authStore.state.authData.companyName];
    return q.join(",");
  };

  const findUomConvByProduct = async (
    productId: string
  ): Promise<uomData[]> => {
    const res = await productService.detailProduct(productId);
    return res.uomConversions;
  };

  const toOptions = (
    data: ProductResponseDto[]
  ): Option<ProductResponseDto>[] => {
    return data.map(item => ({
      key: item.id,
      label: item.name + " (" + item.code + ")",
      value: item.id,
      meta: item,
    }));
  };

  const toOptionsCode = (
    data: ProductResponseDto[]
  ): Option<ProductResponseDto>[] => {
    return data.map(item => ({
      key: item.id,
      label: item.code,
      value: item.id,
      meta: item,
    }));
  };

  const toOptionsName = (
    data: ProductResponseDto[]
  ): Option<ProductResponseDto>[] => {
    return data.map(item => ({
      key: item.id,
      label: item.name,
      value: item.id,
      meta: item,
    }));
  };

  const findAllSnForSales = (
    params?: RequestQueryParamsModel
  ): Promise<ProductPurchaseResponseDto[]> => {
    return productService.listSerialNumberForSales(params);
  };

  const findAllProductInStock = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<ProductStockResponseDto>> => {
    return productService.getListProductInStock(params);
  };

  const findAllProductType = () => {
    return useFindMasterType("PRODUCT_TYPE");
  };

  const uploadImage = (file: FormData) => {
    return productService.uploadPartImage(file);
  };

  const initProductSupplierDto = (): ProductSupplierDto => ({
    address: "",
    cityDistrict: "",
    id: "",
    name: "",
    partNumber: "",
  });

  const initProductSupplierRow = (): ProductSupplierRow => ({
    ...initProductSupplierDto(),
    key: new Date().valueOf(),
    isSearchSupplier: false,
    loadingSupplier: false,
    supplier: undefined,
    supplierFirstName: "",
    supplierOptions: [],
  });

  const initUomConversionDto = (): ProductUomConversionDto => ({
    id: "",
    uomSource: "",
    uomSourceId: "",
    uomConversion: "",
    uomConversionId: "",
    conversionRate: 0,
    cogs: 0,
  });

  const initProductAccountDto = (): ProductAccountDto => ({
    salesTaxId: "",
    purchaseTaxId: "",
    costOfSalesAccountId: "",
    inventoryAccountId: "",
    expensePurchaseAccountId: "",
    purchaseReturnAccountId: "",
    purchaseDiscountAccountId: "",
    unbilledAccountId: "",
    salesAccountId: "",
    salesReturnAccountId: "",
    salesDiscountAccountId: "",
  });

  const initProductCreateRequestDto = (): ProductCreateRequestDto => ({
    active: false,
    assetAccumulationAccountId: "",
    assetClearingAccountId: "",
    assetCostAccountId: "",
    assetDepreciationAccountId: "",
    barcode: "",
    baseUnit: "",
    code: "",
    description: "",
    image: "",
    merk: "",
    minimumStock: 0,
    multiPrices: [],
    name: "",
    productAccount: initProductAccountDto(),
    productCategoryId: "",
    productSuppliers: [],
    trackAsAsset: false,
    trackAsInventory: false,
    trackAsPurchase: false,
    trackAsSell: false,
    type: "" as ProductTypeEnum,
    uomConversionDTOs: [],
    locationReceiveId: "",
    serviceCode: "000000"
  });

  const initProductUpdateRequestDto = (): ProductUpdateRequestDto => ({
    productCategoryId: "",
    type: "" as ProductTypeEnum,
    name: "",
    merk: "",
    baseUnit: "",
    code: "",
    barcode: "",
    minimumStock: 0,
    image: "",
    trackAsInventory: false,
    trackAsAsset: false,
    trackAsPurchase: false,
    trackAsSell: false,
    active: false,
    description: "",
    multiPrices: [],
    deleteUomConversions: [],
    uomConversionDTOS: [],
    productSuppliers: [],
    assetCostAccountId: "",
    assetClearingAccountId: "",
    assetDepreciationAccountId: "",
    assetAccumulationAccountId: "",
    productAccount: initProductAccountDto(),
    locationReceiveId: "",
    isLuxuryGoods: false,
    serviceCode: "000000"
  });

  const initUomConversionRow = (): UomConversionRow => ({
    ...initUomConversionDto(),
    key: new Date().valueOf(),
  });

  const mapProductAccountSelectToProductAccountDto = (
    form: ProductAccountSelectType
  ): ProductAccountDto => {
    const req = initProductAccountDto();
    req.salesTaxId = form.salesTax?.key ?? "";
    req.purchaseTaxId = form.purchaseTax?.key ?? "";
    req.costOfSalesAccountId = form.costOfSalesAccount?.key ?? "";
    req.inventoryAccountId = form.inventoryAccount?.key ?? "";
    req.expensePurchaseAccountId = form.expensePurchaseAccount?.key ?? "";
    req.purchaseReturnAccountId = form.purchaseReturnAccount?.key ?? "";
    req.purchaseDiscountAccountId = form.purchaseDiscountAccount?.key ?? "";
    req.unbilledAccountId = form.unbilledAccount?.key ?? "";
    req.salesAccountId = form.salesAccount?.key ?? "";
    req.salesReturnAccountId = form.salesReturnAccount?.key ?? "";
    req.salesDiscountAccountId = form.salesDiscountAccount?.key ?? "";
    return req;
  };

  const mapFormToCreateDto = (form: FormValue): ProductCreateRequestDto => {
    const req: ProductCreateRequestDto = initProductCreateRequestDto();
    req.locationReceiveId = form.locationReceive?.key ?? "";
    req.active = form.active;
    req.assetAccumulationAccountId =
      form.productAccount.assetAccumulationAccount?.key ?? "";
    req.assetClearingAccountId =
      form.productAccount.assetClearingAccount?.key ?? "";
    req.assetCostAccountId = form.productAccount.assetCostAccount?.key ?? "";
    req.assetDepreciationAccountId =
      form.productAccount.assetDepreciationAccount?.key ?? "";
    req.barcode = form.barcode;
    req.baseUnit = form.baseUnit;
    req.code = form.code;
    req.description = form.description;
    req.image = form.image;
    req.merk = form.merk;
    req.minimumStock = form.minimumStock;
    req.name = form.name;
    req.productAccount = mapProductAccountSelectToProductAccountDto(
      form.productAccount
    );
    req.productCategoryId = form.productCategoryId;
    req.productSuppliers = form.productSuppliers.map<ProductSupplierDto>(
      item => ({
        id: item.supplier?.key ?? "",
        name: item.supplierFirstName,
        address: item.address,
        cityDistrict: item.cityDistrict,
        partNumber: item.partNumber,
      })
    );
    req.trackAsAsset = form.trackAsAsset;
    req.trackAsInventory = form.trackAsInventory;
    req.trackAsPurchase = form.trackAsPurchase;
    req.trackAsSell = form.trackAsSell;
    req.type = form.type;
    req.uomConversionDTOs = form.uomConversions.map<ProductUomConversionDto>(
      item => ({
        id: item.id,
        uomSource: item.uomSource,
        uomSourceId: item.uomSourceId,
        uomConversion: item.uomConversion,
        uomConversionId: item.uomConversionId,
        conversionRate: item.conversionRate,
        cogs: item.cogs,
      })
    );
    req.serviceCode = form.serviceCode;

    return req;
  };

  const mapFormToUpdateDto = (form: FormValue): ProductUpdateRequestDto => {
    const req: ProductUpdateRequestDto = initProductUpdateRequestDto();
    req.locationReceiveId = form.locationReceive?.key ?? "";
    req.productCategoryId = form.productCategoryId;
    req.type = form.type;
    req.name = form.name;
    req.merk = form.merk;
    req.baseUnit = form.baseUnit;
    req.code = form.code;
    req.barcode = form.barcode;
    req.minimumStock = form.minimumStock;
    req.image = form.image;
    req.trackAsInventory = form.trackAsInventory;
    req.trackAsAsset = form.trackAsAsset;
    req.trackAsPurchase = form.trackAsPurchase;
    req.trackAsSell = form.trackAsSell;
    req.active = form.active;
    req.description = form.description;
    req.deleteUomConversions = form.deleteUomConversions || [];
    req.uomConversionDTOS = form.uomConversions.map<ProductUomConversionDto>(
      item => ({
        id: item.id,
        uomSource: item.uomSource,
        uomSourceId: item.uomSourceId,
        uomConversion: item.uomConversion,
        uomConversionId: item.uomConversionId,
        conversionRate: item.conversionRate,
        cogs: item.cogs,
      })
    );
    req.productSuppliers = form.productSuppliers.map<ProductSupplierDto>(
      item => ({
        id: item.supplier?.key ?? "",
        name: item.supplierFirstName,
        address: item.address,
        cityDistrict: item.cityDistrict,
        partNumber: item.partNumber,
      })
    );
    req.assetCostAccountId = form.productAccount.assetCostAccount?.key ?? "";
    req.assetClearingAccountId =
      form.productAccount.assetClearingAccount?.key ?? "";
    req.assetDepreciationAccountId =
      form.productAccount.assetDepreciationAccount?.key ?? "";
    req.assetAccumulationAccountId =
      form.productAccount.assetAccumulationAccount?.key ?? "";
    req.productAccount = mapProductAccountSelectToProductAccountDto(
      form.productAccount
    );
    req.serviceCode = form.serviceCode;
    return req;
  };

  const initProductDetailDto = (): ProductDetailDto => ({
    active: false,
    assetAccumulationAccountId: "",
    assetAccumulationAccountName: "",
    assetClearingAccountId: "",
    assetClearingAccountName: "",
    assetCostAccountId: "",
    assetCostAccountName: "",
    assetDepreciationAccountId: "",
    assetDepreciationAccountName: "",
    barcode: "",
    baseUnit: "",
    baseUnitId: "",
    categoryId: "",
    categoryName: "",
    code: "",
    cogs: 0,
    costOfSalesAccountId: "",
    costOfSalesAccountName: "",
    description: "",
    expensePurchaseAccountId: "",
    expensePurchaseAccountName: "",
    image: "",
    inventoryAccountId: "",
    inventoryAccountName: "",
    merk: "",
    name: "",
    purchaseDiscountAccountId: "",
    purchaseDiscountAccountName: "",
    purchaseReturnAccountId: "",
    purchaseReturnAccountName: "",
    purchaseTaxId: "",
    purchaseTaxName: "",
    salesAccountId: "",
    salesAccountName: "",
    salesDiscountAccountId: "",
    salesDiscountAccountName: "",
    salesReturnAccountId: "",
    salesReturnAccountName: "",
    salesTaxId: "",
    salesTaxName: "",
    status: false,
    trackAsAsset: false,
    trackAsInventory: false,
    trackAsPurchase: false,
    trackAsSell: false,
    type: "" as ProductTypeEnum,
    unbilledAccountId: "",
    unbilledAccountName: "",
    updatable: true,
    multiPrices: [],
    productSuppliers: [],
    uomConversions: [],
    minimumStock: 0,
    createdBy: "",
    createdDate: "",
    id: "",
    modifiedBy: "",
    modifiedDate: "",
    locationReceiveId: "",
    locationReceiveName: "",
    isLuxuryGoods: false,
    serviceCode: "000000"
  });

  const mapProductAccountDetailToProductAccountSelect = (
    detail: ProductDetailDto
  ): ProductAccountSelectType => {
    const form = initProductAccountSelect();
    form.salesTax = { key: detail.salesTaxId, label: detail.salesTaxName };
    form.purchaseTax = {
      key: detail.purchaseTaxId,
      label: detail.purchaseTaxName,
    };
    form.costOfSalesAccount = {
      key: detail.costOfSalesAccountId,
      label: detail.costOfSalesAccountName,
    };
    form.inventoryAccount = {
      key: detail.inventoryAccountId,
      label: detail.inventoryAccountName,
    };
    form.expensePurchaseAccount = {
      key: detail.expensePurchaseAccountId,
      label: detail.expensePurchaseAccountName,
    };
    form.purchaseReturnAccount = {
      key: detail.purchaseReturnAccountId,
      label: detail.purchaseReturnAccountName,
    };
    form.purchaseDiscountAccount = {
      key: detail.purchaseDiscountAccountId,
      label: detail.purchaseDiscountAccountName,
    };
    form.unbilledAccount = {
      key: detail.unbilledAccountId,
      label: detail.unbilledAccountName,
    };
    form.salesAccount = {
      key: detail.salesAccountId,
      label: detail.salesAccountName,
    };
    form.salesReturnAccount = {
      key: detail.salesReturnAccountId,
      label: detail.salesReturnAccountName,
    };
    form.salesDiscountAccount = {
      key: detail.salesDiscountAccountId,
      label: detail.salesDiscountAccountName,
    };
    form.assetAccumulationAccount = {
      key: detail.assetAccumulationAccountId,
      label: detail.assetAccumulationAccountName,
    };
    form.assetClearingAccount = {
      key: detail.assetClearingAccountId,
      label: detail.assetClearingAccountName,
    };
    form.assetCostAccount = {
      key: detail.assetCostAccountId,
      label: detail.assetCostAccountName,
    };
    form.assetDepreciationAccount = {
      key: detail.assetDepreciationAccountId,
      label: detail.assetDepreciationAccountName,
    };
    return form;
  };

  const mapProductDetailToForm = (detail: ProductDetailDto): FormValue => {
    const form = initFormProduct();
    if (detail.locationReceiveId) {
      form.locationReceive = {
        key: detail.locationReceiveId,
        label: detail.locationReceiveName,
      };
    }
    form.active = detail.active;
    form.barcode = detail.barcode ?? "";
    form.baseUnit = detail.baseUnit ?? "";
    form.code = detail.code ?? "";
    form.description = detail.description ?? "";
    form.image = detail.image ?? "";
    form.merk = detail.merk ?? "";
    form.minimumStock = detail.minimumStock ?? 0;
    form.name = detail.name ?? "";
    form.productAccount = mapProductAccountDetailToProductAccountSelect(detail);
    form.productCategoryId = detail.categoryId ?? "";
    form.productSuppliers =
      detail.productSuppliers?.map<ProductSupplierRow>((item, i) => {
        const row = initProductSupplierRow();
        row.key = i;
        if (item.id && item.name) {
          row.supplier = {
            label: `${item.name} (${item.id})`,
            key: item.id,
          };
        }
        row.supplierFirstName = item.name;
        row.address = item.address ?? "";
        row.cityDistrict = item.cityDistrict ?? "";
        row.partNumber = item.partNumber ?? "";
        return row;
      }) ?? [];
    form.trackAsAsset = detail.trackAsAsset ?? false;
    form.trackAsInventory = detail.trackAsInventory ?? false;
    form.trackAsPurchase = detail.trackAsPurchase ?? false;
    form.trackAsSell = detail.trackAsSell ?? false;
    form.type = detail.type ?? "";
    form.categoryName = detail.categoryName ?? "";
    form.uomConversions =
      detail.uomConversions?.map<UomConversionRow>((item, i) => {
        const row = initUomConversionRow();
        row.key = i;
        row.id = item.id ?? "";
        row.uomSource = item.uomSource ?? "";
        row.uomSourceId = item.uomSourceId ?? "";
        row.uomConversion = item.uomConversion ?? "";
        row.uomConversionId = item.uomConversionId ?? "";
        row.conversionRate = item.conversionRate ?? 0;
        row.cogs = item.cogs ?? 0;
        return row;
      }) ?? [];
    form.serviceCode = detail.serviceCode ?? "";

    return form;
  };

  const isFormUomConvInValid = (data: Array<UomConversionRow>): boolean => {
    const isEmpty: boolean = data.length === 0;
    const isColEmpty = !!data.find(e => !e.uomSource || !e.uomConversion);

    // // merge uom source and uom conversion column
    const sourceAndConversion: Array<string> = data.map<string>(
      e => e.uomConversionId + e.uomSourceId
    );

    return isEmpty || isColEmpty || !isNotUnique(sourceAndConversion);
  };

  const toOptionsUomConv = (
    data: Array<ProductUomConversionDto>
  ): Array<Option<ProductUomConversionDto>> => {
    return data.map(item => ({
      key: item.id,
      label: item.uomSource,
      value: item.uomSourceId,
      meta: item,
    }));
  };

  return {
    buildDownloadParam,
    create,
    download,
    findAll,
    findAllProductInStock,
    findAllProductType,
    findAllRemastered,
    findAllSnForSales,
    findById,
    findUomConvByProduct,
    initProductAccountDto,
    initProductCreateRequestDto,
    initProductDetailDto,
    initProductSupplierDto,
    initProductSupplierRow,
    initProductUpdateRequestDto,
    initUomConversionDto,
    initUomConversionRow,
    isFormUomConvInValid,
    mapFormToCreateDto,
    mapFormToUpdateDto,
    mapProductDetailToForm,
    searchBy,
    searchByRemastered,
    toOptions,
    update,
    uploadImage,
    toOptionsCode,
    toOptionsName,
    toOptionsUomConv,
    mapProductAccountSelectToProductAccountDto,
    mapProductAccountDetailToProductAccountSelect,
    searchProductInStock,
  };
};

export default useProduct;
