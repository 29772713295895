






















































































































import quantityFilter from "@/filters/quantity.filter";
import { toTitlecase } from "@/helpers/common";
import useBlob from "@/hooks/useBlob";
import { WAREHOUSE_STATE } from "@/models/enums/warehouse-transfer.enum";
import { DetailWarehouseTransfer as WarehouseTransferDetailDto } from "@/models/interface/warehouse.interface";
import { logisticServices } from "@/services/logistic.service";
import printJs from "print-js";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class DetailWarehouseTransfer extends Vue {
  toTitlecase = toTitlecase;
  loading = false;
  loadingPrint = false;

  formState: WarehouseTransferDetailDto = {
    createdDate: "",
    modifiedDate: "",
    documentNumber: "",
    state: WAREHOUSE_STATE.NEW,
    sourceLocation: "",
    sourceLocationId: "",
    destinationLocation: "",
    destinationLocationId: "",
    notes: "",
    date: "",
    receivedDate: "",
    attachment: "",
    transferLines: [],
    id: "",
  };

  columns = [
    {
      title: this.$t("lbl_part"),
      key: "part",
      customRender: (_, record) =>
        `${record.product.name} (${record.product.code})`,
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_total_qty"),
      dataIndex: "qty",
      align: "right",
      customRender: text => quantityFilter(text),
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "uom.name",
    },
    {
      title: this.$t("lbl_qty_received"),
      dataIndex: "receivedQty",
      align: "right",
      customRender: text => quantityFilter(text),
    },
    {
      title: this.$t("lbl_qty_outstanding"),
      dataIndex: "outstandingQty",
      align: "right",
      customRender: text => quantityFilter(text),
    },
  ];

  get warehouseTransferId(): string {
    return this.$route.params.id as string;
  }

  get isReceived(): boolean {
    return this.formState.state === WAREHOUSE_STATE.RECEIVED;
  }

  get isSubmitted(): boolean {
    return this.formState.state === WAREHOUSE_STATE.SUBMITTED;
  }

  get allowPrint(): boolean {
    return this.isReceived || this.isSubmitted;
  }

  mounted() {
    this.loading = true;
    logisticServices
      .detailWarehouseTransfer(this.warehouseTransferId)
      .then(res => {
        this.formState = res;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  handleBack(): void {
    this.$router.push({ name: "logistic.warehouse-transfer" });
  }

  handlePrint(): void {
    const { toObjectUrl } = useBlob();
    this.loadingPrint = true;
    logisticServices
      .getDownloadWarehouseTransferForm(this.formState.id)
      .then(response => {
        printJs(toObjectUrl(response));
      })
      .finally(() => (this.loadingPrint = false));
  }
}
