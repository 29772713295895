import {
  buildCreateDto,
  buildLineCreateDto,
} from "@/builder/stock-adjustment/StockAdjustmentDtoBuilder";
import {
  initFormValue,
  initStockAdjRow,
} from "@/builder/stock-adjustment/StockAdjustmentStateBuilder";
import {
  FormValue,
  StockAdjRow,
  StockAdjustmentLineRequestCreateDTO,
  StockAdjustmentLineResponseDTO,
  StockAdjustmentRequestCreateDTO,
  StockAdjustmentResponseDTO,
} from "@/models/interface/stock-adjustment";
import moment from "moment";

export class StockAdjustmentMapper {
  static mapFormRowToCreateLineDto(
    row: StockAdjRow
  ): StockAdjustmentLineRequestCreateDTO {
    const line: StockAdjustmentLineRequestCreateDTO = buildLineCreateDto();

    line.physicalQty = row.physicalQty || 0;
    line.productId = row.productCode?.key || "";
    line.qty = row.qty || 0;
    line.id = row.id || "";
    line.uomId = row.uom?.key || "";
    line.serialNumber = row.serialNumber;

    return line;
  }

  static mapFormToCreateDto(form: FormValue): StockAdjustmentRequestCreateDTO {
    const req: StockAdjustmentRequestCreateDTO = buildCreateDto();

    req.attachment = form.attachment || "";
    req.deletedLineIds = form.deletedLineIds || [];
    req.notes = form.notes || "";
    req.stockAdjustmentLines =
      form.stockAdjustmentRows.map<StockAdjustmentLineRequestCreateDTO>(
        StockAdjustmentMapper.mapFormRowToCreateLineDto
      );
    req.type = form.type;
    req.warehouseLocationId = form.warehouseLocation?.key ?? "";

    return req;
  }

  static mapDetailLineToFormRow(
    line: StockAdjustmentLineResponseDTO
  ): StockAdjRow {
    const row: StockAdjRow = initStockAdjRow();

    row.differenceQty = line.differenceQty || 0;
    row.physicalQty = line.physicalQty || 0;
    row.productCode = {
      key: line.product.id,
      label: line.product.code,
    };
    row.productMerk = line.product?.merk || "";
    row.productName = {
      key: line.product.id,
      label: line.product.name,
    };
    row.qty = line.qty || 0;
    row.id = line.id;
    row.uom = line.uom ? { key: line.uom.id, label: line.uom.name } : undefined;
    row.serialNumber = line.serialNumber;
    row.type = line.qty > 0 ? "increment" : "decrement";

    return row;
  }

  static mapDetailToForm(detail: StockAdjustmentResponseDTO): FormValue {
    const form: FormValue = initFormValue();

    form.attachment = detail.attachment;
    form.brand = ""; // tidak di provide
    form.date = detail.date ? moment(detail.date) : null;
    form.deletedLineIds = [];
    form.documentNumber = detail.documentNumber;
    form.notes = detail.notes;
    form.productCategoryId = ""; // tidak di provide
    form.state = detail.state;
    form.stockAdjustmentRows = detail.stockAdjustmentLines.map<StockAdjRow>(
      StockAdjustmentMapper.mapDetailLineToFormRow
    );
    form.type = detail.type;
    form.warehouseLocation = {
      key: detail.warehouseLocationId,
      label: detail.warehouseLocation,
    };

    return form;
  }
}
