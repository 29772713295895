var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Select", {
    attrs: {
      value: _vm.value,
      "allow-clear": "",
      "show-search": "",
      "filter-option": false,
      loading: _vm.loading,
      disabled: _vm.disabled,
      options: _vm.options,
      "label-in-value": true,
      "option-label-prop": "label"
    },
    on: {
      search: _vm.onSearch,
      change: _vm.onChange,
      scrollEnd: _vm.onScrollEnd
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }