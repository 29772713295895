var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("small", [
    _vm._v(
      _vm._s(
        _vm.$tc("lbl_characters-x", _vm.valueLength, { data: _vm.valueLength })
      )
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }