import { useTaxRate } from "@/hooks/tax";
import { Mutations } from "@/models/constant/enums/mutations.enum";
import store from "@/store";
import { NavigationGuard } from "vue-router";

export const arInvoiceCreateLoader: NavigationGuard = async (
  _to,
  _from,
  next
) => {
  try {
    store.commit(Mutations.SET_LOADING_COMPONENT, true);
    const salesInclusiveTaxRate = store.getters[
      "preferenceStore/GET_PREFERENCE_BY_KEY"
    ]("feature_sales_inclusive_tax_rate");
    if (salesInclusiveTaxRate && salesInclusiveTaxRate.value) {
      const response = await useTaxRate(salesInclusiveTaxRate.value);
      store.commit("preferenceStore/setSalesInclusiveTaxRate", response);
    }
    next();
  } finally {
    store.commit(Mutations.SET_LOADING_COMPONENT, false);
  }
};
