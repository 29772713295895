





























































































































































































































































































































































































































































































































































import {
  buildAmortizationFormBasePeriodState,
  buildAmortizationFormState,
  buildAmortizationFormUnitPeriodState,
  buildAmortizationFormUnitState,
} from "@/builder/amortization/AmortizationStateBuilder";
import SearchBuilder from "@/builder/SearchBuilder";
import SelectAmortizationTransactionType from "@/components/custom/select/SelectAmortizationTransactionType.vue";
import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCompanyBank from "@/components/custom/select/SelectCompanyBank.vue";
import SelectCurrency from "@/components/custom/select/SelectCurrency.vue";
import SelectUnitCode from "@/components/custom/select/SelectUnitCode.vue";
import SelectLeasing from "@/components/Leasing/SelectLeasing.vue";
import dateFormat from "@/filters/date.filter";
import { debounceProcess } from "@/helpers/debounce";
import { generateUUID } from "@/helpers/uuid";
import { useMaxOneYear, useMinByDate } from "@/hooks/datepicker";
import useCoa from "@/hooks/useCoa";
import useCurrency from "@/hooks/useCurrency";
import usePreferences from "@/hooks/usePreferences";
import { AmortizationMapper } from "@/mapper/Amortization.mapper";
import MNotification from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DATE_FORMAT_MMM_YYYY,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { CurrencyCodeEnum } from "@/models/enums/global.enum";
import {
  AmortizationDetailResponseDto,
  AmortizationFormBasePeriodState,
  AmortizationFormState,
  AmortizationFormUnitPeriodState,
  AmortizationFormUnitState,
  AmortizationRequestDto,
} from "@/models/interface/amortization";
import { ListLeasingHeaderDto } from "@/models/interface/leasing";
import { AssetResponseDto } from "@/models/interface/master-asset";
import { amortizationService } from "@/services/amortization.service";
import { StorageSAuthtModel } from "@/store/auth.store";
import { LabelInValue } from "@/types";
import { FormUtils } from "@/utils/FormUtils";
import { StringUtils } from "@/utils/StringUtils";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { FormModel } from "ant-design-vue";
import Decimal from "decimal.js-light";
import moment, { Moment } from "moment";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "AmortizationForm",
  mixins: [MNotification],
  components: {
    SelectAmortizationTransactionType,
    SelectBranch,
    SelectLeasing,
    SelectCurrency,
    SelectCompanyBank,
    SelectUnitCode,
  },
  props: {
    id: {
      required: false,
      type: String,
    },
  },
  data() {
    this.onSearchPrepaidAccount = debounceProcess(this.onSearchPrepaidAccount);
    this.onSearchExpenseAccount = debounceProcess(this.onSearchExpenseAccount);
    return {
      DEFAULT_DATE_FORMAT,
      DATE_FORMAT_MMM_YYYY,
      formState: {
        ...buildAmortizationFormState(),
        units: [
          {
            ...buildAmortizationFormUnitState(),
            periods: [buildAmortizationFormUnitPeriodState()],
          },
        ],
      } as AmortizationFormState,
      prepaidAccountOptions: [] as Option[],
      expenseAccountOptions: [] as Option[],
      currentPageUnit: 1,
      formRef: null as FormModel | null,
      dtDetail: new AmortizationDetailResponseDto(),
      numberOfCopies: 1,
      isDoneCopy: false,
      toggleTimeout: null as number | null,
      basePeriods: [
        buildAmortizationFormBasePeriodState(),
      ] as AmortizationFormBasePeriodState[],
      loading: {
        prepaidAccount: false,
        expenseAccount: false,
        draft: false,
        create: false,
        detail: false,
        update: false,
      },
      unitColumn: [
        {
          title: this.$t("lbl_unit_code"),
          key: "unitCode",
          width: 250,
          scopedSlots: { customRender: "unitCode" },
        },
        {
          title: this.$t("lbl_serial_number"),
          dataIndex: "serialNumber",
          customRender: text => text ?? "-",
          width: 150,
        },
        {
          title: this.$t("lbl_acquisition_date"),
          dataIndex: "acquisitionDate",
          customRender: text => dateFormat(text),
          width: 150,
        },
        {
          title: this.$t("lbl_brand"),
          dataIndex: "brand",
          customRender: text => text?.toUpperCase() ?? "-",
          width: 150,
        },
        {
          title: this.$t("lbl_equipment"),
          dataIndex: "equipment",
          customRender: text => text?.toUpperCase() ?? "-",
          width: 150,
        },
        {
          title: this.$t("lbl_type"),
          dataIndex: "type",
          customRender: text => text ?? "-",
          width: 150,
        },
        {
          title: this.$t("lbl_note"),
          dataIndex: "note",
          scopedSlots: { customRender: "note" },
          width: 250,
        },
      ],
      basePeriodColumn: [
        {
          title: this.$t("lbl_year"),
          dataIndex: "year",
          width: 70,
        },
        {
          title: this.$t("lbl_start_period"),
          dataIndex: "startPeriod",
          scopedSlots: { customRender: "startPeriod" },
        },
        {
          title: this.$t("lbl_end_period"),
          dataIndex: "endPeriod",
          scopedSlots: { customRender: "endPeriod" },
        },
        {
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      unitPeriodColumn: [
        {
          title: this.$t("lbl_year"),
          dataIndex: "year",
          width: 70,
        },
        {
          title: this.$t("lbl_start_period"),
          dataIndex: "startPeriod",
          customRender: text => dateFormat(text, DATE_FORMAT_MMM_YYYY),
        },
        {
          title: this.$t("lbl_end_period"),
          dataIndex: "endPeriod",
          customRender: text => dateFormat(text, DATE_FORMAT_MMM_YYYY),
        },
        {
          title: this.$t("lbl_amount"),
          dataIndex: "amount",
          scopedSlots: { customRender: "amount" },
        },
        {
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      authStore: (st: any) => st.authStore as StorageSAuthtModel,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    disabledField(): boolean {
      if (!this.formState.status) return false;

      return !StringUtils.compare(this.formState.status, "Draft");
    },
    disabledLeasing(): boolean {
      const notDraft: boolean = this.disabledField as boolean;
      return notDraft && this.dtDetail.leasingId !== null;
    },
    allowCreate(): boolean {
      return (
        !this.formState.status ||
        StringUtils.compare(this.formState.status, "Draft")
      );
    },
    allowSaveDraft(): boolean {
      return !this.formState.status;
    },
    allowUpdate(): boolean {
      return !!this.formState.status && !!this.id;
    },
    title() {
      return this.id
        ? this.$t("common.edit-text", { text: this.$t("lbl_amortization") })
        : this.$t("common.create-text", { text: this.$t("lbl_amortization") });
    },
    isCreate() {
      return !this.id;
    },
    isDraft() {
      if (!this.formState.status) return false;

      return StringUtils.compare(this.formState.status, "Draft");
    },
    isIdr() {
      return this.formState.currency?.label === CurrencyCodeEnum.IDR;
    },
    validationSchema() {
      return {
        branch: FormUtils.mandatory(this.$t("lbl_validation_required_error")),
        currency: FormUtils.mandatory(this.$t("lbl_validation_required_error")),
        description: FormUtils.mandatory(
          this.$t("lbl_validation_required_error")
        ),
        documentNumber: FormUtils.mandatory(
          this.$t("lbl_validation_required_error")
        ),
        expenseAccount: FormUtils.mandatory(
          this.$t("lbl_validation_required_error")
        ),
        period: FormUtils.mandatory(this.$t("lbl_validation_required_error")),
        prepaidAccount: FormUtils.mandatory(
          this.$t("lbl_validation_required_error")
        ),
        startPeriod: FormUtils.mandatory(
          this.$t("lbl_validation_required_error")
        ),
        transactionDate: FormUtils.mandatory(
          this.$t("lbl_validation_required_error")
        ),
        transactionType: FormUtils.mandatory(
          this.$t("lbl_validation_required_error")
        ),
        companyBank: {
          required: this.formState.useCashOut,
          message: () => this.$t("lbl_validation_required_error"),
        },
      };
    },
  },
  created(): void {
    if (this.isCreate) {
      this.setDefaultBranch();
      this.setDefaultCurrency();
    } else {
      this.getDetail(this.id);
    }
    this.getPrepaidAccountList({
      currency: this.formState.currency?.label,
    });
    this.getExpenseAccountList({
      currency: this.formState.currency?.label,
    });
  },
  mounted(): void {
    if (this.$refs.formRef) {
      this.formRef = this.$refs.formRef as FormModel;
    }
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    dateFormat,
    getDetail(amortizationId: string): void {
      this.loading.detail = true;
      amortizationService
        .getAmortizationDetail(amortizationId)
        .then(response => {
          this.formState = AmortizationMapper.toAmortizationFormState(response);
          this.basePeriods = this.formState.units[0].periods.map(period => {
            return {
              key: generateUUID(),
              startPeriod: period.startPeriod,
              endPeriod: period.endPeriod,
              year: period.year,
            };
          });
          this.dtDetail = response;
        })
        .finally(() => {
          this.loading.detail = false;
        });
    },
    setDefaultCurrency(): void {
      const { findBaseCurrency } = usePreferences();
      const preference = findBaseCurrency();
      if (!preference || !preference.value) return;
      this.formState.currency = {
        label: preference.name,
        key: preference.value,
      };
    },
    setDefaultBranch(): void {
      if (
        !this.authStore.authData.branchList ||
        this.authStore.authData.branchList?.length > 1
      ) {
        return;
      }
      const [branch] = this.authStore.authData.branchList;
      this.formState.branch = {
        key: branch.branchId,
        label: branch.branchName,
      };
    },
    onChangeLeasing(val?: Option<ListLeasingHeaderDto>): void {
      this.formState.leasingNumber = undefined;
      this.formState.leasingContractNumber = undefined;
      this.formState.lessor = undefined;
      if (!val || !val.meta) return;

      this.formState.leasingNumber = {
        label: val.meta.leasingNumber,
        key: val.meta.id,
      };
      this.formState.leasingContractNumber = {
        label: val.meta.leasingContractNumber,
        key: val.meta.id,
      };
      this.formState.lessor = {
        label: val.meta.lessorName,
        key: val.meta.lessorId,
      };
    },
    onChangeCurrency(val?: LabelInValue): void {
      const { findBaseCurrency } = usePreferences();
      const { findConversion } = useCurrency();
      const baseCurrency = findBaseCurrency();
      if (!val || !baseCurrency || !baseCurrency.value) {
        this.formState.currencyRate = 1;
      } else {
        findConversion(baseCurrency.name, val.label).then(({ data }) => {
          const [currency] = data;
          this.formState.currencyRate = currency?.rate ?? 1;
        });
        this.getPrepaidAccountList({ currency: val.label });
        this.getExpenseAccountList({ currency: val.label });
      }
      this.formState.prepaidAccount = undefined;
      this.formState.expenseAccount = undefined;
    },
    async getPrepaidAccountOptions(
      arg: { currency?: string; code?: string } = {}
    ) {
      const { currency, code } = arg;
      const { findAllChildAccount, toOptions } = useCoa();
      const PREPAID_ACCOUNT_PARENT_CODE = "1106";
      const queries: string[] = [];
      const params = new RequestQueryParams();
      params.defaultParentCode = PREPAID_ACCOUNT_PARENT_CODE;
      params.sorts = "code:asc";

      if (code) {
        const searchByCode = new SearchBuilder()
          .push(["code", code], {
            like: "both",
          })
          .or()
          .push(["description", code], { like: "both" })
          .build();
        queries.push(searchByCode);
      }

      if (currency) {
        const searchByCurrency = new SearchBuilder()
          .push(["currency.currencyCode", currency])
          .build();
        queries.push(searchByCurrency);
      }
      params.search = queries.join(SearchBuilder.AND);
      const response = await findAllChildAccount(params);
      return toOptions(response.data);
    },
    getExpenseAccountList(
      arg: {
        code?: string;
        currency?: string;
      } = {}
    ): void {
      const { findAllChildAccount, toOptions } = useCoa();
      const { code, currency } = arg;
      const PREFIX_EXPENSE_ACCOUNT_5 = "5";
      const PREFIX_EXPENSE_ACCOUNT_6 = "6";
      const EXPENSE_ACCOUNT_PREFIXES = [
        PREFIX_EXPENSE_ACCOUNT_5,
        PREFIX_EXPENSE_ACCOUNT_6,
      ];
      const queries: string[] = [];

      if (code) {
        const searchByCode = new SearchBuilder()
          .push(["code", code], {
            like: "both",
          })
          .or()
          .push(["description", code], { like: "both" })
          .build();
        queries.push(searchByCode);
      }

      if (currency) {
        queries.push(
          new SearchBuilder().push(["currency.currencyCode", currency]).build()
        );
      }

      const params = new RequestQueryParams();
      params.search = queries.join(SearchBuilder.AND);
      params.sorts = "code:asc";
      params.defaultCode = EXPENSE_ACCOUNT_PREFIXES.join(",");

      this.loading.expenseAccount = true;
      findAllChildAccount(params)
        .then(response => {
          this.expenseAccountOptions = toOptions(response.data);
        })
        .finally(() => {
          this.loading.expenseAccount = false;
        });
    },
    getPrepaidAccountList(
      arg: {
        code?: string;
        currency?: string;
      } = {}
    ): void {
      const { code, currency } = arg;
      this.loading.prepaidAccount = true;
      this.getPrepaidAccountOptions({
        currency,
        code,
      })
        .then(response => {
          this.prepaidAccountOptions = response;
        })
        .finally(() => {
          this.loading.prepaidAccount = false;
        });
    },
    onSearchPrepaidAccount(val?: string): void {
      if (!val) {
        this.getPrepaidAccountList({
          currency: this.formState.currency?.label,
        });
        return;
      }

      this.getPrepaidAccountList({
        code: val,
        currency: this.formState.currency?.label,
      });
    },
    onSearchExpenseAccount(val?: string): void {
      if (!val) {
        this.getExpenseAccountList({
          currency: this.formState.currency?.label,
        });
        return;
      }

      this.getExpenseAccountList({
        code: val,
        currency: this.formState.currency?.label,
      });
    },
    handleAddUnit(): void {
      const unit = buildAmortizationFormUnitState();
      unit.periods = this.basePeriods.map(base => {
        const period = buildAmortizationFormUnitPeriodState();
        period.startPeriod = moment(base.startPeriod).startOf("days");
        period.endPeriod = moment(base.endPeriod).endOf("days");
        period.year = base.year;
        return period;
      });
      this.formState.units.push(unit);
      this.currentPageUnit = this.formState.units.length;
    },
    handleDeleteUnit(): void {
      const index = this.currentPageUnit - 1;
      const [deletedUnit] = this.formState.units.splice(index, 1);
      if (deletedUnit.id) {
        this.formState.deletedUnitIds.push(deletedUnit.id);
      }

      this.currentPageUnit = index === 0 ? 1 : this.currentPageUnit - 1;
      this.countGrandTotal();
    },
    onChangeAmount(): void {
      this.setUnitSubTotal();
      this.countGrandTotal();
    },
    setUnitSubTotal(): void {
      const periods: AmortizationFormUnitPeriodState[] =
        this.formState.units[this.currentPageUnit - 1].periods;
      const subTotal: number = periods.reduce(
        (left, right) => new Decimal(left).plus(right.amount || 0).toNumber(),
        0
      );
      this.formState.units[this.currentPageUnit - 1].subTotal = subTotal;
    },
    countGrandTotal(): void {
      const units: AmortizationFormUnitState[] = this.formState.units;
      this.formState.grandTotal = units.reduce(
        (left, right) => new Decimal(left).plus(right.subTotal).toNumber(),
        0
      );
    },
    handleBack(): void {
      this.$router.push({ name: "amortizations" });
    },
    onChangeUnit(
      record: AmortizationFormUnitState,
      val?: Option<AssetResponseDto>
    ): void {
      record.serialNumber = undefined;
      record.acquisitionDate = undefined;
      record.brand = undefined;
      record.type = undefined;
      record.equipment = undefined;

      if (!val || !val.meta) return;

      record.serialNumber = val.meta.serialNumber;

      const [equipment, brand] = val.meta.assetCategory.id
        ? val.meta.assetCategory.id.split(".")
        : [];
      record.brand = brand;
      record.acquisitionDate = val.meta.acquisitionDate;
      record.type = val.meta.type;
      record.equipment = equipment;
    },
    async handleSubmit(type: "draft" | "create" | "update"): Promise<void> {
      try {
        await this.formRef?.validate();
        if (type === "create") {
          this.handleCreate(this.formState);
        } else if (type === "draft") {
          this.handleCreateDraft(this.formState);
        } else if (type === "update") {
          this.handleUpdate(this.id, this.formState);
        }
      } catch (error) {
        this.showNotifWarning("notif_validation_error");
      }
    },
    handleUpdate(id: string, state: AmortizationFormState): void {
      const dto: AmortizationRequestDto =
        AmortizationMapper.toAmortizationRequestDto(state);
      this.loading.update = true;
      amortizationService
        .updateAmortization(id, dto)
        .then(({ amortizationNumber }) => {
          this.showNotifSuccess("notif_update_success", {
            documentNumber: amortizationNumber,
          });
          this.handleBack();
        })
        .finally(() => {
          this.loading.update = false;
        });
    },
    handleCreate(state: AmortizationFormState): void {
      const dto: AmortizationRequestDto =
        AmortizationMapper.toAmortizationRequestDto(state);
      this.loading.create = true;
      amortizationService
        .submitAmortization(dto)
        .then(({ amortizationNumber }) => {
          this.showNotifSuccess("notif_create_success", {
            documentNumber: amortizationNumber,
          });
          this.handleBack();
        })
        .finally(() => {
          this.loading.create = false;
        });
    },
    handleCreateDraft(state: AmortizationFormState): void {
      const dto: AmortizationRequestDto =
        AmortizationMapper.toAmortizationRequestDto(state);
      this.loading.draft = true;
      amortizationService
        .createAmortization(dto)
        .then(() => {
          this.showNotifSuccess("notif_document_created_as_draft_success", {
            documentNumber: "",
          });
          this.handleBack();
        })
        .finally(() => {
          this.loading.draft = false;
        });
    },
    handleCopy(): void {
      for (let i = 0; i < this.numberOfCopies; i++) {
        const copy: AmortizationFormUnitState = {
          ...this.formState.units[this.currentPageUnit - 1],
          unitCode: undefined,
          serialNumber: undefined,
          acquisitionDate: undefined,
          brand: undefined,
          type: undefined,
          equipment: undefined,
          key: generateUUID(),
          id: null,
          periods: this.formState.units[this.currentPageUnit - 1].periods.map(
            item => ({
              ...item,
              key: generateUUID(),
            })
          ),
        };
        this.formState.units.push(copy);
      }
      this.toggleDoneCopy();
      this.countGrandTotal();
    },
    toggleDoneCopy(): void {
      this.isDoneCopy = true;

      if (this.toggleTimeout) {
        clearTimeout(this.toggleTimeout);
      }

      this.toggleTimeout = setTimeout(() => {
        this.isDoneCopy = false;
      }, 1500);
    },
    handleResetUnit(): void {
      this.showConfirmation(
        this.$t("lbl_modal_delete_info", {
          count: this.formState.units.length,
        }),
        () => {
          const deletedUnits = this.formState.units.splice(
            0,
            this.formState.units.length
          );
          deletedUnits.forEach(item => {
            if (item.id) {
              this.formState.deletedUnitIds.push(item.id);
            }
          });
          this.currentPageUnit = 1;
          this.countGrandTotal();
        }
      );
    },
    handleAddPeriod(): void {
      const period: AmortizationFormBasePeriodState =
        buildAmortizationFormBasePeriodState();
      const lastRow = this.basePeriods.length - 1;
      if (this.basePeriods.length > 0) {
        period.year = this.basePeriods[lastRow].year + 1;

        if (this.basePeriods[lastRow].endPeriod !== null) {
          const lastPeriod = moment(this.basePeriods[lastRow].endPeriod);
          period.startPeriod = lastPeriod.add(1, "months").startOf("days");
          period.endPeriod = moment(period.startPeriod)
            .add(11, "months")
            .endOf("days");
        }
      }
      this.basePeriods.push(period);
      this.formState.units.forEach(item => {
        const periodState = buildAmortizationFormUnitPeriodState();
        periodState.startPeriod = period.startPeriod
          ? moment(period.startPeriod.startOf("days"))
          : null;
        periodState.endPeriod = period.endPeriod
          ? moment(period.endPeriod.endOf("days"))
          : null;
        periodState.year = period.year;
        item.periods.push(periodState);
      });
    },
    handleDeletePeriod(period: AmortizationFormBasePeriodState): void {
      const index = this.basePeriods.findIndex(item => item.key === period.key);
      if (index === -1) return;
      this.basePeriods.splice(index, 1);
      this.formState.units.forEach(unit => {
        const deleted = unit.periods.splice(index, 1);
        if (deleted[0].id) {
          this.formState.deletedPeriodIds.push(deleted[0].id);
        }
      });
      this.updatePeriodYears();
      this.countAllSubtotal();
      this.countGrandTotal();
    },
    updatePeriodYears(): void {
      this.formState.units.forEach(unit => {
        unit.periods.forEach((period, i) => {
          period.year = i + 1;
        });
      });
      this.basePeriods.forEach((period, i) => {
        period.year = i + 1;
      });
    },
    countAllSubtotal(): void {
      this.formState.units.forEach(unit => {
        unit.subTotal = unit.periods.reduce((left, right) => {
          return new Decimal(left).plus(right.amount || 0).toNumber();
        }, 0);
      });
    },
    onChangeBaseDatePeriod(
      state: AmortizationFormBasePeriodState,
      key: "startPeriod" | "endPeriod",
      val?: Moment
    ): void {
      const index = state.year - 1;
      if (key === "startPeriod") {
        this.basePeriods.forEach((period, i) => {
          period.endPeriod = null;
          if (i !== 0) period.startPeriod = null;
        });
        return;
      }

      for (let i = index + 1; i < this.basePeriods.length; i++) {
        if (i > index + 1) {
          const lastPeriod = this.basePeriods[i - 1].endPeriod;
          this.basePeriods[i].startPeriod = moment(lastPeriod)
            .add(1, "months")
            .startOf("days");
        } else {
          this.basePeriods[i].startPeriod = moment(val)
            .add(1, "months")
            .startOf("days");
        }

        this.basePeriods[i].endPeriod = moment(this.basePeriods[i].startPeriod)
          .add(11, "months")
          .endOf("days");
      }

      this.basePeriods.forEach((base, i) => {
        this.formState.units.forEach(unit => {
          unit.periods[i].startPeriod = moment(base.startPeriod).startOf(
            "days"
          );
          unit.periods[i].endPeriod = moment(base.endPeriod).endOf("days");
        });
      });
    },
    endPeriodRules(
      curr: Moment,
      state: AmortizationFormBasePeriodState
    ): boolean {
      const max = moment(state.startPeriod).add(11, "months").endOf("days");
      return useMaxOneYear(curr, max) || useMinByDate(curr, state.startPeriod);
    },
  },
});
