import {
  buildAmortizationPeriodRequestDto,
  buildAmortizationRequestDto,
  buildAmortizationUnitRequestDto,
} from "@/builder/amortization/AmortizationDtoBuilder";
import {
  buildAmortizationFormState,
  buildAmortizationFormUnitPeriodState,
  buildAmortizationFormUnitState,
} from "@/builder/amortization/AmortizationStateBuilder";
import {
  AmortizationDetailPeriodResponseDto,
  AmortizationDetailResponseDto,
  AmortizationDetailUnitResponseDto,
  AmortizationFormState,
  AmortizationFormUnitPeriodState,
  AmortizationFormUnitState,
  AmortizationPeriodRequestDto,
  AmortizationRequestDto,
  AmortizationUnitRequestDto,
} from "@/models/interface/amortization";
import { DownloadRequestParam } from "@/models/interface/download";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import store from "@/store";
import moment from "moment";

const isAmortizationDetailDto = (
  arg: any
): arg is AmortizationDetailResponseDto => {
  return typeof arg === "object" && "journals" in arg;
};
const isAmortizationFormState = (arg: any): arg is AmortizationFormState => {
  return typeof arg === "object" && "useCashOut" in arg;
};

export class AmortizationMapper {
  static mapDownloadParams(
    param: RequestQueryParamsModel,
    {
      amortizationNumber,
      branch,
      documentNumber,
      endDate,
      leasingContractNumber,
      leasingNumber,
      lessorName,
      startDate,
      status,
      transactionType,
    }: {
      branch?: string;
      lessorName?: string;
      transactionType?: string;
      amortizationNumber?: string;
      status?: string;
      documentNumber?: string;
      leasingNumber?: string;
      leasingContractNumber?: string;
      startDate?: string;
      endDate?: string;
    }
  ): DownloadRequestParam {
    const DEFAULT = "All";

    const company = store.state["authStore"].authData.companyName;
    const filters: string[] = [
      company || DEFAULT,
      branch || DEFAULT,
      lessorName || DEFAULT,
      transactionType || DEFAULT,
      amortizationNumber || DEFAULT,
      status || DEFAULT,
      documentNumber || DEFAULT,
      leasingNumber || DEFAULT,
      leasingContractNumber || DEFAULT,
      startDate || DEFAULT,
      endDate || DEFAULT,
    ];

    return {
      ...param,
      params: filters.join(","),
    };
  }

  static toAmortizationPeriodRequestDto(
    state: AmortizationFormUnitPeriodState
  ): AmortizationPeriodRequestDto {
    const dto = buildAmortizationPeriodRequestDto();
    dto.amount = state.amount;
    dto.endPeriod = state.endPeriod?.format() ?? "";
    dto.startPeriod = state.startPeriod?.format() ?? "";
    dto.amortizationPeriodId = state.id;
    return dto;
  }

  static toAmortizationUnitRequestDto(
    state: AmortizationFormUnitState
  ): AmortizationUnitRequestDto {
    const dto = buildAmortizationUnitRequestDto();
    dto.amortizationUnitId = state.id;
    dto.assetId = state.unitCode?.key ?? null;
    dto.note = state.note || null;
    dto.periodLineDTOs = state.periods.map(
      AmortizationMapper.toAmortizationPeriodRequestDto
    );
    return dto;
  }

  static toAmortizationRequestDto(
    state: AmortizationFormState
  ): AmortizationRequestDto;
  static toAmortizationRequestDto(
    state: AmortizationDetailResponseDto
  ): AmortizationRequestDto;

  static toAmortizationRequestDto(state: unknown): AmortizationRequestDto {
    if (isAmortizationFormState(state)) {
      const dto = buildAmortizationRequestDto();
      const countPeriod = state.units[0].periods.length;
      const startPeriod = state.units[0].periods[0].startPeriod;
      const endPeriod = state.units[0].periods[countPeriod - 1].endPeriod;
      dto.deletedUnitIds = state.deletedUnitIds;
      dto.deletedPeriodIds = state.deletedPeriodIds;
      dto.amortizationId = state.amortizationId;
      dto.lessorId = state.lessor?.key || null;
      dto.transactionType = state.transactionType ?? "";
      dto.periodLength = countPeriod;
      dto.branchId = state.branch?.key ?? "";
      dto.documentNumber = state.documentNumber;
      dto.startPeriod = startPeriod?.format() ?? "";
      dto.endPeriod = endPeriod?.format() ?? "";
      dto.transactionDate = state.transactionDate?.format() ?? "";
      dto.description = state.description;
      dto.leasingId = state.leasingNumber?.key || null;
      dto.companyBankId = state.companyBank?.key || null;
      dto.prepaidAccountId = state.prepaidAccount?.key ?? "";
      dto.expenseAccountId = state.expenseAccount?.key ?? "";
      dto.currencyId = state.currency?.key ?? "";
      dto.currencyRate = state.currencyRate || 1;
      dto.unitLineDTOs = state.units.map(
        AmortizationMapper.toAmortizationUnitRequestDto
      );
      return dto;
    } else if (isAmortizationDetailDto(state)) {
      const dto = buildAmortizationRequestDto();
      dto.amortizationId = state.amortizationId;
      dto.lessorId = state.lessorId;
      dto.transactionType = state.transactionType;
      dto.periodLength = state.periodLength;
      dto.branchId = state.branchId;
      dto.documentNumber = state.documentNumber;
      dto.startPeriod = state.startPeriod;
      dto.endPeriod = state.endPeriod;
      dto.transactionDate = state.transactionDate;
      dto.description = state.description;
      dto.leasingId = state.leasingId;
      dto.companyBankId = state.bankId;
      dto.prepaidAccountId = state.prepaidAccountId;
      dto.expenseAccountId = state.expenseAccountId;
      dto.currencyId = state.currencyId;
      dto.currencyRate = state.currencyRate || 1;
      dto.unitLineDTOs = state.units.map(unit => ({
        amortizationUnitId: unit.unitId,
        assetId: unit.assetId,
        note: unit.note,
        periodLineDTOs: unit.periods.map(period => ({
          amortizationPeriodId: period.periodId,
          amount: period.amount,
          endPeriod: period.endPeriod,
          startPeriod: period.startPeriod,
          year: period.year,
        })),
      }));
      return dto;
    }

    return buildAmortizationRequestDto();
  }

  static toAmortizationFormState(
    dto: AmortizationDetailResponseDto
  ): AmortizationFormState {
    const state: AmortizationFormState = buildAmortizationFormState();
    state.branch = {
      key: dto.branchId,
      label: dto.branchName,
    };
    if (dto.bankId && dto.bankName) {
      state.companyBank = {
        label: dto.bankName,
        key: dto.bankId,
      };
    }

    state.currency = {
      label: dto.currencyName,
      key: dto.currencyId,
    };

    state.currencyRate = dto.currencyRate;
    state.description = dto.description;
    state.documentNumber = dto.documentNumber;
    state.expenseAccount = {
      label: `${dto.expenseAccountCode} - ${dto.expenseAccountDescription}`,
      key: dto.expenseAccountId,
    };
    state.grandTotal = dto.totalAmortizationAmount;

    if (dto.leasingId && dto.leasingContractNumber && dto.leasingNumber) {
      state.leasingContractNumber = {
        label: dto.leasingContractNumber,
        key: dto.leasingId,
      };
      state.leasingNumber = {
        label: dto.leasingNumber,
        key: dto.leasingId,
      };
    }

    if (dto.lessorId && dto.lessorName) {
      state.lessor = {
        label: dto.lessorName,
        key: dto.lessorId,
      };
    }

    state.prepaidAccount = {
      label: `${dto.prepaidAccountCode} - ${dto.prepaidAccountDescription}`,
      key: dto.prepaidAccountId,
    };

    state.transactionDate = moment(dto.transactionDate);
    state.transactionType = dto.transactionType;
    state.units = dto.units.map(AmortizationMapper.toAmortizationFormUnitState);
    state.useCashOut = !!dto.bankId;
    state.amortizationId = dto.amortizationId;
    state.status = dto.status;
    return state;
  }

  static toAmortizationFormUnitState(
    dto: AmortizationDetailUnitResponseDto
  ): AmortizationFormUnitState {
    const state: AmortizationFormUnitState = buildAmortizationFormUnitState();

    state.equipment = dto.equipment;
    state.brand = dto.brand;
    state.note = dto.note;
    state.periods = dto.periods.map((period, index) => {
      const periodState =
        AmortizationMapper.toAmortizationFormUnitPeriodState(period);
      periodState.year = index + 1;
      return periodState;
    });
    state.serialNumber = dto.serialNumber;
    state.acquisitionDate = dto.acquisitionDate;
    state.subTotal = dto.unitAmortizationAmount;
    state.type = dto.type;
    if (dto.unitCode && dto.assetId) {
      state.unitCode = {
        key: dto.assetId,
        label: dto.unitCode,
      };
    }
    state.id = dto.unitId;

    return state;
  }

  static toAmortizationFormUnitPeriodState(
    dto: AmortizationDetailPeriodResponseDto
  ): AmortizationFormUnitPeriodState {
    const state: AmortizationFormUnitPeriodState =
      buildAmortizationFormUnitPeriodState();
    state.amount = dto.amount;
    state.endPeriod = moment(dto.endPeriod);
    state.startPeriod = moment(dto.startPeriod);
    state.id = dto.periodId;

    return state;
  }
}
