





















































































































































































import { SearchBuilder } from "@/builder";
import SelectAmortization from "@/components/custom/select/SelectAmortization.vue";
import SelectAmortizationStatus from "@/components/custom/select/SelectAmortizationStatus.vue";
import SelectAmortizationTransactionType from "@/components/custom/select/SelectAmortizationTransactionType.vue";
import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectLessor from "@/components/custom/select/SelectLessor.vue";
import SelectLeasing from "@/components/Leasing/SelectLeasing.vue";
import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import { APagination, useBlob, useDate } from "@/hooks";
import { AmortizationMapper } from "@/mapper/Amortization.mapper";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  DATE_FORMAT_MMM_YYYY,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import {
  AmortizationResponseDto,
  ListAmortizationFilter,
} from "@/models/interface/amortization";
import { amortizationService } from "@/services/amortization.service";
import { SorterProps } from "@/types";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    SelectBranch,
    SelectLessor,
    SelectLeasing,
    SelectAmortizationTransactionType,
    SelectAmortization,
    SelectAmortizationStatus,
  },
})
export default class Amortizations extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  filters: ListAmortizationFilter = this.initDefaultFilters();

  columns = [
    {
      title: this.$t("lbl_amortization_number"),
      dataIndex: "amortizationNumber",
      key: "amortizationNumber",
      width: 190,
      customRender: text => text || "-",
      sorter: true,
    },
    {
      title: this.$t("lbl_period"),
      dataIndex: "period",
      key: "period",
      width: 250,
      customRender: (_, record) =>
        `${dateFormat(record.startPeriod, DATE_FORMAT_MMM_YYYY)} - ${dateFormat(
          record.endPeriod,
          DATE_FORMAT_MMM_YYYY
        )}`,
      sorter: true,
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branch",
      key: "branch",
      width: 150,
      customRender: text => text || "-",
      sorter: true,
    },
    {
      title: this.$t("lbl_lessor_name"),
      dataIndex: "lessor",
      key: "lessor",
      customRender: text => text || "-",
      sorter: true,
    },
    {
      title: this.$t("lbl_transaction_type"),
      dataIndex: "transactionType",
      key: "transactionType",
      customRender: text => text || "-",
      sorter: true,
    },
    {
      title: this.$t("lbl_document_number"),
      dataIndex: "documentNumber",
      key: "documentNumber",
      customRender: text => text || "-",
      sorter: true,
    },
    {
      title: this.$t("lbl_leasing_number"),
      dataIndex: "leasingNumber",
      key: "leasingNumber",
      customRender: text => text || "-",
      sorter: true,
    },
    {
      title: this.$t("lbl_leasing_contract_number"),
      dataIndex: "leasingContractNumber",
      key: "leasingContractNumber",
      customRender: text => text || "-",
      sorter: true,
    },
    {
      title: this.$t("lbl_cash_out_number"),
      dataIndex: "cashOutNumber",
      key: "cashOutNumber",
      scopedSlots: { customRender: "cashOutNumber" },
      sorter: true,
    },
    {
      title: this.$t("lbl_total_amount"),
      dataIndex: "totalAmortizationAmount",
      key: "totalAmortizationAmount",
      customRender: amount => currencyFilter(amount),
      sorter: true,
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      width: 100,
      customRender: text => text || "-",
      sorter: true,
    },
    {
      title: this.$t("lbl_action"),
      dataIndex: "action",
      key: "action",
      width: 100,
      scopedSlots: { customRender: "action" },
    },
  ];

  loading = {
    find: false,
    download: false,
  };

  pagination = {
    page: +this.$route?.query.p || DEFAULT_PAGE,
    limit: +this.$route?.query.l || DEFAULT_PAGE_SIZE,
    sorts: "createdDate:desc",
    reset(): void {
      this.page = DEFAULT_PAGE;
      this.limit = DEFAULT_PAGE_SIZE;
    },
  };

  dataSource: Pagination<AmortizationResponseDto> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  created(): void {
    this.fetchAmortizations(this.buildQueryParams());
  }

  initDefaultFilters(): ListAmortizationFilter {
    return {
      amortization: undefined,
      branch: undefined,
      documentNumber: undefined,
      leasingContractNumber: undefined,
      leasingNumber: undefined,
      lessor: undefined,
      period: null,
      status: undefined,
      transactionType: undefined,
    };
  }

  fetchAmortizations(
    params: RequestQueryParams = new RequestQueryParams()
  ): void {
    this.loading.find = true;
    amortizationService
      .getAmortizations(params)
      .then(res => {
        this.dataSource = res;
      })
      .finally(() => (this.loading.find = false));
  }

  buildQueryParams(): RequestQueryParams {
    const { toStartDay, toEndDay, toDefaultFormat } = useDate();

    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    const queries: string[] = [];

    if (this.filters.branch?.label) {
      queries.push(
        builder.push(["branch.name", this.filters.branch.label]).build()
      );
    }

    if (this.filters.lessor?.key) {
      queries.push(
        builder.push(["lessor.secureId", this.filters.lessor.key]).build()
      );
    }

    if (this.filters.transactionType) {
      queries.push(
        builder.push(["transactionType", this.filters.transactionType]).build()
      );
    }

    if (this.filters.amortization?.label) {
      queries.push(
        builder
          .push(["amortizationNumber", this.filters.amortization.label])
          .build()
      );
    }

    if (this.filters.status) {
      queries.push(builder.push(["status", this.filters.status]).build());
    }

    if (this.filters.documentNumber) {
      queries.push(
        builder
          .push(["documentNumber", this.filters.documentNumber], {
            like: "both",
          })
          .build()
      );
    }

    if (this.filters.leasingNumber?.label) {
      queries.push(
        builder
          .push(["leasing.leasingNumber", this.filters.leasingNumber.label])
          .build()
      );
    }

    if (this.filters.leasingContractNumber?.label) {
      queries.push(
        builder
          .push([
            "leasing.leasingContractNumber",
            this.filters.leasingContractNumber.label,
          ])
          .build()
      );
    }

    if (this.filters.period && this.filters.period.length === 2) {
      const [start, end] = this.filters.period;
      const startDate = toStartDay(start);
      const endDate = toEndDay(end);

      if (startDate) {
        queries.push(
          builder
            .push(["startPeriod", startDate.format()], { het: true })
            .build()
        );
      }

      if (endDate) {
        queries.push(
          builder.push(["startPeriod", endDate.format()], { let: true }).build()
        );
      }
    }

    params.limit = this.pagination.limit;
    params.page = this.pagination.page - ONE;
    params.sorts = this.pagination.sorts;
    params.search = queries.join(builder.AND);

    return params;
  }

  getColumnKey(key: string): string {
    if (key === "period") {
      return "startPeriod";
    } else if (key === "leasingNumber") {
      return "leasing.leasingNumber";
    } else if (key === "leasingContractNumber") {
      return "leasing.leasingContractNumber";
    } else if (key === "cashOutNumber") {
      return "cashInOut.documentNo";
    }

    return key;
  }

  onChangeTable(pagination: APagination, _filter, sort: SorterProps): void {
    const { current, pageSize } = pagination;
    const { order, columnKey } = sort;
    const direction = order === "ascend" ? "asc" : "desc";

    const hasNextPage = pageSize === this.pagination.limit;
    this.pagination.page = hasNextPage ? current : DEFAULT_PAGE;
    this.pagination.limit = pageSize;

    const params = this.buildQueryParams();
    if (columnKey) {
      params.sorts = `${this.getColumnKey(columnKey)}:${direction}`;
      this.pagination.sorts = params.sorts;
    }

    this.fetchAmortizations(params);
  }

  handleResetFilter(): void {
    this.pagination.reset();
    this.filters = this.initDefaultFilters();

    this.fetchAmortizations(this.buildQueryParams());
  }

  handleFind(): void {
    this.pagination.reset();
    this.fetchAmortizations(this.buildQueryParams());
  }

  handleDownload(): void {
    const { toStartDay, toEndDay, toDefaultFormat } = useDate();
    const { toDownload } = useBlob();

    let startDate: string | undefined;
    let endDate: string | undefined;
    if (this.filters.period && this.filters.period.length === 2) {
      const [start, end] = this.filters.period;
      startDate = toDefaultFormat(toStartDay(start));
      endDate = toDefaultFormat(toEndDay(end));
    }

    const params = this.buildQueryParams();
    const downloadParams = AmortizationMapper.mapDownloadParams(params, {
      startDate: startDate,
      endDate: endDate,
      amortizationNumber: this.filters.amortization?.label,
      branch: this.filters.branch?.label,
      documentNumber: this.filters.documentNumber,
      leasingContractNumber: this.filters.leasingContractNumber?.label,
      leasingNumber: this.filters.leasingNumber?.label,
      lessorName: this.filters.lessor?.label,
      status: this.filters.status,
      transactionType: this.filters.transactionType,
    });
    this.loading.download = true;
    amortizationService
      .downloadAmortizations(downloadParams)
      .then(response => {
        toDownload(response, "amortizations.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }
}
