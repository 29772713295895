import { useStockAdjustment } from "@/hooks/useStockAdjustment";
import { Mutations } from "@/models/constant/enums/mutations.enum";
import { StockAdjustmentResponseDTO } from "@/models/interface/stock-adjustment";
import store from "@/store";

export const stockAdjDetailLoader = async (to, _, next) => {
  const { findById } = useStockAdjustment();
  store.dispatch("stockAdjStore/resetStore");
  try {
    const res: StockAdjustmentResponseDTO = await findById(to.params.id);
    store.dispatch("stockAdjStore/mapDetailToForm", res);
    next();
  } finally {
    store.commit(Mutations.SET_LOADING_COMPONENT, false);
  }
};

export const stockAdjCreateLoader = (_, __, next) => {
  store.dispatch("stockAdjStore/resetStore");
  next();
};
