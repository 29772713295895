var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      attrs: {
        value: _vm.value,
        loading: _vm.loading,
        "allow-clear": "",
        "show-search": "",
        disabled: _vm.disabled,
        placeholder: _vm.placeholder,
        mode: _vm.mode
      },
      on: { change: _vm.emits }
    },
    [
      _vm._t(
        "options",
        function() {
          return _vm._l(_vm.options, function(option) {
            return _c(
              "a-select-option",
              { key: option.key, attrs: { value: option.value } },
              [
                _vm._t(
                  "option",
                  function() {
                    return [_vm._v(" " + _vm._s(option.label) + " ")]
                  },
                  { option: option }
                )
              ],
              2
            )
          })
        },
        { options: _vm.options }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }