var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c("legend", [_vm._v(_vm._s(_vm.$t("lbl_payment")))]),
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: {
            model: _vm.store.form,
            rules: _vm.formRules,
            "wrapper-col": { span: 6 },
            "label-col": { span: 4 },
            "label-align": "left"
          }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_amount"), prop: "amount" } },
            [
              _c("a-input-number", {
                staticClass: "w-100",
                attrs: {
                  parser: _vm.reverseFormatNumber,
                  formatter: _vm.formatterNumber,
                  precision: _vm.storeBaseDecimalPlace
                },
                on: { change: _vm.onChangeAmount },
                model: {
                  value: _vm.store.form.amount,
                  callback: function($$v) {
                    _vm.$set(_vm.store.form, "amount", _vm._n($$v))
                  },
                  expression: "store.form.amount"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_tax_code"), prop: "taxCode" } },
            [
              _c("SelectTaxCode", {
                on: { "update:meta": _vm.onChangeTaxCode },
                model: {
                  value: _vm.store.form.taxCode,
                  callback: function($$v) {
                    _vm.$set(_vm.store.form, "taxCode", $$v)
                  },
                  expression: "store.form.taxCode"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_tax_type"), prop: "taxType" } },
            [
              _c("SelectTaxCalculation", {
                attrs: { "exclude-none": "" },
                on: { change: _vm.onChangeTaxCalc },
                model: {
                  value: _vm.store.form.taxType,
                  callback: function($$v) {
                    _vm.$set(_vm.store.form, "taxType", $$v)
                  },
                  expression: "store.form.taxType"
                }
              })
            ],
            1
          ),
          _vm.isInclusiveTax
            ? _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_inclusive_tax_rate"),
                    prop: "inclusiveTax"
                  }
                },
                [
                  _c("SelectTaxCode", {
                    attrs: { "label-in-value": "" },
                    on: { "update:meta": _vm.onChangeInclusiveTaxRate },
                    model: {
                      value: _vm.store.form.inclusiveTax,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "inclusiveTax", $$v)
                      },
                      expression: "store.form.inclusiveTax"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }