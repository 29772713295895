var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_maintenance_report") } },
    [
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: {
            layout: "vertical",
            model: _vm.formState,
            "wrapper-col": { span: 14 },
            "label-col": { span: 10 },
            "label-align": "left"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_branch"), prop: "branch" } },
                    [
                      _c("SelectBranch", {
                        attrs: { "label-in-value": "" },
                        model: {
                          value: _vm.formState.branch,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "branch", $$v)
                          },
                          expression: "formState.branch"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_customer"), prop: "customer" }
                    },
                    [
                      _c("SelectCustomer", {
                        attrs: { "label-in-value": "" },
                        model: {
                          value: _vm.formState.customer,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "customer", $$v)
                          },
                          expression: "formState.customer"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_po_date"), prop: "poDate" } },
                    [
                      _c("a-range-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          placeholder: [
                            _vm.$t("lbl_start_date"),
                            _vm.$t("lbl_end_date")
                          ]
                        },
                        model: {
                          value: _vm.formState.poDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "poDate", $$v)
                          },
                          expression: "formState.poDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_unit_code"),
                        prop: "unitCode"
                      }
                    },
                    [
                      _c("SelectUnitCode", {
                        attrs: { "label-in-value": "" },
                        model: {
                          value: _vm.formState.unitCode,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "unitCode", $$v)
                          },
                          expression: "formState.unitCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_rwo_date"), prop: "rwoDate" }
                    },
                    [
                      _c("a-range-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          placeholder: [
                            _vm.$t("lbl_start_date"),
                            _vm.$t("lbl_end_date")
                          ]
                        },
                        model: {
                          value: _vm.formState.rwoDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "rwoDate", $$v)
                          },
                          expression: "formState.rwoDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_asset_category"),
                        prop: "assetCategory"
                      }
                    },
                    [
                      _c("SelectAssetCategory", {
                        attrs: { "label-in-value": "" },
                        model: {
                          value: _vm.formState.assetCategory,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "assetCategory", $$v)
                          },
                          expression: "formState.assetCategory"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { loading: _vm.loading.find },
                          on: { click: _vm.handleReset }
                        },
                        [_vm._v(_vm._s(_vm.$t("lbl_reset")))]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", loading: _vm.loading.find },
                          on: { click: _vm.handleFind }
                        },
                        [_vm._v(_vm._s(_vm.$t("lbl_find")))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-table",
                    {
                      attrs: {
                        "data-source": _vm.dataSource.data,
                        columns: _vm.columns,
                        size: "small",
                        scroll: { x: 1000 },
                        "row-class-name": function(_record, index) {
                          return index % 2 ? "bg-white" : "bg-gray-light"
                        },
                        loading: _vm.loading.find
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "expandedRowRender",
                          fn: function(record) {
                            return [
                              _c(
                                "a-divider",
                                { attrs: { orientation: "left" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "lbl_result_customer_maintenance"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c("a-table", {
                                attrs: {
                                  "data-source": record.rwoDto,
                                  columns: _vm.rwoColumn,
                                  pagination: {
                                    showTotal: function(total) {
                                      return _vm.$t("lbl_total_items", {
                                        total: total
                                      })
                                    },
                                    showSizeChanger: true
                                  },
                                  "row-key": function(r) {
                                    return r.rwoLineId
                                  },
                                  size: "small",
                                  scroll: { x: 1000 }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "currency",
                                      fn: function(text) {
                                        return _c("span", {}, [
                                          _vm._v(
                                            _vm._s(_vm._f("currency")(text))
                                          )
                                        ])
                                      }
                                    },
                                    {
                                      key: "number",
                                      fn: function(text) {
                                        return _c("span", {}, [
                                          _vm._v(_vm._s(_vm._f("qty")(text)))
                                        ])
                                      }
                                    },
                                    {
                                      key: "date",
                                      fn: function(text) {
                                        return _c("span", {}, [
                                          _vm._v(_vm._s(_vm._f("date")(text)))
                                        ])
                                      }
                                    },
                                    {
                                      key: "nullable",
                                      fn: function(text) {
                                        return _c("span", {}, [
                                          _vm._v(_vm._s(text || "-"))
                                        ])
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c(
                                "a-divider",
                                { attrs: { orientation: "left" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("lbl_purchase_order")) +
                                      " "
                                  )
                                ]
                              ),
                              _c("a-table", {
                                attrs: {
                                  "data-source": record.poDto,
                                  columns: _vm.poColumn,
                                  pagination: {
                                    showTotal: function(total) {
                                      return _vm.$t("lbl_total_items", {
                                        total: total
                                      })
                                    },
                                    showSizeChanger: true
                                  },
                                  "row-key": function(r) {
                                    return r.poLineId
                                  },
                                  size: "small",
                                  scroll: { x: 1000 }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "currency",
                                      fn: function(text) {
                                        return _c("span", {}, [
                                          _vm._v(
                                            _vm._s(_vm._f("currency")(text))
                                          )
                                        ])
                                      }
                                    },
                                    {
                                      key: "number",
                                      fn: function(text) {
                                        return _c("span", {}, [
                                          _vm._v(_vm._s(_vm._f("qty")(text)))
                                        ])
                                      }
                                    },
                                    {
                                      key: "date",
                                      fn: function(text) {
                                        return _c("span", {}, [
                                          _vm._v(_vm._s(_vm._f("date")(text)))
                                        ])
                                      }
                                    },
                                    {
                                      key: "nullable",
                                      fn: function(text) {
                                        return _c("span", {}, [
                                          _vm._v(_vm._s(text || "-"))
                                        ])
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "a-descriptions",
                        {
                          attrs: { slot: "footer", size: "small", column: 4 },
                          slot: "footer"
                        },
                        [
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: _vm.$t("lbl_grand_total_rwo") } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.dataSource.grandTotalRwo
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: _vm.$t("lbl_grand_total_po") } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.dataSource.grandTotalPo
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading.download },
                      on: { click: _vm.handleDownload }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_download")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }