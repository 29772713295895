var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: {
        title: _vm.$t("lbl_detail_x", { x: _vm.$t("lbl_stock_adjustment") })
      }
    },
    [
      _c(
        "a-form-model",
        {
          attrs: {
            "wrapper-col": { span: 12 },
            "label-col": { span: 8 },
            "label-align": "left"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_document_number") } },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.documentNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "documentNumber", $$v)
                          },
                          expression: "form.documentNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_location"),
                        prop: "warehouseLocation"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          value: _vm.form.warehouseLocation
                            ? _vm.form.warehouseLocation.label
                            : "",
                          disabled: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_type"), prop: "type" } },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.type,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "type", $$v)
                          },
                          expression: "form.type"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_date"), prop: "date" } },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          disabled: ""
                        },
                        model: {
                          value: _vm.form.date,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "date", $$v)
                          },
                          expression: "form.date"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_status"),
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.state,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "state", $$v)
                          },
                          expression: "form.state"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_notes"),
                        "label-align": "right",
                        prop: "notes"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "extra",
                          fn: function() {
                            return [
                              _c("character-length", {
                                attrs: { value: _vm.form.notes }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("a-textarea", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.notes,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "notes", $$v)
                          },
                          expression: "form.notes"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_attachment"),
                        "label-align": "right",
                        prop: "attachment"
                      }
                    },
                    [
                      _vm.form.attachment
                        ? _c(
                            "a-button",
                            {
                              attrs: { type: "link", icon: "cloud-download" },
                              on: { click: _vm.showAttachment }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("lbl_view_attachment")) +
                                  " "
                              )
                            ]
                          )
                        : _c("span", { staticClass: "ant-form-text" }, [
                            _vm._v(" " + _vm._s("-") + " ")
                          ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          size: "small",
          pagination: {
            showSizeChanger: true,
            pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
            showTotal: function(total) {
              return _vm.$t("lbl_total_items", { total: total })
            }
          },
          "data-source": _vm.form.stockAdjustmentRows,
          columns: _vm.columns,
          scroll: { x: 900 },
          "row-class-name": function(_record, index) {
            return index % 2 ? "bg-white" : "bg-gray-light"
          }
        },
        scopedSlots: _vm._u([
          {
            key: "nullable",
            fn: function(text) {
              return _c("span", {}, [_vm._v(" " + _vm._s(text || "-") + " ")])
            }
          },
          {
            key: "number",
            fn: function(text) {
              return _c("span", {}, [
                _vm._v(" " + _vm._s(_vm._f("qty")(text)) + " ")
              ])
            }
          },
          {
            key: "differenceQty",
            fn: function(text, row) {
              return [
                _c("span", [_vm._v(_vm._s(_vm.useCountQtyDifference(row)))])
              ]
            }
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "text-right mt-2" },
        [
          _c("a-button", { on: { click: _vm.handleBack } }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }