import { DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import { APP_DECIMAL_PLACES } from "@/models/enums/global.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { InvoiceArProductState } from "@/models/interface/account-receivable";
import localStorageService from "@/services/LocalStorage.service";
import {
  changeCurrencytoNumeric,
  currencyFormat,
} from "@/validator/globalvalidator";
import Decimal from "decimal.js-light";

type FormValue = {
  taxCalculation: TAX_CALCULATION | null;
  additionalDiscount: {
    amount: number;
    percent: number;
  };
  isLuxury: boolean;
  taxBaseVariable: number;
  assignee: string;
};

export interface State {
  tabDetailSource: any[];
  form: FormValue;
  tabPrepayment: any[];
}

const state: State = {
  tabDetailSource: [],
  form: {
    isLuxury: false,
    taxBaseVariable: 1,
    taxCalculation: null,
    additionalDiscount: {
      amount: 0,
      percent: 0,
    },
    assignee: "",
  },
  tabPrepayment: [],
};

const actions = {
  ["CALC_PRICING_INVOICE_PRODUCT"]: ({ state, getters, rootState }): void => {
    let sumOfGrossValue = new Decimal(0); // alias Subtotal
    const discountHeader: number = state.form.additionalDiscount.amount;
    const isLuxury: boolean = state.form.isLuxury;
    const isInclusive: boolean = getters.TAX_CALCULATION_INCLUSIVE;
    const isExclusive: boolean = getters.TAX_CALCULATION_EXCLUSIVE;
    const totalAppliedPrepayment: Decimal = new Decimal(
      getters.CALC_SUM_PREPAYMENT.raw || 0
    );
    const salesInclusiveTaxRate: number =
      rootState.preferenceStore.salesInclusiveTaxRate ?? 0;
    const taxBaseVariable: number = state.form.taxBaseVariable;

    sumOfGrossValue = state.tabDetailSource.reduce(
      (left: number, right: InvoiceArProductState) => {
        const gross = new Decimal(right.price).times(right.qty);
        return new Decimal(left).plus(gross).toNumber();
      },
      0
    );

    state.tabDetailSource.forEach((product: InvoiceArProductState) => {
      const gross = new Decimal(product.price ?? 0).times(product.qty ?? 0);
      product.grossValue = gross.toNumber();
      product.proRateRatio = gross
        .dividedBy(sumOfGrossValue)
        .todp(14, Decimal.ROUND_FLOOR)
        .toNumber();
      product.proRateAdditionalDiscountAmount = new Decimal(
        product.proRateRatio
      )
        .times(discountHeader)
        .toNumber();
      const proRatePrepaymentAmount: Decimal = totalAppliedPrepayment.times(
        product.proRateRatio
      );

      const grossAfterDiscount = new Decimal(product.grossValue)
        .minus(product.discountValue)
        .minus(product.proRateAdditionalDiscountAmount)
        .minus(proRatePrepaymentAmount);
      product.grossAfterDiscount = grossAfterDiscount.toNumber();

      if (isExclusive) {
        const baseAmount = new Decimal(taxBaseVariable).times(
          grossAfterDiscount
        );
        product.baseAmount = baseAmount
          .todp(2, Decimal.ROUND_HALF_UP)
          .toNumber();
        const taxRate = new Decimal(product.taxRate ?? 0).dividedBy(100);
        product.taxValue = baseAmount
          .times(taxRate)
          .todp(2, Decimal.ROUND_HALF_UP)
          .toNumber();
        if (isLuxury) {
          product.subTotal = baseAmount
            .plus(product.taxValue)
            .todp(2, Decimal.ROUND_HALF_UP)
            .toNumber();
        } else {
          product.subTotal = new Decimal(product.grossAfterDiscount)
            .plus(product.taxValue)
            .todp(2, Decimal.ROUND_HALF_UP)
            .toNumber();
        }
      } else if (isInclusive) {
        const inclusiveRate: number = isLuxury
          ? product.taxRate ?? 0
          : salesInclusiveTaxRate;
        const inclusiveRateDivider = new Decimal(inclusiveRate)
          .dividedBy(100)
          .plus(1);
        const grossAfterDiscountExcludeTax = grossAfterDiscount
          .dividedBy(inclusiveRateDivider)
          .todp(2, Decimal.ROUND_HALF_UP);
        const baseAmount = new Decimal(taxBaseVariable).times(
          grossAfterDiscountExcludeTax
        );
        product.grossAfterDiscount = grossAfterDiscountExcludeTax.toNumber();
        product.baseAmount = baseAmount
          .todp(2, Decimal.ROUND_HALF_UP)
          .toNumber();
        product.taxValue = grossAfterDiscount
          .minus(grossAfterDiscountExcludeTax)
          .toNumber();
        product.subTotal = grossAfterDiscountExcludeTax
          .plus(product.taxValue)
          .toNumber();
      } else {
        product.taxValue = 0;
        product.baseAmount = grossAfterDiscount.toNumber();
        product.subTotal = product.baseAmount;
      }
    });
  },
  /**
   * @deprecated
   */
  ["CALC_PRICING_INVOICE_PRODUCT2"]: (context): void => {
    const dp =
      localStorageService.load(APP_DECIMAL_PLACES.DP) ??
      DECIMAL_PLACES_CURRENCY;
    let sumGrossAfterDiscount = new Decimal(0);
    let sumAmountAfterDiscountInvoice = new Decimal(0);
    let sumTotalAppliedPrepayment = new Decimal(0);
    context.state.tabDetailSource.forEach((x: InvoiceArProductState) => {
      x.qty = changeCurrencytoNumeric(x.qty) || 0;
      x.price = changeCurrencytoNumeric(x.price) || 0;

      // C = A * B
      let gross = new Decimal(x.qty || 0).times(x.price || 0);

      // find inclusive gross amount
      if (context.getters.TAX_CALCULATION_INCLUSIVE) {
        gross = gross
          .dividedBy(new Decimal(x.taxRate || 0).dividedBy(100).plus(1))
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
      }

      // gross after discount line
      // E = C - D
      const grossAfterDiscount = gross.minus(x.discountValue || 0);
      // later define on model
      x.grossAfterDiscount = grossAfterDiscount.toNumber();
    });

    // calc sum of gross after discount
    // formula = sum of gross after discount
    sumGrossAfterDiscount = context.state.tabDetailSource.reduce(
      (prev, curr) => {
        return new Decimal(curr.grossAfterDiscount || 0).plus(prev).toNumber();
      },
      0
    );

    context.state.tabDetailSource.forEach(x => {
      // continue the loop
      // now you have sum gross after discount
      const totalGrossProductAfterDiscount = new Decimal(
        sumGrossAfterDiscount || 0
      );

      /**
       * start ratio calculation from additional
       * discount invoice
       */
      let proRateRatio = new Decimal(0);

      proRateRatio = new Decimal(x.grossAfterDiscount || 0)
        .dividedBy(totalGrossProductAfterDiscount)
        .toDecimalPlaces(14, Decimal.ROUND_DOWN);

      /**
       * now you have pro rate each line from
       * additional discount invoice
       */
      x.proRateRatio = proRateRatio.toNumber();
      // end

      /**
       * get additional discount invoice amount
       */
      const additionalDiscountValue = new Decimal(
        context.state.form.additionalDiscount.amount || 0
      );

      /**
       * calc amount of pro rate additional discount
       * formula:
       *
       * pro rate ratio from sum of gross after
       * discount exclude service
       *
       * X
       *
       * additional discount invoice amount
       *
       */
      const proRateAdditionalDiscountAmount = additionalDiscountValue
        .times(x.proRateRatio)
        .toDecimalPlaces(14, Decimal.ROUND_DOWN);
      // now you have, pro rate amount from additional discount exclude service product type
      x.proRateAdditionalDiscountAmount =
        proRateAdditionalDiscountAmount.toNumber();

      /**
       * find amount after pro rate additional discount invoice
       * formula = gross after discount each line - pro rate amount
       * aditional discount each line
       */
      const amountAfterProRateAdditionalDiscount = new Decimal(
        x.grossAfterDiscount || 0
      ).minus(x.proRateAdditionalDiscountAmount || 0);
      /**
       * now you have amount after pro rate additional discount
       * on each line
       */
      x.amountAfterProRateAdditionalDiscount =
        amountAfterProRateAdditionalDiscount.toNumber();
    });

    /**
     * calc sum of amount after pro rate additional discount
     * EXCLUDE SERVICE PRODUCT TYPE
     */
    sumAmountAfterDiscountInvoice = context.state.tabDetailSource.reduce(
      (prev, curr) => {
        let amount = new Decimal(0);
        /**
         * exclude service product type
         */
        amount = new Decimal(curr.amountAfterProRateAdditionalDiscount || 0);
        return amount.plus(prev).toNumber();
      },
      0
    );
    // now you have sum of amount after pro rate discount invoice exclude service product type

    // get sum of total applied prepayment
    sumTotalAppliedPrepayment = new Decimal(
      context.getters.CALC_SUM_PREPAYMENT.raw || 0
    );

    context.state.tabDetailSource.forEach(x => {
      /**
       * calc pro rate prepayment
       * formula = amount after discount invoice on each line
       *
       * divided by
       *
       * sum of amount after discount invoice EXCLUDE service producty type
       */
      const prepaymentRatio = new Decimal(
        x.amountAfterProRateAdditionalDiscount || 0
      )
        .dividedBy(sumAmountAfterDiscountInvoice)
        .toDecimalPlaces(14, Decimal.ROUND_DOWN);
      x.proRateAmountAfterAdditionalDiscountRatio = prepaymentRatio
        .toDecimalPlaces(14, Decimal.ROUND_DOWN)
        .toNumber();
      // now you have pro rate ratio pre payment for each line, exclude service product type

      // calc prepayment amount line
      const prepaymentLineAmount = new Decimal(
        x.proRateAmountAfterAdditionalDiscountRatio
      ).times(sumTotalAppliedPrepayment);
      x.proRatePrepaymentAmount = prepaymentLineAmount.toNumber();
      // now you have amount of pro rate prepayment for each line exclude service product type

      let baseAmount = new Decimal(0);
      let taxAmount = new Decimal(0);
      let subTotal = new Decimal(0);

      /**
       * calc base amount
       * formula = amount after discount invoice on each line
       *
       * minus
       *
       * amount of pro rate prepayment
       */
      baseAmount = new Decimal(
        x.amountAfterProRateAdditionalDiscount || 0
      ).minus(x.proRatePrepaymentAmount);

      // tax calculation
      if (context.getters.TAX_CALCULATION_EXCLUSIVE) {
        taxAmount = baseAmount
          .times(x.taxRate || 0)
          .dividedBy(100)
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
        subTotal = baseAmount.plus(taxAmount || 0);
        x.baseAmount = currencyFormat(
          baseAmount.toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber()
        );
        x.taxValue = currencyFormat(
          taxAmount.toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber()
        );
        x.subTotal = currencyFormat(
          subTotal.toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber()
        );
      } else if (context.getters.TAX_CALCULATION_INCLUSIVE) {
        const grossValue = new Decimal(x.qty || 0).times(x.price || 0);
        const baseAmountInclusive = grossValue.dividedBy(
          new Decimal(x.taxRate || 0).dividedBy(100).plus(1)
        );

        const baseAmountAfterDiscountLine = baseAmountInclusive.minus(
          x.discountValue
        );

        // additional discount invoice amount
        const additionalDiscountValue = new Decimal(
          context.state.form.additionalDiscount.amount || 0
        );
        /**
         * find amount of aditional discount amount after pro rate
         * on each line
         * formula = pro rate additional discount X additional discount invoice
         */
        const discountInvoiceValue = new Decimal(x.proRateRatio).times(
          additionalDiscountValue
        );
        const prepaymentValue = new Decimal(
          x.proRateAmountAfterAdditionalDiscountRatio
        ).times(sumTotalAppliedPrepayment);

        baseAmount = baseAmountAfterDiscountLine
          .minus(prepaymentValue)
          .minus(discountInvoiceValue);

        taxAmount = baseAmount
          .times(x.taxRate)
          .dividedBy(100)
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
        subTotal = baseAmount.plus(taxAmount);
        x.baseAmount = currencyFormat(
          baseAmount.toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber()
        );
        x.taxValue = currencyFormat(
          taxAmount.toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber()
        );
        x.subTotal = currencyFormat(
          subTotal.toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber()
        );
      } else {
        subTotal = baseAmount;
        x.baseAmount = currencyFormat(
          baseAmount.toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber()
        );
        x.taxValue = currencyFormat(
          taxAmount.toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber()
        );
        x.subTotal = currencyFormat(
          subTotal.toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber()
        );
      }
    });
  },
  ["CALC_PRICING_RECURING"]: (context): void => {
    const dp =
      localStorageService.load(APP_DECIMAL_PLACES.DP) ??
      DECIMAL_PLACES_CURRENCY;
    context.state.tabDetailSource.forEach(x => {
      x.qty = changeCurrencytoNumeric(x.qty);
      x.price = changeCurrencytoNumeric(x.price);
      x.discountValue = changeCurrencytoNumeric(x.discountValue);

      // C = A * B
      let gross = new Decimal(x.qty || 0).times(x.price || 0);

      // find inclusive gross amount
      if (context.getters.TAX_CALCULATION_INCLUSIVE) {
        gross = gross
          .dividedBy(new Decimal(x.taxRate || 0).dividedBy(100).plus(1))
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
      }

      // gross after discount line
      // E = C - D
      const grossAfterDiscount = gross.minus(x.discountValue || 0);
      // later define on model
      x.grossAfterDiscount = grossAfterDiscount.toNumber();

      let baseAmount = new Decimal(0);
      let taxAmount = new Decimal(0);
      let subTotal = new Decimal(0);

      // tax calculation
      if (context.getters.TAX_CALCULATION_EXCLUSIVE) {
        baseAmount = new Decimal(x.grossAfterDiscount || 0);
        taxAmount = baseAmount
          .times(x.taxRate || 0)
          .dividedBy(100)
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
        subTotal = baseAmount.plus(taxAmount);
        x.baseAmount = currencyFormat(
          baseAmount.toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber()
        );
        x.taxValue = currencyFormat(
          taxAmount.toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber()
        );
        x.subTotal = currencyFormat(
          subTotal.toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber()
        );
      } else if (context.getters.TAX_CALCULATION_INCLUSIVE) {
        const grossValue = new Decimal(x.qty || 0).times(x.price || 0);
        const baseAmountInclusive = grossValue.dividedBy(
          new Decimal(x.taxRate || 0).dividedBy(100).plus(1)
        );

        const baseAmountAfterDiscountLine = baseAmountInclusive.minus(
          x.discountValue
        );

        taxAmount = baseAmountAfterDiscountLine
          .times(x.taxRate)
          .dividedBy(100)
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
        subTotal = baseAmountAfterDiscountLine.plus(taxAmount);
        x.baseAmount = currencyFormat(
          baseAmountAfterDiscountLine
            .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
            .toNumber()
        );
        x.taxValue = currencyFormat(
          taxAmount.toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber()
        );
        x.subTotal = currencyFormat(
          subTotal.toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber()
        );
      } else {
        baseAmount = new Decimal(x.grossAfterDiscount || 0);
        subTotal = baseAmount;
        x.baseAmount = currencyFormat(
          baseAmount.toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber()
        );
        x.taxValue = 0;
        x.subTotal = currencyFormat(
          subTotal.toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber()
        );
      }
    });
  },
  ["RESET_STORE"]: (context): void => {
    const form: FormValue = {
      taxCalculation: null,
      isLuxury: false,
      taxBaseVariable: 1,
      additionalDiscount: {
        amount: 0,
        percent: 0,
      },
      assignee: "",
    };
    context.commit("SET_FORM", form);
    context.commit("SET_DATA_SOURCE_DETAILS_TAB_PANE", []);
    context.commit("SET_PREPAYMENT", []);
  },
  disableTaxLines: (context): void => {
    const { state } = context;
    const local: State = state;
    local.tabDetailSource.forEach(item => {
      item.taxValue = currencyFormat(0);
      item.taxRate = 0;
      item.taxCode = "";

      item.disableByRow.push("taxCode");
    });
  },
  enableTaxLines: (context): void => {
    const { state } = context;
    const local: State = state;
    local.tabDetailSource.forEach(item => {
      const idx: number = item.disableByRow.indexOf("taxCode");
      if (idx !== -1) {
        item.disableByRow.splice(idx, 1);
      }
    });
  },
};

const getters = {
  ["GET_DATA_SOURCE_DETAILS_TAB_PANE"]: (st: State) => {
    return st.tabDetailSource;
  },
  ["TAX_CALCULATION_EXCLUSIVE"]: (st: State): boolean => {
    return (
      (st.form.taxCalculation || "").toUpperCase() ===
      TAX_CALCULATION.EXCLUSIVE.toUpperCase()
    );
  },
  ["TAX_CALCULATION_INCLUSIVE"]: (st: State): boolean => {
    return (
      (st.form.taxCalculation || "").toUpperCase() ===
      TAX_CALCULATION.INCLUSIVE.toUpperCase()
    );
  },
  ["TAX_CALCULATION_NONE"]: (st: State): boolean => {
    return (
      (st.form.taxCalculation || "").toUpperCase() ===
      TAX_CALCULATION.NONE.toUpperCase()
    );
  },
  ["CALC_SUM_PREPAYMENT"]: (st: State): { raw: number; format: string } => {
    const sum = st.tabPrepayment.reduce((curr, val) => {
      const amount = new Decimal(changeCurrencytoNumeric(val.amount) || 0);
      return amount.plus(curr).toNumber();
    }, 0);
    return {
      raw: sum,
      format: currencyFormat(sum),
    };
  },
  /** @deprecated */
  ["CALC_SUM_BASE_AMOUNT"]: (st): number => {
    return st.tabDetailSource.reduce((a, b) => {
      const baseAmount = changeCurrencytoNumeric(b.baseAmount) ?? 0;
      return new Decimal(baseAmount).plus(a).toNumber();
    }, 0);
  },
  ["CALC_SUM_SUBTOTAL"]: (st): number => {
    return st.tabDetailSource.reduce((a: number, b: InvoiceArProductState) => {
      const sumSubtotal = b.subTotal ?? 0;
      return new Decimal(sumSubtotal).plus(a).toNumber();
    }, 0);
  },
};

const mutations = {
  ["SET_DATA_SOURCE_DETAILS_TAB_PANE"]: (st: State, payload: any): void => {
    st.tabDetailSource = payload;
  },
  ["SET_FORM"]: (st: State, payload: FormValue): void => {
    st.form = payload;
  },
  ["SET_PREPAYMENT"]: (st: State, payload): void => {
    st.tabPrepayment = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
