

































































































































































































































































import SearchBuilder from "@/builder/SearchBuilder";
import SelectAmortizationTransactionType from "@/components/custom/select/SelectAmortizationTransactionType.vue";
import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import { setNumbering, toTitlecase } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import { APagination } from "@/hooks/table";
import useBlob from "@/hooks/useBlob";
import useDate from "@/hooks/useDate";
import MNotification from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import {
  DATE_FORMAT_MMM_YYYY,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import {
  AmortizationTransactionReportDto,
  AmortizationTransactionReportParamsDownload,
  AmortizationTransactionReportParamsList,
} from "@/models/interface/amortization-transaction-report";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { amortizationTransactionReportService } from "@/services/amortization-transaction-report.service";
import { amortizationService } from "@/services/amortization.service";
import { LabelInValue, SorterProps } from "@/types";
import { FormModel } from "ant-design-vue";
import { Moment } from "moment";
import { Component, Mixins, Ref } from "vue-property-decorator";

type FormState = {
  amortization: LabelInValue | undefined;
  transactionType: string[];
  transactionDate: Moment[];
  cashOutNumber: string | undefined;
  unitCode: string | undefined;
  serialNumber: string | undefined;
  documentNumber: string | undefined;
  description: string | undefined;
  status: string | undefined;
  leasingContractNumber: string | undefined;
};

type Record = AmortizationTransactionReportDto & {
  no: number;
};

@Component({
  components: {
    SelectAmortizationTransactionType,
  },
})
export default class AmortizationTransactionReport extends Mixins(
  MNotification
) {
  @Ref() readonly formModel!: FormModel;

  readonly DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  readonly PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  pagination = {
    page: 1,
    limit: 10,
    sorts: undefined as string | undefined,
  };

  loading = {
    download: false,
    submit: false,
    amortization: false,
  };

  form: FormState = {
    amortization: undefined,
    transactionType: [],
    transactionDate: [],
    cashOutNumber: undefined,
    unitCode: undefined,
    serialNumber: undefined,
    documentNumber: undefined,
    description: undefined,
    status: undefined,
    leasingContractNumber: undefined,
  };

  amortizationNumberOptions: Option[] = [];
  dtReport = {
    totalElements: 0,
    grandTotal: 0,
    data: [] as Record[],
  };

  detailColumns = [
    {
      title: this.$t("lbl_year"),
      dataIndex: "year",
      width: 90,
    },
    {
      title: this.$t("lbl_start_period"),
      dataIndex: "startPeriod",
      width: 150,
      customRender: text => dateFormat(text, DATE_FORMAT_MMM_YYYY),
    },
    {
      title: this.$t("lbl_end_period"),
      dataIndex: "endPeriod",
      width: 150,
      customRender: text => dateFormat(text, DATE_FORMAT_MMM_YYYY),
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "amount",
      align: "right",
      customRender: text => currencyFilter(text),
    },
  ];

  columns = [
    {
      title: "#",
      dataIndex: "no",
      width: 40,
    },
    {
      title: this.$t("lbl_transaction_type"),
      dataIndex: "transactionType",
      key: "transactionType",
      sorter: true,
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
      sorter: true,
      width: 170,
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      sorter: true,
    },
    {
      title: this.$t("lbl_acquisition_date"),
      dataIndex: "acquisitionDate",
      key: "acquisitionDate",
      width: 170,
      customRender: text => dateFormat(text),
      sorter: true,
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "brand",
      key: "brand",
      width: 150,
      sorter: true,
      customRender: text => (text ? text.toUpperCase() : "-"),
    },
    {
      title: this.$t("lbl_equipment"),
      dataIndex: "equipment",
      key: "equipment",
      width: 150,
      sorter: true,
      customRender: text => (text ? text.toUpperCase() : "-"),
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      key: "type",
      sorter: true,
      width: 150,
    },
    {
      title: this.$t("lbl_note"),
      dataIndex: "note",
      ellipsis: true,
      scopedSlots: { customRender: "ellipsis" },
    },
    {
      title: this.$t("lbl_lessor_name"),
      dataIndex: "lessorName",
      key: "lessorName",
      sorter: true,
      ellipsis: true,
      scopedSlots: { customRender: "ellipsis" },
    },
    {
      title: this.$t("lbl_document_number"),
      dataIndex: "documentNumber",
      key: "documentNumber",
      sorter: true,
    },
    {
      title: this.$t("lbl_amortization_number"),
      dataIndex: "amortizationNumber",
      key: "amortizationNumber",
      width: 190,
      sorter: true,
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      ellipsis: true,
      scopedSlots: { customRender: "ellipsis" },
    },
    {
      title: this.$t("lbl_total_amount"),
      dataIndex: "amount",
      key: "amount",
      sorter: true,
      align: "right",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_transaction_date"),
      dataIndex: "transactionDate",
      key: "transactionDate",
      width: 170,
      sorter: true,
      customRender: text => dateFormat(text),
    },
    {
      title: this.$t("lbl_leasing_contract_number"),
      dataIndex: "leasingContractNumber",
      key: "leasingContractNumber",
      sorter: true,
      width: 170,
      scopedSlots: { customRender: "ellipsis" },
    },
    {
      title: this.$t("lbl_cash_out_number"),
      dataIndex: "cashOutNumber",
      key: "cashOutNumber",
      sorter: true,
      width: 170,
      scopedSlots: { customRender: "cashOutNumber" },
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      width: 100,
      customRender: text => toTitlecase(text),
    },
  ];

  created(): void {
    this.onSearchAmortization = debounceProcess(this.onSearchAmortization);

    this.getAmortizationOptions();
  }

  getAmortizationOptions(): void {
    const params = new RequestQueryParams();
    params.sorts = "createdDate:desc";
    params.search = this.buildAmortizationSearch();
    this.getAmortizationNumberList(params);
  }

  handleReset(): void {
    this.formModel.resetFields();
  }

  async getAmortizationNumberList(
    params?: RequestQueryParamsModel
  ): Promise<void> {
    try {
      this.loading.amortization = true;
      const response = await amortizationService.getAmortizations(params);
      this.amortizationNumberOptions = response.data.map(amortization => {
        return {
          label: amortization.amortizationNumber,
          value: amortization.amortizationId,
          key: amortization.amortizationId,
        };
      });
    } catch {
      this.amortizationNumberOptions = [];
    } finally {
      this.loading.amortization = false;
    }
  }

  onSearchAmortization(val?: string): void {
    const params = new RequestQueryParams();
    params.sorts = "createdDate:desc";
    params.search = this.buildAmortizationSearch(val);
    this.getAmortizationNumberList(params);
  }

  buildAmortizationSearch(docNumber?: string): string {
    const builder = new SearchBuilder();
    const criteria: string = builder
      .push(["status", "OPEN"])
      .or()
      .push(["status", "PARTIAL_CLOSED"])
      .or()
      .push(["status", "FULLY_CLOSED"])
      .or()
      .push(["status", "ENDED"])
      .build();
    const queries: string[] = [criteria];
    if (docNumber) {
      queries.push(
        builder
          .push(["amortizationNumber", docNumber], { like: "both" })
          .build()
      );
    }
    return queries.join(SearchBuilder.AND);
  }

  async getTransactionReportData(
    params?: RequestQueryParamsModel
  ): Promise<void> {
    try {
      this.loading.submit = true;
      const response =
        await amortizationTransactionReportService.getAmortizationTransactionReport(
          params
        );
      this.dtReport.totalElements = response.totalElements;
      this.dtReport.grandTotal = response.grandTotal;
      this.dtReport.data = response.data.map((item, index) => {
        return {
          ...item,
          no: setNumbering(params?.page, params?.limit, index),
          periods: item.periods.map((period, index) => ({
            ...period,
            year: index + 1,
          })),
        };
      });
    } catch {
      this.dtReport.data = [];
      this.dtReport.totalElements = 0;
    } finally {
      this.loading.submit = false;
    }
  }

  buildRequestParamsList({
    page = 0,
    limit = 10,
    sorts,
    state: {
      amortization,
      transactionDate,
      transactionType,
      status,
      cashOutNumber,
      unitCode,
      serialNumber,
      documentNumber,
      description,
      leasingContractNumber,
    },
  }: {
    page?: number;
    limit?: number;
    sorts?: string;
    state: FormState;
  }): AmortizationTransactionReportParamsList {
    const { toStartDay, toEndDay } = useDate();
    const params: AmortizationTransactionReportParamsList = {};
    params.page = page - 1;
    params.limit = limit;
    params.sorts = sorts;

    params.amortizationNumber = amortization?.label;

    if (transactionDate.length === 2) {
      const [start, end] = transactionDate;
      params.transactionDateStart = toStartDay(start).format();
      params.transactionDateEnd = toEndDay(end).format();
    }

    if (transactionType.length > 0) {
      params.transactionType = transactionType.join(",");
    }

    params.status = status;
    params.cashOutNumber = cashOutNumber;
    params.unitCode = unitCode;
    params.serialNumber = serialNumber;
    params.documentNumber = documentNumber;
    params.description = description;
    params.leasingContractNumber = leasingContractNumber;

    return params;
  }

  handleSubmit(): void {
    this.pagination.page = 1;
    const params = this.buildRequestParamsList({
      page: this.pagination.page,
      limit: this.pagination.limit,
      sorts: this.pagination.sorts,
      state: this.form,
    });
    this.getTransactionReportData(params);
  }

  buildRequestParamsDownload({
    state,
    page,
    limit,
  }: {
    state: FormState;
    page?: number;
    limit?: number;
  }): AmortizationTransactionReportParamsDownload {
    const params: AmortizationTransactionReportParamsDownload = {
      ...this.buildRequestParamsList({ page, limit, state }),
      params: this.buildHeader(state).join(","),
    };
    return params;
  }

  buildHeader(state: FormState): string[] {
    const defaultCompany = "PT. SATRIA PIRANTI PERKASA";
    const defaultValue = "ALL";
    const company =
      this.$store.state.authStore.authData.companyName || defaultCompany;
    const transactionType = state.transactionType?.join(";") ?? defaultValue;
    const cashOutNumber = state.cashOutNumber ?? defaultValue;
    const unitCode = state.unitCode ?? defaultValue;
    const serialNumber = state.serialNumber ?? defaultValue;
    const amortizationNumber = state.amortization?.label ?? defaultValue;
    const documentNumber = state.documentNumber ?? defaultValue;
    const description = state.description ?? defaultValue;
    const status = state.status ?? defaultValue;
    const leasingContractNumber = state.leasingContractNumber ?? defaultValue;
    let transactionDateStart = defaultValue;
    let transactionDateEnd = defaultValue;

    if (state.transactionDate && state.transactionDate.length === 2) {
      const start = dateFormat(state.transactionDate[0], DATE_FORMAT_MMM_YYYY);
      const end = dateFormat(state.transactionDate[1], DATE_FORMAT_MMM_YYYY);
      transactionDateStart = start;
      transactionDateEnd = end;
    }

    return [
      company,
      amortizationNumber,
      transactionDateStart,
      transactionDateEnd,
      cashOutNumber,
      unitCode,
      serialNumber,
      documentNumber,
      description,
      status,
      leasingContractNumber,
      transactionType,
    ];
  }

  async handleDownload(): Promise<void> {
    try {
      const { toDownload } = useBlob();
      this.loading.download = true;
      const params = this.buildRequestParamsDownload({
        page: this.pagination.page,
        limit: this.pagination.limit,
        state: this.form,
      });
      const response =
        await amortizationTransactionReportService.downloadReport(params);
      toDownload(response, "amortization-transaction-report.xlsx");
    } catch {
      this.showNotifError("notif_download_error");
    } finally {
      this.loading.download = false;
    }
  }

  onChangeTable(
    { current, pageSize }: APagination,
    _,
    { order, columnKey }: SorterProps
  ): void {
    this.pagination.page = pageSize !== this.pagination.limit ? 1 : current;
    this.pagination.limit = pageSize;
    if (order && order === "ascend") {
      this.pagination.sorts = `${columnKey}:asc`;
    } else if (order && order === "descend") {
      this.pagination.sorts = `${columnKey}:desc`;
    } else {
      this.pagination.sorts = undefined;
    }

    const params = this.buildRequestParamsList({
      page: this.pagination.page,
      limit: this.pagination.limit,
      sorts: this.pagination.sorts,
      state: this.form,
    });
    this.getTransactionReportData(params);
  }
}
