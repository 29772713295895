



















































































































































import dateFormat from "@/filters/date.filter";
import quantityFilter from "@/filters/quantity.filter";
import { toTitlecase } from "@/helpers/common";
import { useDisabledFromTomorrow } from "@/hooks/datepicker";
import { WarehouseTransferMapper } from "@/mapper/WarehouseTransfer.mapper";
import MNotification from "@/mixins/MNotification.vue";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { WAREHOUSE_STATE } from "@/models/enums/warehouse-transfer.enum";
import { ReceiveWarehouseTransferFormState } from "@/models/interface/warehouse-transfer/ReceiveWarehouseTransferFormState.interface";
import {
  DetailWarehouseTransfer,
  RequestWarehouseTransfer,
} from "@/models/interface/warehouse.interface";
import { logisticServices } from "@/services/logistic.service";
import { FormModel } from "ant-design-vue";
import printJS from "print-js";
import { Component, Mixins, Ref } from "vue-property-decorator";

@Component({})
export default class ReceiveWarehouseTransfer extends Mixins(MNotification) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  useDisabledFromTomorrow = useDisabledFromTomorrow;
  toTitlecase = toTitlecase;
  dateFormat = dateFormat;

  warehouseTransfer: DetailWarehouseTransfer = {
    attachment: "",
    createdDate: "",
    date: "",
    destinationLocation: "",
    destinationLocationId: "",
    documentNumber: "",
    id: "",
    modifiedDate: "",
    notes: "",
    receivedDate: "",
    sourceLocation: "",
    sourceLocationId: "",
    state: WAREHOUSE_STATE.SUBMITTED,
    transferLines: [],
  };

  @Ref("formModel")
  formModel!: FormModel;

  formState: ReceiveWarehouseTransferFormState =
    new ReceiveWarehouseTransferFormState();

  formRules = {
    receiveDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  loading = {
    getDetail: false,
    print: false,
    cancel: false,
    receive: false,
  };

  columns = [
    {
      title: this.$t("lbl_part"),
      dataIndex: "part",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_total_qty"),
      dataIndex: "totalQty",
      width: 200,
      customRender: text => quantityFilter(text),
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "uom",
      width: 200,
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_qty_received"),
      dataIndex: "qtyReceive",
      width: 200,
      scopedSlots: { customRender: "qtyReceive" },
    },
    {
      title: this.$t("lbl_qty_outstanding"),
      dataIndex: "qtyOutstanding",
      width: 200,
      customRender: text => quantityFilter(text),
    },
  ];

  get warehouseTransferId(): string {
    return this.$route.params.id as string;
  }

  mounted() {
    this.loading.getDetail = true;
    logisticServices
      .detailWarehouseTransfer(this.warehouseTransferId)
      .then(res => {
        this.warehouseTransfer = res;
        this.formState =
          WarehouseTransferMapper.mapReceiveWarehouseTransferFormState(res);
      })
      .finally(() => {
        this.loading.getDetail = false;
      });
  }

  handleBack(): void {
    this.$router.push({ name: "logistic.warehouse-transfer" });
  }

  handlePrint(): void {
    this.loading.print = true;
    logisticServices
      .getDownloadWarehouseTransferForm(this.warehouseTransferId)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        printJS(url);
      })
      .finally(() => (this.loading.print = false));
  }

  handleCancel(): void {
    this.loading.cancel = true;
    logisticServices
      .cancelWarehouseTransfer(this.warehouseTransferId)
      .then(() => {
        this.showNotifSuccess("notif_cancel_success", {
          documentNumber: this.warehouseTransfer.documentNumber,
        });
        this.handleBack();
      })
      .finally(() => {
        this.loading.cancel = false;
      });
  }

  handleReceive(): void {
    this.formModel.validate((valid: boolean) => {
      if (!valid) {
        this.showNotifWarning("notif_validation_error");
        return;
      }

      const dto: RequestWarehouseTransfer =
        WarehouseTransferMapper.mapRequestWarehouseTransferFromReceiveForm(
          this.warehouseTransfer,
          this.formState
        );

      this.loading.receive = true;
      logisticServices
        .receiveWarehouseTransfer(this.warehouseTransferId, dto)
        .then(() => {
          this.showNotifSuccess("notif_receive_success", {
            documentNumber: this.warehouseTransfer.documentNumber,
          });

          this.handleBack();
        })
        .finally(() => {
          this.loading.receive = true;
        });
    });
  }
}
