var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout-content",
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-card",
                { attrs: { title: "Asset Inquiry > Retirement" } },
                [
                  _c(
                    "a-tabs",
                    {
                      attrs: {
                        "default-active-key": "Retirement",
                        activeKey: "Retirement"
                      }
                    },
                    [
                      _c(
                        "a-tab-pane",
                        { key: "Retirement", attrs: { tab: "Retirement" } },
                        [
                          _c(
                            "a-row",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "a-form",
                                    _vm._b(
                                      {
                                        attrs: {
                                          layout: "vertical",
                                          form: _vm.form
                                        }
                                      },
                                      "a-form",
                                      _vm.formItemLayout,
                                      false
                                    ),
                                    [
                                      _c(
                                        "a-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "a-form-model-item",
                                            {
                                              staticStyle: {
                                                "margin-top": "1rem"
                                              },
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.assetId.label
                                                ),
                                                required: ""
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.assetId
                                                        .decorator,
                                                    expression:
                                                      "formRules.assetId.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  disabled: "",
                                                  name:
                                                    _vm.formRules.assetId.name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.assetId
                                                      .placeholder
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              staticStyle: {
                                                "margin-top": "1rem"
                                              },
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.assetBookId
                                                    .label
                                                ),
                                                required: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules
                                                          .assetBookId
                                                          .decorator,
                                                      expression:
                                                        "formRules.assetBookId.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    disabled: "",
                                                    name:
                                                      _vm.formRules.assetBookId
                                                        .name,
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.assetBookId
                                                        .placeholder
                                                    ),
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading:
                                                      _vm.loadingListAssetBook
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListAssetBook(
                                                        value
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataListBook,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.name +
                                                                        " " +
                                                                        data.type +
                                                                        " " +
                                                                        data.method
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.name +
                                                                    " " +
                                                                    data.type +
                                                                    " " +
                                                                    data.method
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              staticStyle: {
                                                "margin-top": "1rem"
                                              },
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.unit.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input-number", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.unit
                                                        .decorator,
                                                    expression:
                                                      "formRules.unit.decorator"
                                                  }
                                                ],
                                                staticClass: "w-100",
                                                attrs: {
                                                  disabled: "",
                                                  name: _vm.formRules.unit.name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.unit
                                                      .placeholder
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              staticStyle: {
                                                "margin-top": "1rem"
                                              },
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.unitRetired
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input-number", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.unitRetired
                                                        .decorator,
                                                    expression:
                                                      "formRules.unitRetired.decorator"
                                                  }
                                                ],
                                                staticClass: "w-100",
                                                attrs: {
                                                  disabled: _vm.idReferenceNumber
                                                    ? true
                                                    : false,
                                                  name:
                                                    _vm.formRules.unitRetired
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.unitRetired
                                                      .placeholder
                                                  ),
                                                  parser: _vm.formatNumericOnly
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.validateInput(
                                                      $event,
                                                      "retire"
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              staticStyle: {
                                                "margin-top": "1rem"
                                              },
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.costOfSale.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.costOfSale
                                                        .decorator,
                                                    expression:
                                                      "formRules.costOfSale.decorator"
                                                  }
                                                ],
                                                staticClass: "w-100",
                                                attrs: {
                                                  name:
                                                    _vm.formRules.costOfSale
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.costOfSale
                                                      .placeholder
                                                  ),
                                                  disabled:
                                                    _vm.requiredCostOfSold
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.validateInput(
                                                      $event,
                                                      "selling"
                                                    )
                                                  },
                                                  blur: function($event) {
                                                    return _vm.onBlur(
                                                      $event,
                                                      "costOfSale"
                                                    )
                                                  },
                                                  focus: function($event) {
                                                    return _vm.onFocus(
                                                      $event,
                                                      "costOfSale"
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              staticStyle: {
                                                "margin-top": "1rem"
                                              },
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.gainLossAmount
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules
                                                        .gainLossAmount
                                                        .decorator,
                                                    expression:
                                                      "formRules.gainLossAmount.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  disabled: "",
                                                  name:
                                                    _vm.formRules.gainLossAmount
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.gainLossAmount
                                                      .placeholder
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              staticStyle: {
                                                "margin-top": "1rem"
                                              },
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.invoiceNumber
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules
                                                        .invoiceNumber
                                                        .decorator,
                                                    expression:
                                                      "formRules.invoiceNumber.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  disabled: _vm.idReferenceNumber
                                                    ? true
                                                    : false,
                                                  name:
                                                    _vm.formRules.invoiceNumber
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.invoiceNumber
                                                      .placeholder
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "a-form-model-item",
                                            {
                                              staticStyle: {
                                                "margin-top": "1rem"
                                              },
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.netBookValue
                                                    .label
                                                ),
                                                required:
                                                  _vm.requiredNetBookValue
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.netBookValue
                                                        .decorator,
                                                    expression:
                                                      "formRules.netBookValue.decorator"
                                                  }
                                                ],
                                                staticClass: "w-100",
                                                attrs: {
                                                  name:
                                                    _vm.formRules.netBookValue
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.netBookValue
                                                      .placeholder
                                                  ),
                                                  disabled:
                                                    _vm.disabledNetBookValue
                                                },
                                                on: {
                                                  blur: function($event) {
                                                    return _vm.onBlur(
                                                      $event,
                                                      "netBookValue"
                                                    )
                                                  },
                                                  focus: function($event) {
                                                    return _vm.onFocus(
                                                      $event,
                                                      "netBookValue"
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              staticStyle: {
                                                "margin-top": "1rem"
                                              },
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.currentCost
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.currentCost
                                                        .decorator,
                                                    expression:
                                                      "formRules.currentCost.decorator"
                                                  }
                                                ],
                                                staticClass: "w-100",
                                                attrs: {
                                                  disabled: "",
                                                  name:
                                                    _vm.formRules.currentCost
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.currentCost
                                                      .placeholder
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              staticStyle: {
                                                "margin-top": "1rem"
                                              },
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.costOfRetired
                                                    .label
                                                ),
                                                required: _vm.requiredRetired
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules
                                                        .costOfRetired
                                                        .decorator,
                                                    expression:
                                                      "formRules.costOfRetired.decorator"
                                                  }
                                                ],
                                                staticClass: "w-100",
                                                attrs: {
                                                  disabled: "",
                                                  name:
                                                    _vm.formRules.costOfRetired
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.costOfRetired
                                                      .placeholder
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              staticStyle: {
                                                "margin-top": "1rem"
                                              },
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.costOfRemoval
                                                    .label
                                                ),
                                                required: _vm.requiredRemoval
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules
                                                        .costOfRemoval
                                                        .decorator,
                                                    expression:
                                                      "formRules.costOfRemoval.decorator"
                                                  }
                                                ],
                                                staticClass: "w-100",
                                                attrs: {
                                                  name:
                                                    _vm.formRules.costOfRemoval
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.costOfRemoval
                                                      .placeholder
                                                  ),
                                                  disabled:
                                                    _vm.disabledCostOfRemoval
                                                },
                                                on: {
                                                  blur: function($event) {
                                                    return _vm.onBlur(
                                                      $event,
                                                      "costOfRemoval"
                                                    )
                                                  },
                                                  focus: function($event) {
                                                    return _vm.onFocus(
                                                      $event,
                                                      "costOfRemoval"
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.retireType.label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules.retireType
                                                          .decorator,
                                                      expression:
                                                        "formRules.retireType.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    disabled: _vm.disabledType,
                                                    name:
                                                      _vm.formRules.retireType
                                                        .name,
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.retireType
                                                        .placeholder
                                                    ),
                                                    filterOption: true
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.typeChange(
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "a-select-option",
                                                    {
                                                      attrs: {
                                                        value: "DISPOSAL"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a-tooltip",
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [
                                                              _vm._v(
                                                                " DISPOSAL "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" DISPOSAL ")
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-select-option",
                                                    {
                                                      attrs: { value: "SOLD" }
                                                    },
                                                    [
                                                      _c(
                                                        "a-tooltip",
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [_vm._v(" SOLD ")]
                                                          ),
                                                          _vm._v(" SOLD ")
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              staticStyle: {
                                                "margin-top": "1rem"
                                              },
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.retireDate.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-date-picker", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.retireDate
                                                        .decorator,
                                                    expression:
                                                      "formRules.retireDate.decorator"
                                                  }
                                                ],
                                                staticClass: "w-100",
                                                attrs: {
                                                  name:
                                                    _vm.formRules.retireDate
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.retireDate
                                                      .placeholder
                                                  ),
                                                  format:
                                                    _vm.DEFAULT_DATE_FORMAT
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              staticStyle: {
                                                "margin-top": "1rem"
                                              },
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.soldTo.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.soldTo
                                                        .decorator,
                                                    expression:
                                                      "formRules.soldTo.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  disabled: _vm.idReferenceNumber
                                                    ? true
                                                    : false,
                                                  name:
                                                    _vm.formRules.soldTo.name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.soldTo
                                                      .placeholder
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-row",
                            [
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-right": "0.5rem" },
                                  attrs: { type: "danger" },
                                  on: { click: _vm.cancleHandle }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_cancel")))]
                              ),
                              _vm.allowRetirement &&
                              _vm.$can("create", "asset-retirement")
                                ? _c(
                                    "a-button",
                                    {
                                      key: "submit",
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.isFormSubmitted
                                      },
                                      on: { click: _vm.submitForm }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("lbl_save")) + " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }