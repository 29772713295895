































import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useInventory } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { InventoryLineResponseDto } from "@/models/interface/inventory";
import { LabelInValue } from "@/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class SelectProductInventory extends Vue {
  @Prop({ required: false, type: Object, default: undefined })
  readonly value!: LabelInValue;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: String, default: "code" })
  optionLabel!: "code" | "name";

  @Prop({ required: false, type: String, default: "" })
  warehouseLocationId!: string;

  @Prop({ required: false, type: String, default: "" })
  productCategoryId!: string;

  @Prop({ required: false, type: String, default: "" })
  productBrand!: string;

  options: Array<Option<InventoryLineResponseDto>> = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.preFetch();
  }

  @Watch("warehouseLocationId")
  onChangeWarehouse(): void {
    this.options = [];
    this.preFetch();
  }

  @Watch("productCategoryId")
  onChangeCategory(): void {
    this.options = [];
    this.preFetch();
  }

  @Watch("productBrand")
  onChangeBrand(): void {
    this.options = [];
    this.preFetch();
  }

  get isMapAsCode(): boolean {
    return this.optionLabel === "code";
  }

  preFetch(): void {
    const params = new RequestQueryParams();
    params.search = this.buildQuery();

    this.fetchOptions(params);
  }

  fetchOptions(params = new RequestQueryParams()): void {
    const { findAllInventoryLine, toOptions } = useInventory();

    this.loading = true;
    findAllInventoryLine(params)
      .then(({ data }) => {
        this.options = toOptions(data, this.optionLabel);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e?: LabelInValue): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { searchBy } = useInventory();
    const params = new RequestQueryParams();
    const query: Array<string> = [this.buildQuery()];
    const builder = new SearchBuilder();

    if (val) {
      query.unshift(searchBy({ productCode: val, productName: val }));
    }

    params.search = query.join(builder.AND);

    this.fetchOptions(params);
  }

  findOption(
    value?: LabelInValue
  ): Option<InventoryLineResponseDto> | undefined {
    return this.options.find(e => value?.key === e.value);
  }

  buildQuery(): string {
    const builder = new SearchBuilder();
    const query: Array<string> = [
      builder.push(["available", "0"], { ht: true }).build(),
    ];

    if (this.warehouseLocationId) {
      query.push(
        builder
          .push(["warehouseLocation.secureId", this.warehouseLocationId])
          .build()
      );
    }

    if (this.productCategoryId) {
      query.push(
        builder
          .push(["product.category.secureId", this.productCategoryId])
          .build()
      );
    }

    if (this.productBrand) {
      query.push(builder.push(["product.merk", this.productBrand]).build());
    }

    return query.join(builder.AND);
  }
}
