import { ONE } from "@/models/constant/global.constant";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import {
  TruckingInvoiceArCreateDto,
  TruckingInvoiceArLineRequestDto,
  TruckingInvoiceArResponseDto,
  TruckingInvoiceArUpdateDto,
} from "@interface/trucking-account-receivable";
import {
  buildInvoiceArCreateDto,
  buildInvoiceArLineRequestDto,
  buildInvoiceArUpdateDto,
} from "../account-receivable/AccountReceivableInvoiceDtoBuilder";

export function buildTruckingInvoiceArLineRequestDto(): TruckingInvoiceArLineRequestDto {
  return {
    ...buildInvoiceArLineRequestDto(),
    documentReference: "",
    assetId: "",
    salesName: "",
  };
}

export function buildTruckingInvoiceArCreateDto(): TruckingInvoiceArCreateDto {
  return {
    ...buildInvoiceArCreateDto(),
    invoiceARLines: [],
  };
}

export function buildTruckingInvoiceArUpdateDto(): TruckingInvoiceArUpdateDto {
  return {
    ...buildInvoiceArUpdateDto(),
    invoiceARLines: [],
  };
}

export function buildTruckingInvoiceArResponseDto(): TruckingInvoiceArResponseDto {
  return {
    isLuxury: false,
    createdBy: "",
    createdDate: "",
    id: "",
    modifiedBy: "",
    modifiedDate: "",
    accountingDate: "",
    applyCredit: [],
    applyPrepayment: {
      prepaymentLines: [],
    },
    assignedBy: "",
    branchWarehouseId: "",
    branchWarehouseName: "",
    creditMemoUsed: 0,
    currency: "",
    currencyRate: ONE,
    custCode: "",
    customerBillToAddress: "",
    customerGoodReceiptNo: "",
    customerId: "",
    customerName: "",
    customerPurchaseOrderNo: "",
    customerShipToAddress: "",
    customerTaxType: "",
    deliveryOrderIds: [],
    deliveryOrders: [],
    description: "",
    discountValue: 0,
    documentNumber: "",
    dpp: 0,
    grandTotal: 0,
    icBillingId: "",
    invoiceAmount: 0,
    invoiceARLines: [],
    invoiceARReceiptDetails: [],
    invoiceDate: "",
    invoiceGrossAmount: 0,
    invoiceIncomeTaxAmount: 0,
    invoiceJoinNo: "",
    invoiceReceivedDate: "",
    invoiceSource: "",
    invoiceTaxAmount: 0,
    invoiceType: "",
    journalId: "",
    journalNo: "",
    operatorName: "",
    paidAmount: 0,
    percentDiscount: 0,
    picCustomerName: "",
    prepaymentUsed: 0,
    receivableAccount: "",
    receivableAccountId: "",
    remainingInvoiceAmount: 0,
    returnAmount: 0,
    salesOrderIds: [],
    salesOrders: [],
    status: "",
    taxInvoiceDate: "",
    taxInvoiceNumber: "",
    taxIsUploaded: false,
    taxRegistrationName: "",
    taxRegistrationNumber: "",
    taxType: TAX_CALCULATION.EXCLUSIVE,
    termOfPayment: 0,
    usageType: "",
    vat: 0,
  };
}
