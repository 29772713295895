var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c("legend", [_vm._v(_vm._s(_vm.$t("lbl_general")))]),
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: {
            model: _vm.store.form,
            rules: _vm.formRules,
            "wrapper-col": { span: 12 },
            "label-col": { span: 8 },
            "label-align": "left"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_branch"),
                        prop: "branchWarehouseId"
                      }
                    },
                    [
                      _c("SelectBranch", {
                        model: {
                          value: _vm.store.form.branchWarehouseId,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "branchWarehouseId", $$v)
                          },
                          expression: "store.form.branchWarehouseId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_invoice_type"),
                        prop: "invoiceType"
                      }
                    },
                    [
                      _c("SelectMasterType", {
                        attrs: { name: "INVOICE_AR_TYPE" },
                        scopedSlots: _vm._u([
                          {
                            key: "options",
                            fn: function(ref) {
                              var options = ref.options
                              return [
                                _vm._l(options, function(opt) {
                                  return [
                                    opt.value.toUpperCase() !==
                                    _vm.EXCLUDE_INVOICE_TYPE
                                      ? _c(
                                          "a-select-option",
                                          {
                                            key: opt.key,
                                            attrs: { value: opt.value }
                                          },
                                          [
                                            _c("a-tooltip", [
                                              _c(
                                                "span",
                                                {
                                                  attrs: { slot: "title" },
                                                  slot: "title"
                                                },
                                                [_vm._v(_vm._s(opt.label))]
                                              ),
                                              _vm._v(
                                                " " + _vm._s(opt.label) + " "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                })
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.store.form.invoiceType,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "invoiceType", $$v)
                          },
                          expression: "store.form.invoiceType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_customer_name"),
                        prop: "customerId"
                      }
                    },
                    [
                      _c("SelectCustomer", {
                        on: { "update:meta": _vm.onChangeCustomer },
                        model: {
                          value: _vm.store.form.customerId,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "customerId", $$v)
                          },
                          expression: "store.form.customerId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_shipping_address"),
                        prop: "customerShipToAddress"
                      }
                    },
                    [
                      _c("SelectContactAddress", {
                        attrs: {
                          "address-type": "ship",
                          addresses: _vm.custAddresses
                        },
                        model: {
                          value: _vm.store.form.customerShipToAddress,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.store.form,
                              "customerShipToAddress",
                              $$v
                            )
                          },
                          expression: "store.form.customerShipToAddress"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_billing_address"),
                        prop: "customerBillToAddress"
                      }
                    },
                    [
                      _c("SelectContactAddress", {
                        attrs: {
                          "address-type": "bill",
                          addresses: _vm.custAddresses
                        },
                        model: {
                          value: _vm.store.form.customerBillToAddress,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.store.form,
                              "customerBillToAddress",
                              $$v
                            )
                          },
                          expression: "store.form.customerBillToAddress"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_term_of_payment"),
                        prop: "termOfPayment"
                      }
                    },
                    [
                      _c("SelectMasterType", {
                        attrs: { name: "TOP" },
                        model: {
                          value: _vm.store.form.termOfPayment,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "termOfPayment", $$v)
                          },
                          expression: "store.form.termOfPayment"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_invoice_date"),
                        prop: "invoiceDate"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        attrs: { format: _vm.DEFAULT_DATE_FORMAT },
                        on: { change: _vm.onChangeInvoiceDate },
                        model: {
                          value: _vm.store.form.invoiceDate,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "invoiceDate", $$v)
                          },
                          expression: "store.form.invoiceDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_accounting_date"),
                        prop: "accountingDate",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          disabled: ""
                        },
                        model: {
                          value: _vm.store.form.accountingDate,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "accountingDate", $$v)
                          },
                          expression: "store.form.accountingDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_currency"),
                        prop: "currency",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("SelectCurrency", {
                        on: { "update:meta": _vm.onChangeCurrency },
                        model: {
                          value: _vm.store.form.currency,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "currency", $$v)
                          },
                          expression: "store.form.currency"
                        }
                      })
                    ],
                    1
                  ),
                  !_vm.isIdr
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_currency_rates"),
                            prop: "currencyRate",
                            "label-align": "right"
                          }
                        },
                        [
                          _c("a-input-number", {
                            staticClass: "w-50",
                            attrs: {
                              parser: _vm.reverseFormatNumber,
                              formatter: _vm.formatterNumber,
                              precision: _vm.storeBaseDecimalPlace,
                              min: 1
                            },
                            model: {
                              value: _vm.store.form.currencyRate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.store.form,
                                  "currencyRate",
                                  _vm._n($$v)
                                )
                              },
                              expression: "store.form.currencyRate"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_receivable_account"),
                        prop: "receivableAccountName",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("SelectReceivableAccount", {
                        attrs: {
                          disabled: _vm.isIdr,
                          currencyCode: _vm.store.form.currency
                        },
                        on: { "update:meta": _vm.onChangeArAcc },
                        model: {
                          value: _vm.store.form.receivableAccountName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.store.form,
                              "receivableAccountName",
                              $$v
                            )
                          },
                          expression: "store.form.receivableAccountName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_line_account"),
                        prop: "lineAccountName",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.store.form.lineAccountName,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "lineAccountName", $$v)
                          },
                          expression: "store.form.lineAccountName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_luxury_goods"),
                        "label-align": "right",
                        prop: "isLuxury"
                      }
                    },
                    [
                      _c("a-switch", {
                        on: { change: _vm.onChangeLuxury },
                        model: {
                          value: _vm.store.form.isLuxury,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "isLuxury", $$v)
                          },
                          expression: "store.form.isLuxury"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_po_number"),
                        prop: "poNumber",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { "allow-clear": "" },
                        model: {
                          value: _vm.store.form.poNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "poNumber", $$v)
                          },
                          expression: "store.form.poNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_invoice_description"),
                        prop: "description",
                        "label-align": "right"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "extra",
                          fn: function() {
                            return [
                              _c("character-length", {
                                attrs: { value: _vm.store.form.description }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("a-textarea", {
                        model: {
                          value: _vm.store.form.description,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "description", $$v)
                          },
                          expression: "store.form.description"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }