


















import Select from "@/components/custom/select/Select.vue";
import { debounceProcess } from "@/helpers/debounce";
import { useCoa } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ONE } from "@/models/constant/global.constant";
import { AccountingAccountResponseDto } from "@/models/interface/accounting-account";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    Select,
  },
})
export default class SelectAccountChild extends Vue {
  @Prop({ required: false, type: Object, default: undefined })
  value!: { key: string; label: string };

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<AccountingAccountResponseDto>[] = [];
  loading = false;

  pagination = {
    page: 0,
    search: "",
  };

  allLoaded = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions(this.buildParams());
  }

  buildParams(): RequestQueryParams {
    const params = new RequestQueryParams();
    params.sorts = "code:asc";
    params.page = this.pagination.page;
    params.search = this.pagination.search;
    return params;
  }

  fetchOptions(params: RequestQueryParams): void {
    const { findAllChildAccount, toOptions } = useCoa();
    this.loading = true;
    findAllChildAccount(params)
      .then(res => {
        this.allLoaded = res.currentPage + ONE === res.totalPages;
        const copy = [...this.options];
        this.options = [...copy, ...toOptions(res.data)];
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  resetState(): void {
    this.pagination.page = 0;
    this.options = [];
    this.allLoaded = false;
  }

  onSearch(val = ""): void {
    const { filterBy } = useCoa();
    this.resetState();
    this.pagination.search = filterBy({
      code: val,
      description: val,
    });

    this.fetchOptions(this.buildParams());
  }

  findOption(value: string): Option<AccountingAccountResponseDto> | undefined {
    return this.options.find(e => value === e.value);
  }

  onScrollEnd(): void {
    if (this.loading || this.allLoaded) {
      return;
    }

    this.pagination.page += ONE;
    this.fetchOptions(this.buildParams());
  }
}
