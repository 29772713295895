






























































































































































































































































































































































































import { DisplayTotal } from "@/components/ArPrepayment";
import DisplayBoolean from "@/components/DisplayBoolean/DisplayBoolean.vue";
import { useArPrepayment, useBlob, usePrint } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { InvoiceArPrepaymentStatusEnum } from "@/models/enums/ArPrepayment.enum";
import { CurrencyCodeEnum } from "@/models/enums/global.enum";
import { State as ArPrepaymentStore } from "@/store/aRPrepayment.store";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { Component, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
  components: {
    DisplayTotal,
    DisplayBoolean,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.aRPrepaymentStore,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
})
export default class DetailPage extends Mixins(MNotificationVue) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  reverseFormatNumber = reverseFormatNumber;
  formatterNumber = formatterNumber;

  store!: ArPrepaymentStore;

  loading = {
    print: false,
    cancel: false,
  };

  columnPrepaymentUsed = [
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoiceDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "invoiceNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_prepayment_used_amount"),
      dataIndex: "appliedAmount",
      scopedSlots: { customRender: "currency" },
    },
  ];
  columnPrepaymentPaid = [
    {
      title: this.$t("lbl_ar_receipt_date"),
      dataIndex: "receiptDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_ar_receipt_number"),
      dataIndex: "receiptNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_receipt_amount"),
      dataIndex: "receiptAmount",
      scopedSlots: { customRender: "currency" },
    },
  ];

  get isIdr(): boolean {
    return (
      this.store.detailDoc.currency.toUpperCase() ===
      CurrencyCodeEnum.IDR.toUpperCase()
    );
  }

  get totalItemUsed(): number {
    return this.store.detailDoc.invoiceARDetails?.length || 0;
  }

  get totalItemPaid(): number {
    return this.store.detailDoc.invoiceARReceiptDetails?.length || 0;
  }

  get allowCancel(): boolean {
    const notAllowed: string[] = [InvoiceArPrepaymentStatusEnum.CANCELLED];
    return !notAllowed.includes(
      this.store.detailDoc.invoicePrepaymentStatus.toUpperCase()
    );
  }

  handleBack(): void {
    this.$router.push({
      name: "account-receivable.invoice.prepayment",
      query: this.$route.query,
    });
  }

  handleCancel(): void {
    const { cancel } = useArPrepayment();
    this.loading.cancel = true;
    cancel(this.store.detailDoc.id)
      .then(({ documentNumber }) => {
        this.handleBack();
        this.showNotifSuccess("notif_cancel_success", {
          documentNumber,
        });
      })
      .finally(() => {
        this.loading.cancel = false;
      });
  }

  handlePrint(): void {
    const { print: printInvoice } = useArPrepayment();
    const { toObjectUrl } = useBlob();
    const { print } = usePrint();

    this.loading.print = true;
    printInvoice(this.store.detailDoc.id)
      .then(res => {
        print(toObjectUrl(res));
      })
      .finally(() => {
        this.loading.print = false;
      });
  }
}
