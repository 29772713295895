



















































































import SelectProduct from "@/components/custom/select/SelectProduct.vue";
import { useProduct } from "@/hooks";
import { useCountQtyDifference } from "@/hooks/useStockAdjustment";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { InventoryLineResponseDto } from "@/models/interface/inventory";
import {
  ProductDetailDto,
  ProductUomConversionDto,
} from "@/models/interface/master-product";
import { FormValue, StockAdjRow } from "@/models/interface/stock-adjustment";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { Component, Mixins } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";
import SelectProductInventory from "./SelectProductInventory.vue";

@Component({
  components: {
    SelectProduct,
    SelectProductInventory,
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) => st.preferenceStore.baseDecimalPlace,
    }),
    ...mapGetters({
      form: "stockAdjStore/getForm",
    }),
  },
  methods: {
    ...mapActions({
      addRow: "stockAdjStore/addStockAdjRow",
      deleteRow: "stockAdjStore/deleteStockAdjRow",
    }),
  },
})
export default class FormTableOpname extends Mixins(MNotificationVue) {
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  useCountQtyDifference = useCountQtyDifference;

  form!: FormValue;
  addRow!: () => void;
  deleteRow!: (payload: Array<number>) => void;

  selectedRowKeys: Array<number> = [];

  columns = [
    {
      // user pilih dari master product
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      //   width: "20%",
      scopedSlots: { customRender: "productCode" },
    },
    {
      // user pilih dari master product
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      //   width: "20%",
      scopedSlots: { customRender: "productName" },
    },
    {
      // autofill dari product yang dipilih
      // statis
      title: this.$t("lbl_brand"),
      dataIndex: "productMerk",
      //   width: "20%",
      scopedSlots: { customRender: "nullable" },
    },
    {
      // qty inventory on hand
      // statis
      title: this.$t("lbl_qty_total"),
      dataIndex: "qty",
      //   width: "20%",
      scopedSlots: { customRender: "number" },
    },
    {
      // user input
      title: this.$t("lbl_uom"),
      key: "uom",
      //   width: "20%",
      scopedSlots: { customRender: "uom" },
    },
    {
      // user input
      title: this.$t("lbl_qty_physical"),
      dataIndex: "physicalQty",
      scopedSlots: { customRender: "physicalQty" },
    },
    {
      // dari perhitungan
      title: this.$t("lbl_qty_difference"),
      dataIndex: "differenceQty",
      scopedSlots: { customRender: "differenceQty" },
    },
  ];

  onSelectChange(keys: Array<number>): void {
    this.selectedRowKeys = keys;
  }

  onChangeProduct(
    e: Option<InventoryLineResponseDto> | undefined,
    row: StockAdjRow
  ): void {
    row.productCode = undefined;
    row.productName = undefined;
    row.productMerk = e?.meta?.product?.merk || "";
    row.uom = undefined;
    row.qty = e?.meta?.onHand || 0;

    if (e && e?.meta) {
      row.productCode = {
        label: e.meta.product.code,
        key: e.meta.product.id,
      };
      row.productName = {
        label: e.meta.product.name,
        key: e.meta.product.id,
      };
    }

    if (!e?.meta?.product?.id) return;
    this.getDetailProduct(
      e.meta.product.id,
      ({ baseUnit, baseUnitId, uomConversions }) => {
        if (baseUnit && baseUnitId) {
          row.uom = {
            key: baseUnitId,
            label: baseUnit,
          };
        }
        row.uomOptions = this.toUomOptions(uomConversions);
      }
    );
  }

  getDetailProduct(id: string, cb: (payload: ProductDetailDto) => void): void {
    const { findById } = useProduct();
    findById(id).then(cb);
  }

  handleDeleteRow(): void {
    this.showConfirmationDeleteItems(() => {
      this.deleteRow(this.selectedRowKeys);
      this.selectedRowKeys = [];
    });
  }

  toUomOptions(
    uomConversions: ProductUomConversionDto[]
  ): Option<ProductUomConversionDto>[] {
    return uomConversions.map(item => ({
      key: item.uomSourceId,
      label: item.uomSource,
      value: item.uomSourceId,
      meta: item,
    }));
  }
}
