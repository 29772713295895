







































































































































































































































































import { debounceProcess } from "@/helpers/debounce";
import { useGeneralJournal } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { LogisticStockAdjustmentState } from "@/models/enums/stock-adjustment.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataResponseGetStockAdjustment,
  DataWarehouse,
  DataWarehouseBranch,
  DataWarehouseLocation,
} from "@/models/interface/logistic.interface";
import { logisticServices } from "@/services/logistic.service";
import moment from "moment";
import printJs from "print-js";
import Vue from "vue";

type Row = Omit<DataResponseGetStockAdjustment, "key"> & { key: string };

enum STOCK_TYPE {
  STOCK_ADJUSTMENT = "Stock Adjustment",
  STOCK_OPNAME = "Stock Opname",
}

const OPT_TYPE = [
  {
    key: "Stock Adjustment",
    value: STOCK_TYPE.STOCK_ADJUSTMENT,
  },
  {
    key: "Stock Opname",
    value: STOCK_TYPE.STOCK_OPNAME,
  },
];

export default Vue.extend({
  name: "StockAdjustmentList",
  mixins: [MNotificationVue],
  data() {
    this.getBranch = debounceProcess(this.getBranch, 400);
    this.getListWarehouse = debounceProcess(this.getListWarehouse, 400);
    this.getListLocationRack = debounceProcess(this.getListLocationRack, 400);
    this.getStockAdjustment = debounceProcess(this.getStockAdjustment, 400);
    return {
      branchId: "" as string,
      idWarehouse: "" as string,
      dataBranch: [] as DataWarehouseBranch[],
      dataListLocationWarehouse: [] as DataWarehouse[],
      dataListLocationRack: [] as DataWarehouseLocation[],
      modelForm: {
        date: [] as any,
      },
      params: { sorts: "date:asc" } as RequestQueryParamsModel,
      dataSource: [] as Array<Row>,
      columnsTable: [
        {
          title: this.$t("lbl_document_number"),
          dataIndex: "documentNumber",
          key: "documentNumber",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_date"),
          dataIndex: "date",
          key: "date",
          scopedSlots: { customRender: "date" },
        },
        {
          title: this.$t("lbl_location"),
          dataIndex: "warehouseLocation",
          key: "warehouseLocation",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_branch"),
          dataIndex: "branchName",
          key: "branchName",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_type"),
          dataIndex: "type",
          key: "type",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_journal_number"),
          dataIndex: "journalNumber",
          key: "journalNumber",
          scopedSlots: { customRender: "journal" },
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "state",
          key: "state",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_action"),
          dataIndex: "operation",
          align: "center",
          scopedSlots: { customRender: "operation" },
        },
      ],
      page: 1 as number,
      totalElements: 0 as number,
      limit: 10 as number,
      loadingTable: false as boolean,
      loadingSubmit: false as boolean,
      loadingDownload: false as boolean,
      loadingPrint: false as boolean,
      loadingBranch: false as boolean,
      loadingLocationWarehouse: false as boolean,
      loadingLocationRack: false as boolean,
      DEFAULT_DATE_FORMAT,
      attachmentFile: "" as string,
      optType: OPT_TYPE,
      dataStockAdjustment: [] as DataResponseGetStockAdjustment[],
      loadingNo: false as boolean,
      stockAdjustmentId: "" as string,
      form: this.$form.createForm(this, { name: "StockAdjustmentList" }),
      formRules: {
        no: {
          label: "lbl_document_number",
          name: "No",
          placeholder: "lbl_document_number_placeholder",
          decorator: [
            "no",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        branch: {
          label: "lbl_branch",
          name: "branch",
          placeholder: "lbl_branch_placeholder",
          decorator: [
            "branch",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        warehouse: {
          label: "lbl_warehouse",
          name: "warehouse",
          placeholder: "lbl_warehouse_placeholder",
          decorator: [
            "warehouse",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        rack: {
          label: "lbl_rack",
          name: "rack",
          placeholder: "lbl_rack_placeholder",
          decorator: [
            "rack",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        adj: {
          label: "lbl_adj_type",
          name: "Adj. Type",
          placeholder: "lbl_adj_type_placeholder",
          decorator: [
            "adj",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        date: {
          label: "lbl_date",
          name: "Date",
          placeholder: "lbl_date_placeholder",
          decorator: [
            "date",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
    };
  },
  created() {
    this.getBranch("");
    this.getStockAdjustment("");
  },
  methods: {
    moment,
    async getJournal(journalNumber: string): Promise<void> {
      try {
        const { findAll } = useGeneralJournal();
        const response = await findAll({
          search: "name~" + journalNumber,
        });
        const [journal] = response.data;
        if (!journal) throw new Error("Error: journal not found");
        this.$router.push({
          name: "generaljournal.detail",
          params: { id: journal.id },
        });
      } catch (error) {
        this.showNotifError("notif_process_fail");
      }
    },
    handleDownload() {
      this.form.validateFields((err, res) => {
        if (err) return;
        this.params.params = this.checkParams(res);
        this.params.search = this.dynamicSearch(res);
        if (
          this.modelForm.date &&
          Array.isArray(this.modelForm.date) &&
          this.modelForm.date.length > 0
        ) {
          const timeFrom = this.modelForm.date?.length
            ? this.moment(this.modelForm.date[0])
                .set({ hour: 0, minute: 0, second: 0 })
                .utcOffset("+07")
                .format()
            : "";
          const timeTo = this.modelForm.date?.length
            ? this.moment(this.modelForm.date[1])
                .set({ hour: 23, minute: 59, second: 59 })
                .utcOffset("+07")
                .format()
            : "";
          const searchDate = `date>=${timeFrom}_AND_date<=${timeTo}`;
          if (this.params.search) {
            this.params.search += `_AND_${searchDate}`;
          } else {
            this.params.search = `${searchDate}`;
          }
        }
        this.loadingDownload = true;
        logisticServices
          .getDownloadStockAdjustment(this.params)
          .then(response => {
            if (response) {
              const url = window.URL.createObjectURL(new Blob([response]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "stock_adjustment.xlsx"); //or any other extension
              document.body.appendChild(link);
              link.click();
            }
          })
          .finally(() => (this.loadingDownload = false));
      });
    },
    checkValue(value): string {
      if (value && !value.includes("undefined")) {
        return value;
      } else {
        return ",ALL";
      }
    },
    checkParams(res): string {
      let params = "";
      // company is mandatory
      params += this.$store.state.authStore.authData.companyName;
      params += this.checkValue(
        `,${
          this.dataStockAdjustment.find(dataFind => dataFind.id === res["no"])
            ?.documentNumber
        }`
      );
      params += this.checkValue(
        `,${
          this.dataBranch.find(dataFind => dataFind.id === res["branch"])?.name
        }`
      );
      params += this.checkValue(
        `,${this.dataListLocationWarehouse
          .find(dataFind => dataFind.id === res["warehouse"])
          ?.name?.replace(",", "")}`
      );
      params += this.checkValue(
        `,${this.dataListLocationRack
          .find(dataFind => dataFind.id === res["rack"])
          ?.name?.replace(",", "")}`
      );
      params += this.checkValue(`,${res["adj"]}`);
      // date from & date to
      params += this.checkValue(
        this.modelForm.date.length > 0
          ? `,${moment(this.modelForm.date[0])
              .set({ hour: 0, minute: 0, second: 0 })
              .utcOffset("+07")
              .format("DD-MM-yyyy HH:MM:ss")}`
          : ",ALL"
      );
      params += this.checkValue(
        this.modelForm.date.length > 0
          ? `,${moment(this.modelForm.date[1])
              .set({ hour: 23, minute: 59, second: 59 })
              .utcOffset("+07")
              .format("DD-MM-yyyy HH:MM:ss")}`
          : ",ALL"
      );

      return params;
    },
    handlePrint() {
      this.form.validateFields((err, res) => {
        if (err) return;
        // set params
        this.params.params = this.checkParams(res);
        // set search
        this.params.search = this.dynamicSearch(res);
        if (
          this.modelForm.date &&
          Array.isArray(this.modelForm.date) &&
          this.modelForm.date.length > 0
        ) {
          const timeFrom = this.modelForm.date?.length
            ? this.moment(this.modelForm.date[0])
                .set({ hour: 0, minute: 0, second: 0 })
                .utcOffset("+07")
                .format()
            : "";
          const timeTo = this.modelForm.date?.length
            ? this.moment(this.modelForm.date[1])
                .set({ hour: 23, minute: 59, second: 59 })
                .utcOffset("+07")
                .format()
            : "";
          const searchDate = `date>=${timeFrom}_AND_date<=${timeTo}`;
          if (this.params.search) {
            this.params.search += `_AND_${searchDate}`;
          } else {
            this.params.search = `${searchDate}`;
          }
        }
        this.loadingPrint = true;

        logisticServices
          .getPrintStockAdjustment(this.params)
          .then(response => {
            if (response) {
              const url = window.URL.createObjectURL(new Blob([response]));
              printJs(url);
            }
          })
          .finally(() => (this.loadingPrint = false));
      });
    },
    onRangePickerChange(dates: Array<any>): void {
      if (!dates.length) {
        this.modelForm.date = undefined;
      }
    },
    responsePageSizeChange(response: ResponsePagination) {
      this.limit = response.size;
      this.page = 1;
      this.onSubmit();
    },
    responseCurrentPageChange(response: ResponsePagination) {
      this.page = response.page;
      this.onSubmit();
    },
    handleCancel() {
      this.tableValueOpname = [];
      this.tableValueAdjustment = [];
      this.modelForm.date = [];
      this.form.resetFields();
    },
    disabledDate(current) {
      return current > moment().endOf("day");
    },
    handleSelectType(value: string): void {
      this.form.setFieldsValue({
        adj: value,
      });
    },
    assignSearch(key, value, and): string {
      if (key === "no" && value) return and + `secureId~${value}`;
      else if (key === "branch" && value)
        return (
          and + `warehouseLocation.warehouse.branchWarehouse.secureId~${value}`
        );
      else if (key === "warehouse" && value)
        return and + `warehouseLocation.warehouse.secureId~${value}`;
      else if (key === "rack" && value)
        return and + `warehouseLocation.secureId~${value}`;
      else if (key === "adj" && value)
        return and + `type~${value.replace(" ", "_").toUpperCase()}`;
      else return "";
    },
    dynamicSearch(res): string {
      let search = "";
      Object.keys(res).forEach(key => {
        if (!search) {
          search = this.assignSearch(key, res[key], "");
        } else {
          search += this.assignSearch(key, res[key], "_AND_");
        }
      });
      return search;
    },
    async onSubmit(): Promise<void> {
      try {
        this.form.validateFields((err, res) => {
          if (err) return;
          const params: RequestQueryParamsModel = {
            page: this.page - 1,
            limit: this.limit,
            sorts: "date:desc",
          };
          params.search = this.dynamicSearch(res);
          if (
            this.modelForm.date &&
            Array.isArray(this.modelForm.date) &&
            this.modelForm.date.length > 0
          ) {
            const timeFrom = this.modelForm.date?.length
              ? this.moment(this.modelForm.date[0])
                  .set({ hour: 0, minute: 0, second: 0 })
                  .utcOffset("+07")
                  .format()
              : "";
            const timeTo = this.modelForm.date?.length
              ? this.moment(this.modelForm.date[1])
                  .set({ hour: 23, minute: 59, second: 59 })
                  .utcOffset("+07")
                  .format()
              : "";
            const searchDate = `date>=${timeFrom}_AND_date<=${timeTo}`;
            if (params.search) {
              params.search += `_AND_${searchDate}`;
            } else {
              params.search = `${searchDate}`;
            }
          }
          this.loadingTable = true;
          logisticServices
            .getStockAdjustment(params)
            .then(data => {
              this.dataSource = data.data.map<Row>(item => ({
                ...item,
                key: item.id,
              }));
              this.totalElements = data.totalElements;
            })
            .finally(() => (this.loadingTable = false));
        });
      } catch (error) {
        this.$message.error(Messages.CREATE_FAIL);
      }
    },
    handleBranch(value) {
      this.branchId = value;
      this.form.resetFields(["warehouse", "rack"]);
      this.getListWarehouse("", this.branchId);
    },
    handleChangeWarehouse(id) {
      this.idWarehouse = id;
      this.form.resetFields(["rack"]);
      this.getListLocationRack("", id);
    },
    handleStockAdjustment(value) {
      this.stockAdjustmentId = value;
    },
    getStockAdjustment(search) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };
      if (search) params.search = `documentNumber~*${search}*`;

      this.loadingNo = true;
      logisticServices
        .getStockAdjustment(params)
        .then(data => {
          data.data.forEach((dataMap, index) => {
            dataMap.key = index;
          });
          this.dataStockAdjustment = data.data;
        })
        .finally(() => (this.loadingNo = false));
    },
    getBranch(valueSearch) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };
      if (valueSearch)
        params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
      this.loadingBranch = true;
      logisticServices
        .listWarehouseBranch(params, "")
        .then(response => {
          this.dataBranch = response.data;
        })
        .finally(() => (this.loadingBranch = false));
    },
    getListWarehouse(valueSearch, id) {
      if (id) {
        let params = {
          page: 0,
          limit: 10,
          search: `branchWarehouse.secureId~${id}`,
        } as RequestQueryParamsModel;
        if (valueSearch) params.search += `_AND_name~*${valueSearch}*`;
        this.loadingLocationWarehouse = true;
        logisticServices
          .listWarehouse(params, "")
          .then(data => {
            this.dataListLocationWarehouse = data.data;
          })
          .finally(() => (this.loadingLocationWarehouse = false));
      } else {
        this.$notification.error({
          message: "Error",
          description: "Choose Location Branch first to get list warehouse",
        });
      }
    },
    getListLocationRack(valueSearch, id) {
      if (id) {
        let params = {
          page: 0,
          limit: 10,
          search: `warehouse.secureId~${id}`,
        } as RequestQueryParamsModel;
        if (valueSearch) params.search += `_AND_name~*${valueSearch}*`;
        this.loadingLocationRack = true;
        logisticServices
          .listWarehouseLocation(params, "")
          .then(data => {
            this.dataListLocationRack = data.data;
          })
          .finally(() => (this.loadingLocationRack = false));
      } else {
        this.$notification.error({
          message: "Error",
          description: "Choose Location Warehouse first to get list rack",
        });
      }
    },
    onChangeTable(pagination: {
      total: number;
      current: number;
      pageSize: number;
    }): void {
      this.page = pagination.current;
      if (this.limit !== pagination.pageSize) {
        this.page = 1;
      }

      this.limit = pagination.pageSize;
      this.onSubmit();
    },
    allowEdit(status: LogisticStockAdjustmentState): boolean {
      return status === LogisticStockAdjustmentState.DRAFT;
    },
  },
});
