import { useProduct } from "@/hooks";
import { ProductTypeEnum } from "@/models/enums/ProductType.enum";
import {
  ProductAccountDto,
  ProductCreateRequestDto,
  ProductDetailDto,
} from "@/models/interface/master-product";
import { FormValue } from "@/store/product.store";
import { LabelInValue } from "@/types";

export type ProductAccountSelectType = {
  salesTax: LabelInValue | undefined;
  purchaseTax: LabelInValue | undefined;
  costOfSalesAccount: LabelInValue | undefined;
  inventoryAccount: LabelInValue | undefined;
  expensePurchaseAccount: LabelInValue | undefined;
  purchaseReturnAccount: LabelInValue | undefined;
  purchaseDiscountAccount: LabelInValue | undefined;
  unbilledAccount: LabelInValue | undefined;
  salesAccount: LabelInValue | undefined;
  salesReturnAccount: LabelInValue | undefined;
  salesDiscountAccount: LabelInValue | undefined;
  assetAccumulationAccount: LabelInValue | undefined;
  assetClearingAccount: LabelInValue | undefined;
  assetCostAccount: LabelInValue | undefined;
  assetDepreciationAccount: LabelInValue | undefined;
};

export const initProductAccountDto = (): ProductAccountDto => ({
  salesTaxId: "",
  purchaseTaxId: "",
  costOfSalesAccountId: "",
  inventoryAccountId: "",
  expensePurchaseAccountId: "",
  purchaseReturnAccountId: "",
  purchaseDiscountAccountId: "",
  unbilledAccountId: "",
  salesAccountId: "",
  salesReturnAccountId: "",
  salesDiscountAccountId: "",
});

export const initProductAccountSelect = (): ProductAccountSelectType => ({
  salesTax: undefined,
  purchaseTax: undefined,
  costOfSalesAccount: undefined,
  inventoryAccount: undefined,
  expensePurchaseAccount: undefined,
  purchaseReturnAccount: undefined,
  purchaseDiscountAccount: undefined,
  unbilledAccount: undefined,
  salesAccount: undefined,
  salesReturnAccount: undefined,
  salesDiscountAccount: undefined,
  assetAccumulationAccount: undefined,
  assetClearingAccount: undefined,
  assetCostAccount: undefined,
  assetDepreciationAccount: undefined,
});

export const initReqCreateProductDto = (): ProductCreateRequestDto => ({
  active: true,
  assetAccumulationAccountId: "",
  assetClearingAccountId: "",
  assetCostAccountId: "",
  assetDepreciationAccountId: "",
  barcode: "",
  baseUnit: "",
  code: "",
  description: "",
  image: "",
  merk: "",
  minimumStock: 0,
  multiPrices: [],
  name: "",
  productAccount: initProductAccountDto(),
  productCategoryId: "",
  productSuppliers: [],
  trackAsAsset: false,
  trackAsInventory: true,
  trackAsPurchase: true,
  trackAsSell: true,
  type: "" as ProductTypeEnum,
  uomConversionDTOs: [],
  locationReceiveId: "",
  serviceCode: "000000"
});

export const initFormProduct = (): FormValue => ({
  active: true,
  barcode: "",
  baseUnit: "",
  code: "",
  description: "",
  image: "",
  merk: "",
  minimumStock: 0,
  multiPrices: [],
  name: "",
  productAccount: initProductAccountSelect(),
  productCategoryId: "",
  trackAsAsset: false,
  trackAsInventory: true,
  trackAsPurchase: true,
  trackAsSell: true,
  type: "" as ProductTypeEnum,
  categoryName: "",
  productSuppliers: [],
  uomConversions: [],
  locationReceive: undefined,
  serviceCode: "000000"
});

export const initProductDetailDto = (): ProductDetailDto => {
  const { initProductDetailDto } = useProduct();
  return initProductDetailDto();
};
