

































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DisplayTotal extends Vue {
  @Prop({ required: false, default: 0, type: Number })
  subTotal!: number;

  @Prop({ required: false, default: 0, type: Number })
  baseAmount!: number;

  @Prop({ required: false, default: 0, type: Number })
  totalTax!: number;

  @Prop({ required: false, default: 0, type: Number })
  total!: number;
}
