import {
  initDetail,
  initFormValue,
  initStockAdjRow,
} from "@/builder/stock-adjustment/StockAdjustmentStateBuilder";
import { useRemoveRows } from "@/hooks/table";
import { useStockAdjustment } from "@/hooks/useStockAdjustment";
import { StockAdjustmentMapper } from "@/mapper/StockAdjustment.mapper";
import {
  LogisticStockAdjustmentState,
  LogisticStockAdjustmentType,
} from "@/models/enums/stock-adjustment.enum";
import {
  FormValue,
  State,
  StockAdjRow,
  StockAdjustmentResponseDTO,
} from "@/models/interface/stock-adjustment";

const state: State = {
  form: initFormValue(),
  detail: initDetail(),
};

const mutations = {
  setForm: (st: State, payload: Partial<FormValue>): void => {
    const copy = { ...st.form };
    st.form = {
      ...copy,
      ...payload,
    };
  },
  setDetail: (st: State, payload: StockAdjustmentResponseDTO): void => {
    st.detail = payload;
  },
};

const getters = {
  getForm: (st: State): FormValue => st.form,
  getDetail: (st: State): StockAdjustmentResponseDTO => st.detail,
  isOpname: (st: State): boolean => {
    return st.form.type === LogisticStockAdjustmentType.STOCK_OPNAME;
  },
  isAdjustment: (st: State): boolean => {
    return st.form.type === LogisticStockAdjustmentType.STOCK_ADJUSTMENT;
  },
  allowUpdate: (st: State): boolean => {
    return st.form.state === LogisticStockAdjustmentState.DRAFT;
  },
};

const actions = {
  resetStore: (context): void => {
    const { commit } = context;
    const form: FormValue = initFormValue();
    const detail: StockAdjustmentResponseDTO = initDetail();
    commit("setForm", form);
    commit("setDetail", detail);
  },
  addStockAdjRow: (context): void => {
    const { state } = context;
    const local: State = state;
    const row: StockAdjRow = initStockAdjRow();
    local.form.stockAdjustmentRows.push(row);
  },
  deleteStockAdjRow: (context, rowKeys: Array<string>): void => {
    const { state, commit } = context;
    const local: State = state;
    const { newSource, deletedRows } = useRemoveRows(
      local.form.stockAdjustmentRows,
      rowKeys
    );
    const deletedIds: Array<string> = deletedRows
      .filter(e => !!e.id)
      .map<string>(e => e.id as string);
    const form: Partial<FormValue> = {
      stockAdjustmentRows: newSource,
      deletedLineIds: deletedIds,
    };
    commit("setForm", form);
  },
  mapDetailToForm: (context, payload: StockAdjustmentResponseDTO): void => {
    const { commit } = context;
    const form: FormValue = StockAdjustmentMapper.mapDetailToForm(payload);
    commit("setForm", form);
    commit("setDetail", payload);
  },
  getDetailDoc: (context, id: string): void => {
    const { dispatch } = context;
    const { findById } = useStockAdjustment();
    findById(id).then((res: StockAdjustmentResponseDTO) => {
      dispatch("mapDetailToForm", res);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
