import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import Decimal from "decimal.js-light";
import useCalculator from "./useCalculator";

const useAccountingCalculation = () => {
  const calculateGrossValue = ({
    price,
    qty,
  }: {
    price: number;
    qty: number;
  }): number => {
    const { toRoundHalfUp } = useCalculator();
    const result = new Decimal(price).times(qty);
    return toRoundHalfUp(result).toNumber();
  };

  const calculateGrossAfterDiscount = ({
    grossValue,
    discountValue,
  }: {
    grossValue: number;
    discountValue: number;
  }): number => {
    const { toRoundHalfUp } = useCalculator();
    const result = new Decimal(grossValue).minus(discountValue);
    return toRoundHalfUp(result).toNumber();
  };

  const calculateGrossAfterDiscountBeforeTax = ({
    grossAfterDiscount,
    purchaseInclusiveRate = 0,
    taxType,
  }: {
    taxType: "Inclusive" | "Exclusive";
    grossAfterDiscount: number;
    purchaseInclusiveRate: number | undefined;
  }): number => {
    const { toRoundHalfUp } = useCalculator();

    if (taxType === TAX_CALCULATION.INCLUSIVE) {
      const divider = new Decimal(purchaseInclusiveRate).div(100).plus(1);
      const result = new Decimal(grossAfterDiscount).div(divider);
      return toRoundHalfUp(result).toNumber();
    }

    return grossAfterDiscount;
  };

  const calculateTaxBase = ({
    grossAfterDiscountBeforeTax,
    variable = 1,
  }: {
    grossAfterDiscountBeforeTax: number;
    variable?: number;
  }): number => {
    const { toRoundHalfUp } = useCalculator();
    const result = new Decimal(grossAfterDiscountBeforeTax).times(
      new Decimal(variable)
    );
    return toRoundHalfUp(result).toNumber();
  };

  const calculateTaxValue = ({
    taxBase,
    taxRate,
  }: {
    taxBase: number;
    taxRate: number;
  }): number => {
    const { toRoundHalfUp } = useCalculator();
    const result = new Decimal(taxBase).times(new Decimal(taxRate).div(100));
    return toRoundHalfUp(result).toNumber();
  };

  const calculateTotal = ({
    grossAfterDiscountBeforeTax,
    taxValue,
  }: {
    grossAfterDiscountBeforeTax: number;
    taxValue: number;
  }): number => {
    const { toRoundHalfUp } = useCalculator();
    const result = new Decimal(grossAfterDiscountBeforeTax).plus(taxValue);
    return toRoundHalfUp(result).toNumber();
  };

  const calculateLineRatio = ({
    lineValue,
    total,
  }: {
    lineValue: number;
    total: number;
  }): number => {
    const { toRoundHalfUp } = useCalculator();
    if (total === 0) return 0;

    const result = new Decimal(lineValue).div(total);
    return toRoundHalfUp(result).toNumber();
  };

  const calculateDiscountValueFromRatio = ({
    discountValue,
    ratio,
  }: {
    discountValue: number;
    ratio: number;
  }): number => {
    const { toRoundHalfUp } = useCalculator();
    const result = new Decimal(discountValue).times(ratio);
    return toRoundHalfUp(result).toNumber();
  };

  const calculateTotalWithAdditionalValue = ({
    subTotal,
    additionalValue,
  }: {
    subTotal: number;
    additionalValue: number;
  }): number => {
    const { toRoundHalfUp } = useCalculator();
    const result = new Decimal(subTotal).plus(additionalValue);
    return toRoundHalfUp(result).toNumber();
  };

  const calculateTaxWithCheat = ({
    grossAfterDiscount,
    grossAfterDiscountBeforeTax,
  }: {
    grossAfterDiscount: number;
    grossAfterDiscountBeforeTax: number;
  }): number => {
    const { toRoundHalfUp } = useCalculator();
    const result = new Decimal(grossAfterDiscount).minus(
      grossAfterDiscountBeforeTax
    );

    return toRoundHalfUp(result).toNumber();
  };

  return {
    calculateGrossValue,
    calculateGrossAfterDiscount,
    calculateGrossAfterDiscountBeforeTax,
    calculateTaxBase,
    calculateTaxValue,
    calculateTotal,
    calculateLineRatio,
    calculateDiscountValueFromRatio,
    calculateTotalWithAdditionalValue,
    calculateTaxWithCheat
  };
};

export default useAccountingCalculation;
