

































































































































































































import { SearchBuilder } from "@/builder";
import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import quantityFilter from "@/filters/quantity.filter";
import { debounceProcess } from "@/helpers/debounce";
import {
  APagination,
  useContactData,
  useDate,
  useFindMasterType,
} from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import SALES_ORDER_STATUS from "@/models/enums/sales-order.enum";
import {
  ContactData,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { SalesOrderResponseDto } from "@/models/interface/sales-order";
import { SalesOrderData } from "@/models/interface/salesOrder.interface";
import {
  TruckingSalesOrderListFilter,
  TruckingSalesOrderResponseDto,
} from "@/models/interface/trucking-sales-order";
import { truckingSalesOrderSevice } from "@/services/trucking-sales-order.service";
import { FormModel } from "ant-design-vue";
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class TruckingSalesOrder extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  formModel!: FormModel;

  defaultFilters: TruckingSalesOrderListFilter = {
    date: [],
    salesType: {
      key: "Trucking",
      label: "Trucking",
    },
    customer: undefined,
    salesOrderNumber: undefined,
    status: undefined,
    piNumber: "",
  };

  filters: TruckingSalesOrderListFilter = { ...this.defaultFilters };

  loading = {
    salesOrder: false,
    customer: false,
    status: false,
    find: false,
  };

  pagination = {
    page: +this.$route?.query.p || DEFAULT_PAGE,
    limit: +this.$route?.query.l || DEFAULT_PAGE_SIZE,
    reset(): void {
      this.page = DEFAULT_PAGE;
    },
  };

  dataListSalesOrder: Option<SalesOrderData>[] = [];
  dataListCustomer: Option<ContactData>[] = [];
  dataListSalesType: Option<ResponseListMaster>[] = [];
  dataListStatus: Option<ResponseListMaster>[] = [];

  salesOrders: Pagination<TruckingSalesOrderResponseDto> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  salesOrderTableColumns = [
    {
      title: this.$t("lbl_sales_order_no"),
      dataIndex: "documentNumber",
      key: "documentNumber",
      width: 150,
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "customerName",
      width: 150,
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_sales_order_date"),
      dataIndex: "date",
      key: "date",
      width: 120,
      customRender: text => dateFormat(text),
    },
    {
      title: this.$t("lbl_currency"),
      dataIndex: "currency",
      key: "currency",
      width: 100,
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchWarehouse",
      key: "branch",
      width: 200,
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "states",
      key: "states",
      width: 100,
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_action"),
      key: "action",
      width: 100,
      scopedSlots: { customRender: "action" },
    },
  ];

  nestedTableColumns = [
    {
      title: this.$t("lbl_document_reference"),
      dataIndex: "documentReference",
      width: 200,
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_part_number_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
      width: 200,
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 150,
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "qty",
      key: "qty",
      width: 100,
      customRender: text => quantityFilter(text),
    },
    {
      title: this.$t("lbl_price"),
      dataIndex: "price",
      key: "price",
      width: 200,
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_vat"),
      dataIndex: "taxValue",
      key: "taxValue",
      width: 200,
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_discount"),
      dataIndex: "discount",
      key: "discount",
      width: 100,
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_sub_total"),
      dataIndex: "subTotal",
      key: "subTotal",
      width: 200,
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      width: 200,
      customRender: text => text || "-",
    },
  ];

  getListSalesOrderHeaderOnly(valueSearch: string): void {
    const builder = new SearchBuilder();
    const SO_DRAFT = "Draft";
    const criteria: string = builder
      .push(["state", SO_DRAFT], { not: true })
      .build();
    const queries: string[] = [criteria];

    if (valueSearch) {
      queries.push(
        builder
          .push(["documentNumber", valueSearch], {
            like: "both",
          })
          .build()
      );
    }

    const params: RequestQueryParamsModel = new RequestQueryParams();
    params.search = queries.join(SearchBuilder.AND);

    this.loading.salesOrder = true;
    truckingSalesOrderSevice
      .getListSalesOrder(params)
      .then(data => {
        this.dataListSalesOrder = data.data.map(salesOrder => ({
          key: salesOrder.id,
          label: salesOrder.salesOrderNo,
          value: salesOrder.id,
          meta: salesOrder,
        }));
      })
      .finally(() => (this.loading.salesOrder = false));
  }

  getCustomer(value: string): void {
    const { findCustomers, filterBy } = useContactData();

    const params: RequestQueryParamsModel = new RequestQueryParams();
    params.search = filterBy({ firstName: value, lastName: value });

    this.loading.customer = true;
    findCustomers(params)
      .then(data => {
        this.dataListCustomer = data.data.map(customer => ({
          key: customer.id,
          label: customer.fullName,
          value: customer.id,
          meta: customer,
        }));
      })
      .finally(() => (this.loading.customer = false));
  }

  getStatus(): void {
    this.loading.status = true;
    useFindMasterType("SALES_STATE")
      .then(status => {
        this.dataListStatus = status.map(type => ({
          key: type.id,
          label: type.value,
          value: type.value,
          meta: type,
        }));
      })
      .finally(() => (this.loading.status = false));
  }

  buildQueryParams(filter: TruckingSalesOrderListFilter): RequestQueryParams {
    const { toStartDay, toEndDay } = useDate();

    const queries: string[] = [];

    const builder = new SearchBuilder();
    if (filter.salesOrderNumber && filter.salesOrderNumber.label) {
      const query = new SearchBuilder()
        .push(["documentNumber", filter.salesOrderNumber.label])
        .build();
      queries.push(query);
    }

    if (filter.customer && filter.customer.key) {
      const query = new SearchBuilder()
        .push(["customer.secureId", filter.customer.key])
        .build();
      queries.push(query);
    }

    if (filter.status && filter.status.label) {
      const query = new SearchBuilder()
        .push(["state", filter.status.label])
        .build();
      queries.push(query);
    }

    if (filter.date && filter.date.length > 0) {
      const [start, end] = filter.date;

      const startDay = toStartDay(start).format();
      const endDay = toEndDay(end).format();

      const startQuery = new SearchBuilder()
        .push(["date", startDay], { het: true })
        .build();
      queries.push(startQuery);

      const endQuery = new SearchBuilder()
        .push(["date", endDay], { let: true })
        .build();
      queries.push(endQuery);
    }

    if (filter.piNumber) {
      const query = new SearchBuilder()
        .push(["documentReference", filter.piNumber], { like: "both" })
        .build();
      queries.push(query);
    }

    const params: RequestQueryParams = {
      page: this.pagination.page - 1,
      limit: this.pagination.limit,
      sorts: "createdDate:desc",
      search: queries.join(builder.AND),
    };

    return params;
  }

  fetchTruckingSalesOrders(params: RequestQueryParamsModel): void {
    this.loading.find = true;

    truckingSalesOrderSevice
      .getListSalesOrderHeaders(params)
      .then(data => {
        this.salesOrders = data;
      })
      .finally(() => (this.loading.find = false));
  }

  handleFind(): void {
    this.pagination.reset();
    const params = this.buildQueryParams(this.filters);

    this.fetchTruckingSalesOrders(params);
  }

  handleReset(): void {
    this.filters = { ...this.defaultFilters };
  }

  handleView(salesOrder: SalesOrderResponseDto): void {
    const status: SALES_ORDER_STATUS = salesOrder.states as SALES_ORDER_STATUS;
    const toEdit: boolean =
      status === SALES_ORDER_STATUS.DRAFT ||
      status === SALES_ORDER_STATUS.SUBMITTED;

    const hasEditPermission = this.$ability.can(
      "update",
      "trucking-sales-order"
    );

    if (toEdit && hasEditPermission) {
      this.$router.push({
        name: "trucking.sales-order.edit",
        params: {
          id: salesOrder.id,
        },
      });
    } else {
      this.$router.push({
        name: "trucking.sales-order.detail",
        params: {
          id: salesOrder.id,
        },
      });
    }
  }

  updateQueryFilter(): void {
    this.$router.replace({
      name: "trucking.sales-order",
      query: {
        p: this.pagination.page.toString(),
        l: this.pagination.limit.toString(),
      },
    });
  }

  onChangeTable(pagination: APagination): void {
    const { current, pageSize } = pagination;

    this.pagination.page = current;
    if (pageSize !== this.pagination.limit) {
      this.pagination.page = DEFAULT_PAGE;
    }
    this.pagination.limit = pageSize;

    const params = this.buildQueryParams(this.filters);

    this.updateQueryFilter();
    this.fetchTruckingSalesOrders(params);
  }

  mounted(): void {
    this.getListSalesOrderHeaderOnly = debounceProcess(
      this.getListSalesOrderHeaderOnly,
      500
    );
    this.getCustomer = debounceProcess(this.getCustomer, 500);

    this.handleFind();
    this.getListSalesOrderHeaderOnly("");
    this.getCustomer("");
    this.getStatus();
  }
}
