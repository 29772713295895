

















































import SelectTaxCalculation from "@/components/custom/select/SelectTaxCalculation.vue";
import { Option } from "@/models/class/option.class";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { FormValue, State } from "@/store/aRPrepayment.store";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { AccountingTaxResponseDto } from "@interface/master-tax";
import { FormModel } from "ant-design-vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import { mapActions, mapMutations, mapState } from "vuex";
import SelectTaxCode from "./SelectTaxCode.vue";

@Component({
  components: {
    SelectTaxCode,
    SelectTaxCalculation,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.aRPrepaymentStore,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
  methods: {
    ...mapMutations({
      setForm: "aRPrepaymentStore/setForm",
    }),
    ...mapActions({
      calcPayment: "aRPrepaymentStore/calcPayment",
    }),
  },
})
export default class FormPayment extends Vue {
  reverseFormatNumber = reverseFormatNumber;
  formatterNumber = formatterNumber;

  @Ref("formModel")
  formModel!: FormModel;

  storeBaseDecimalPlace!: number;
  store!: State;
  setForm!: (payload: Partial<FormValue>) => void;
  calcPayment!: () => void;

  get formRules() {
    return {
      amount: [
        { required: true, message: this.$t("lbl_validation_required_error") },
      ],
      taxType: [
        { required: true, message: this.$t("lbl_validation_required_error") },
      ],
      taxCode: [
        { required: true, message: this.$t("lbl_validation_required_error") },
      ],
      inclusiveTax: {
        required: this.isInclusiveTax,
        message: this.$t("lbl_validation_required_error"),
      },
    };
  }

  get isInclusiveTax(): boolean {
    return this.store.form.taxType === TAX_CALCULATION.INCLUSIVE;
  }

  onChangeAmount(): void {
    this.calcPayment();
  }

  onChangeTaxCode(e?: Option<AccountingTaxResponseDto>): void {
    this.setForm({
      taxRate: e?.meta?.rate || 0,
      taxCode: e?.label || "",
    });
    this.calcPayment();
  }

  onChangeTaxCalc(): void {
    this.setForm({
      inclusiveTax: undefined,
      inclusiveTaxRate: 0,
    });
    this.calcPayment();
  }

  onChangeInclusiveTaxRate(val?: Option<AccountingTaxResponseDto>): void {
    this.setForm({
      inclusiveTaxRate: val?.meta?.rate || 0,
    });
    this.calcPayment();
  }
}
