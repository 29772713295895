





































import { Option } from "@/models/class/option.class";
import Vue from "vue";

// TODO: refactor to functional component

export default Vue.extend({
  name: "Select",
  props: {
    value: {
      required: true,
    },
    dropdownMatchSelectWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowClear: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    labelInValue: {
      type: Boolean,
      required: false,
    },
    showSearch: {
      type: Boolean,
      required: false,
    },
    filterOption: {
      required: false,
    },
    optionFilterProp: {
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    optionLabelProp: {
      type: String,
      required: false,
    },
    options: {
      type: Array as () => Array<Option>,
      required: true,
    },
  },
  methods: {
    onChange(e?: string): void {
      this.$emit("input", e);
      this.$emit("change", e);
    },
    onSearch(e?: string): void {
      this.$emit("search", e);
    },
    onScroll(e): void {
      this.$emit("popupScroll", e);
      const treshold = 30;
      const target = e.target;
      if (
        target.scrollTop + target.offsetHeight >=
        target.scrollHeight - treshold
      ) {
        this.emitReachBottom();
      }
    },
    emitReachBottom(): void {
      this.$emit("scrollEnd");
    },
  },
});
