import {
  SupplierReturnLineRequestDto,
  SupplierReturnRequestDto,
} from "@/models/interface/supplier-return";

export const initLineDTO = (): SupplierReturnLineRequestDto => {
  return {
    grNumber: "",
    invoiceNumber: "",
    apLineId: "",
    grLineId: "",
    productId: "",
    uom: "",
    uomId: "",
    qty: 0,
    price: 0,
    qtyReturn: 0,
    totalPrice: 0,
    locationDataList: [],
  };
};

export const initDTO = (): SupplierReturnRequestDto => {
  return {
    supplierId: "",
    grNumber: "",
    poNumber: "",
    invoiceNumber: "",
    branchId: "",
    grDate: "",
    invoiceDate: "",
    returnDate: "",
    currency: "",
    rates: 1,
    returnFrom: "",
    refundOptions: "NONE",
    notes: "",
    lineRequestDTO: [],
  };
};
