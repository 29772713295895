







































































































































































































































































































































































































import DisplayBoolean from "@/components/DisplayBoolean/DisplayBoolean.vue";
import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import { toTitlecase } from "@/helpers/common";
import { AmortizationMapper } from "@/mapper/Amortization.mapper";
import MNotification from "@/mixins/MNotification.vue";
import { DATE_FORMAT_MMM_YYYY } from "@/models/constants/date.constant";
import { AmortizationStatusEnum } from "@/models/enums/amortization.enum";
import {
  AmortizationDetailResponseDto,
  AmortizationRequestDto,
} from "@/models/interface/amortization";
import { amortizationService } from "@/services/amortization.service";
import { StringUtils } from "@/utils/StringUtils";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
  name: "AmortizationDetailPage",
  components: {
    DisplayBoolean,
  },
})
export default class DetailPage extends Mixins(MNotification) {
  @Prop({ required: false, type: String })
  readonly id!: string;

  toTitlecase = toTitlecase;

  loading = false;
  loadingCancel = false;
  loadingCreate = false;
  dtDetail: AmortizationDetailResponseDto = new AmortizationDetailResponseDto();

  currentUnitPage = 1;

  unitColumns = [
    {
      title: this.$t("lbl_year"),
      dataIndex: "year",
      width: 70,
    },
    {
      title: this.$t("lbl_start_period"),
      dataIndex: "startPeriod",
      width: 150,
      customRender: text => dateFormat(text, DATE_FORMAT_MMM_YYYY),
    },
    {
      title: this.$t("lbl_end_period"),
      dataIndex: "endPeriod",
      width: 150,
      customRender: text => dateFormat(text, DATE_FORMAT_MMM_YYYY),
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "amount",
      align: "right",
      customRender: text => currencyFilter(text),
    },
  ];

  journalColumns = [
    {
      title: this.$t("lbl_journal_number"),
      dataIndex: "journalNumber",
      scopedSlots: { customRender: "journal" },
    },
    {
      title: this.$t("lbl_period"),
      dataIndex: "period",
    },
    {
      title: this.$t("common.amount-text", {
        text: this.$t("lbl_amortization"),
      }),
      dataIndex: "amount",
      align: "right",
      customRender: text => currencyFilter(text),
    },
  ];

  created(): void {
    if (this.id) {
      this.getDetail(this.id);
    }
  }

  get allowCreate(): boolean {
    if (!this.dtDetail.status) return false;
    return StringUtils.compare(this.dtDetail.status, "Draft");
  }

  get useCashout(): boolean {
    return !!this.dtDetail.bankId;
  }

  get allowCancel(): boolean {
    if (!this.dtDetail.status) return false;
    return StringUtils.compare(this.dtDetail.status, "Draft");
  }

  get allowEdit(): boolean {
    const emptyUnit: boolean =
      this.dtDetail.units.findIndex(item => !item.assetId) !== -1;
    return (
      !this.dtDetail.leasingId ||
      emptyUnit ||
      StringUtils.compare(this.dtDetail.status, "Draft")
    );
  }

  get allowClose(): boolean {
    const status: AmortizationStatusEnum[] = ["Open", "Partial Closed"];
    if (!this.dtDetail.status) return false;
    return status.some(val => StringUtils.compare(this.dtDetail.status, val));
  }

  isClosed(status: string): boolean {
    return StringUtils.compare(status, "Closed");
  }

  getDetail(amortizationId: string): void {
    this.loading = true;
    amortizationService
      .getAmortizationDetail(amortizationId)
      .then(response => {
        this.dtDetail = {
          ...response,
          units: response.units.map(item => ({
            ...item,
            periods: item.periods.map((period, i) => ({
              ...period,
              year: i + 1,
            })),
          })),
        };
      })
      .finally(() => {
        this.loading = false;
      });
  }

  handleBack(): void {
    this.$router.push({ name: "amortizations" });
  }

  handleCancel(): void {
    this.showConfirmationCancel(() => {
      this.loadingCancel = true;
      amortizationService
        .cancelAmortization(this.id)
        .then(({ amortizationNumber }) => {
          this.showNotifSuccess("notif_cancel_success", {
            documentNumber: amortizationNumber,
          });
          this.handleBack();
        })
        .finally(() => {
          this.loadingCancel = false;
        });
    });
  }

  handleCreate(): void {
    const dto: AmortizationRequestDto =
      AmortizationMapper.toAmortizationRequestDto(this.dtDetail);
    this.loadingCreate = true;
    amortizationService
      .submitAmortization(dto)
      .then(({ amortizationNumber }) => {
        this.showNotifSuccess("notif_create_success", {
          documentNumber: amortizationNumber,
        });
        this.handleBack();
      })
      .finally(() => {
        this.loadingCreate = false;
      });
  }
}
