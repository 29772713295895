var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: {
        title: _vm.$t("lbl_receive_warehouse_transfer"),
        loading: _vm.loading.getDetail
      }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-form-model",
                {
                  ref: "formModel",
                  attrs: {
                    layout: "horizontal",
                    "label-align": "left",
                    model: _vm.formState,
                    rules: _vm.formRules,
                    "label-col": { sm: { span: 24 }, lg: { span: 5 } },
                    "wrapper-col": { sm: { span: 24 }, lg: { span: 19 } },
                    "data-testid": "receive-warehouse-transfer-form"
                  }
                },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_document_number"),
                        "data-testid":
                          "receive-warehouse-transfer-document-number"
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.warehouseTransfer.documentNumber) + " "
                      )
                    ]
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_from"),
                        "data-testid": "receive-warehouse-transfer-from"
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.warehouseTransfer.sourceLocation) + " "
                      )
                    ]
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_to"),
                        "data-testid": "receive-warehouse-transfer-to"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.warehouseTransfer.destinationLocation) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_date"),
                        "data-testid": "receive-warehouse-transfer-date"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.dateFormat(_vm.warehouseTransfer.date)) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_receive_date"),
                        prop: "receiveDate",
                        "data-testid": "receive-warehouse-transfer-receive-date"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        staticClass: "w-100",
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          "disabled-date": _vm.useDisabledFromTomorrow
                        },
                        model: {
                          value: _vm.formState.receiveDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "receiveDate", $$v)
                          },
                          expression: "formState.receiveDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_note"),
                        prop: "note",
                        "data-testid": "receive-warehouse-transfer-note"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "extra",
                          fn: function() {
                            return [
                              _c("character-length", {
                                attrs: { value: _vm.formState.note }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("a-textarea", {
                        attrs: {
                          autocomplete: "off",
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_note")
                          })
                        },
                        model: {
                          value: _vm.formState.note,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "note", $$v)
                          },
                          expression: "formState.note"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_attachment"),
                        "data-testid": "receive-warehouse-transfer-attachment"
                      }
                    },
                    [
                      !!_vm.warehouseTransfer.attachment
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.warehouseTransfer.attachment,
                                rel: "noopener noreferrer",
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.warehouseTransfer.attachment) +
                                  " "
                              )
                            ]
                          )
                        : _c("p", [_vm._v("-")])
                    ]
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_status"),
                        "data-testid": "receive-warehouse-transfer-status"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.toTitlecase(_vm.warehouseTransfer.state)) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-table", {
                attrs: {
                  size: "small",
                  "row-key": "rowId",
                  columns: _vm.columns,
                  "data-source": _vm.formState.receiveWarehouseTransferLines,
                  pagination: {
                    showTotal: function(total) {
                      return _vm.$t("lbl_total_items", { total: total })
                    },
                    showSizeChanger: true
                  },
                  "row-class-name": function(_record, index) {
                    return index % 2 ? "bg-white" : "bg-gray-light"
                  },
                  scroll: { x: 1000 },
                  "data-testid": "receive-warehouse-transfer-table"
                },
                scopedSlots: _vm._u([
                  {
                    key: "qtyReceive",
                    fn: function(_, row) {
                      return [
                        _c("a-input-number", {
                          staticStyle: { width: "100%" },
                          model: {
                            value: row.qtyReceive,
                            callback: function($$v) {
                              _vm.$set(row, "qtyReceive", $$v)
                            },
                            expression: "row.qtyReceive"
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        "data-testid": "receive-warehouse-transfer-btn-back"
                      },
                      on: { click: _vm.handleBack }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_back")))]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.loading.print,
                        "data-testid": "receive-warehouse-transfer-btn-print"
                      },
                      on: { click: _vm.handlePrint }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_print")))]
                  ),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: _vm.$t("pop_confirmation"),
                        "ok-text": _vm.$t("lbl_yes"),
                        "cancel-text": _vm.$t("lbl_no"),
                        "data-testid": "receive-warehouse-transfer-btn-cancel"
                      },
                      on: { confirm: _vm.handleCancel }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "danger", loading: _vm.loading.cancel }
                        },
                        [_vm._v(_vm._s(_vm.$t("lbl_cancel")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "data-testid": "receive-warehouse-transfer-btn-receive"
                      },
                      on: { click: _vm.handleReceive }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_receive")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }