import { buildDetailDto } from "@/builder/stock-adjustment/StockAdjustmentDtoBuilder";
import { generateUUID } from "@/helpers/uuid";
import {
  LogisticStockAdjustmentState,
  LogisticStockAdjustmentType,
} from "@/models/enums/stock-adjustment.enum";
import {
  FormValue,
  State,
  StockAdjRow,
  StockAdjustmentResponseDTO,
} from "@/models/interface/stock-adjustment";
import moment from "moment";

export const initFormValue = (): FormValue => ({
  attachment: "",
  brand: "",
  date: moment(),
  deletedLineIds: [],
  documentNumber: "",
  notes: "",
  productCategoryId: "",
  state: "" as LogisticStockAdjustmentState,
  stockAdjustmentRows: [],
  type: LogisticStockAdjustmentType.STOCK_ADJUSTMENT,
  warehouseLocation: undefined,
});

export const initState = (): State => ({
  form: initFormValue(),
  detail: initDetail(),
});

export const initDetail = (): StockAdjustmentResponseDTO => buildDetailDto();

export const initStockAdjRow = (): StockAdjRow => ({
  differenceQty: 0,
  key: generateUUID(),
  physicalQty: 0,
  productCode: undefined,
  productMerk: "",
  productName: undefined,
  qty: 0,
  id: "",
  uom: undefined,
  uomOptions: [],
  type: "increment",
  maxQty: undefined,
  minQty: undefined,
  loadingSerialNumber: false,
  serialNumber: null,
  serialNumberOptions: [],
});
