var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_amortization_transaction_report") } },
    [
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: {
            model: _vm.form,
            layout: "horizontal",
            "wrapper-col": { span: 12 },
            "label-col": { span: 8 },
            "label-align": "left",
            "data-testid": "amortization-transaction-report-form"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { flex: "1" } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_amortization_number"),
                        prop: "amortization"
                      }
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          options: _vm.amortizationNumberOptions,
                          "allow-clear": "",
                          "show-search": "",
                          "filter-option": false,
                          "label-in-value": "",
                          loading: _vm.loading.amortization,
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_amortization_number")
                          }),
                          "data-testid":
                            "amortization-transaction-report-amortization"
                        },
                        on: { search: _vm.onSearchAmortization },
                        model: {
                          value: _vm.form.amortization,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "amortization", $$v)
                          },
                          expression: "form.amortization"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_transaction_type"),
                        prop: "transactionType"
                      }
                    },
                    [
                      _c("SelectAmortizationTransactionType", {
                        attrs: {
                          mode: "multiple",
                          "data-testid":
                            "amortization-transaction-report-transaction-type"
                        },
                        model: {
                          value: _vm.form.transactionType,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "transactionType", $$v)
                          },
                          expression: "form.transactionType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_transaction_date"),
                        prop: "transactionDate"
                      }
                    },
                    [
                      _c("a-range-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          placeholder: [
                            _vm.$t("lbl_start_date"),
                            _vm.$t("lbl_end_date")
                          ],
                          "data-testid":
                            "amortization-transaction-report-transaction-date"
                        },
                        model: {
                          value: _vm.form.transactionDate,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "transactionDate", $$v)
                          },
                          expression: "form.transactionDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_leasing_contract_number"),
                        prop: "leasingContractNumber"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_leasing_contract_number")
                          }),
                          "allow-clear": "",
                          "data-testid":
                            "amortization-transaction-report-leasing-contract-number"
                        },
                        model: {
                          value: _vm.form.leasingContractNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "leasingContractNumber", $$v)
                          },
                          expression: "form.leasingContractNumber"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { flex: "1" } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_cash_out_number"),
                        prop: "cashOutNumber"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_cash_out_number")
                          }),
                          "allow-clear": "",
                          "data-testid":
                            "amortization-transaction-report-cash-out-number"
                        },
                        model: {
                          value: _vm.form.cashOutNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "cashOutNumber", $$v)
                          },
                          expression: "form.cashOutNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_unit_code"),
                        prop: "unitCode"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_unit_code")
                          }),
                          "allow-clear": "",
                          "data-testid":
                            "amortization-transaction-report-unit-code"
                        },
                        model: {
                          value: _vm.form.unitCode,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "unitCode", $$v)
                          },
                          expression: "form.unitCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_serial_number"),
                        prop: "serialNumber"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_serial_number")
                          }),
                          "allow-clear": "",
                          "data-testid":
                            "amortization-transaction-report-serial-number"
                        },
                        model: {
                          value: _vm.form.serialNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "serialNumber", $$v)
                          },
                          expression: "form.serialNumber"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { flex: "1" } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_document_number"),
                        prop: "documentNumber"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_document_number")
                          }),
                          "allow-clear": "",
                          "data-testid":
                            "amortization-transaction-report-document-number"
                        },
                        model: {
                          value: _vm.form.documentNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "documentNumber", $$v)
                          },
                          expression: "form.documentNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_description"),
                        prop: "description"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_description")
                          }),
                          "allow-clear": "",
                          "data-testid":
                            "amortization-transaction-report-description"
                        },
                        model: {
                          value: _vm.form.description,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_status"), prop: "status" } },
                    [
                      _c("a-select", {
                        attrs: {
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_status")
                          }),
                          "allow-clear": "",
                          options: [
                            { label: _vm.$t("lbl_open"), value: "OPEN" },
                            { label: _vm.$t("lbl_closed"), value: "CLOSED" }
                          ],
                          "data-testid":
                            "amortization-transaction-report-status"
                        },
                        model: {
                          value: _vm.form.status,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "status", $$v)
                          },
                          expression: "form.status"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            "data-testid":
                              "amortization-transaction-report-reset"
                          },
                          on: { click: _vm.handleReset }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            "data-testid":
                              "amortization-transaction-report-find",
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.loading.submit
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-table", {
                staticClass: "mt-3",
                attrs: {
                  "data-source": _vm.dtReport.data,
                  columns: _vm.columns,
                  "row-key": function(r) {
                    return r.id
                  },
                  pagination: {
                    showTotal: function() {
                      return _vm.$t("lbl_total_items", {
                        total: _vm.dtReport.totalElements
                      })
                    },
                    total: _vm.dtReport.totalElements,
                    showSizeChanger: true,
                    pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                    current: _vm.pagination.page,
                    pageSize: _vm.pagination.limit
                  },
                  scroll: { x: "calc(1900px + 100%)" },
                  size: "small",
                  loading: _vm.loading.submit,
                  "row-class-name": function(_record, index) {
                    return index % 2 ? "bg-white" : "bg-gray-light"
                  },
                  "data-testid": "amortization-transaction-report"
                },
                on: { change: _vm.onChangeTable },
                scopedSlots: _vm._u([
                  {
                    key: "expandedRowRender",
                    fn: function(record) {
                      return [
                        _c(
                          "a-row",
                          { attrs: { type: "flex" } },
                          [
                            _c(
                              "a-col",
                              { attrs: { span: 5 } },
                              [
                                _c("a-table", {
                                  attrs: {
                                    size: "small",
                                    "data-source": record.periods,
                                    columns: _vm.detailColumns,
                                    "row-key": "year",
                                    "row-class-name": function(_record, index) {
                                      return index % 2
                                        ? "bg-white"
                                        : "bg-gray-light"
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "ellipsis",
                    fn: function(text) {
                      return [
                        _c(
                          "a-tooltip",
                          { attrs: { title: text, placement: "right" } },
                          [_c("span", [_vm._v(_vm._s(text))])]
                        )
                      ]
                    }
                  },
                  {
                    key: "cashOutNumber",
                    fn: function(text, record) {
                      return [
                        record.cashOutNumberSecureId
                          ? _c(
                              "RouterLink",
                              {
                                attrs: {
                                  to: {
                                    name: "cash-management.cash-in-out.edit",
                                    params: { id: record.cashOutNumberSecureId }
                                  },
                                  target: "_blank"
                                }
                              },
                              [_vm._v(" " + _vm._s(text) + " ")]
                            )
                          : _c("span", [_vm._v(_vm._s("-"))])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-statistic", {
                attrs: {
                  title: _vm.$t("lbl_grand_total"),
                  value: _vm.dtReport.grandTotal
                },
                scopedSlots: _vm._u([
                  {
                    key: "formatter",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _vm._v(" " + _vm._s(_vm._f("currency")(value)) + " ")
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.loading.download,
                    "data-testid": "amortization-transaction-report-download"
                  },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }