



































































































































import DisplayBoolean from "@/components/DisplayBoolean/DisplayBoolean.vue";
import MNotification from "@/mixins/MNotification.vue";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  buildTaxVariableFormState as buildFormState,
  TaxBaseVariableDto,
  TaxBaseVariableFormState as FormState,
} from "@/models/interface/tax-base-variable";
import { taxBaseVariableService } from "@/services/tax-base-variable.service";
import { ColumnDef } from "@/types";
import moment, { Moment } from "moment";
import { Component, Mixins } from "vue-property-decorator";

@Component({
  components: {
    DisplayBoolean,
  },
})
export default class TaxBaseVariableForm extends Mixins(MNotification) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  loading = false;
  originalState: FormState = buildFormState();
  dataSource: FormState[] = [];
  columns: ColumnDef[] = [
    {
      title: "#",
      dataIndex: "no",
      width: 70,
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      width: 150,
      scopedSlots: { customRender: "description" },
    },
    {
      title: this.$t("lbl_variable"),
      dataIndex: "variable",
      key: "variable",
      width: 150,
      scopedSlots: { customRender: "variable" },
    },
    {
      title: this.$t("lbl_start_effective_date"),
      dataIndex: "effectiveDate",
      key: "effectiveDate",
      width: 200,
      scopedSlots: { customRender: "effectiveDate" },
    },
    {
      title: this.$t("lbl_luxury_goods"),
      dataIndex: "isLuxuryGoods",
      key: "isLuxuryGoods",
      align: "center",
      width: 200,
      scopedSlots: { customRender: "isLuxuryGoods" },
    },
    {
      key: "action",
      width: 200,
      align: "right",
      scopedSlots: { customRender: "action" },
    },
  ];

  created() {
    this.getTaxBaseVariableList();
  }

  async getTaxBaseVariableList() {
    try {
      this.loading = true;
      const response = await taxBaseVariableService.findAll();
      this.dataSource = response.map((item, i) => {
        const state = buildFormState();
        state.description = item.description;
        state.id = item.id;
        state.no = i + 1;
        state.variable = item.value;
        state.effectiveDate = moment(item.effectiveDate);
        state.isLuxuryGoods = item.isLuxury;
        state.isRunning = state.effectiveDate.isBefore(moment().endOf("days"));
        return state;
      });
    } catch {
      this.dataSource = [];
    } finally {
      this.loading = false;
    }
  }

  editRecord(record: FormState): void {
    this.originalState = { ...record };
    record.isEdit = true;
  }

  closeEdit(record: FormState) {
    record.isEdit = false;
    record.variable = this.originalState.variable;
    record.effectiveDate = this.originalState.effectiveDate;
    record.isLuxuryGoods = this.originalState.isLuxuryGoods;
    record.description = this.originalState.description;
  }

  async handleSave(record: FormState): Promise<void> {
    try {
      const dto: TaxBaseVariableDto = {
        description: record.description,
        value: record.variable,
        effectiveDate: record.effectiveDate?.startOf("days").format() ?? "",
        isLuxury: record.isLuxuryGoods,
      };
      record.loadingCreate = true;
      if (record.id === null) {
        await taxBaseVariableService.create(dto);
      } else {
        await taxBaseVariableService.update(record.id, dto);
      }
      record.isEdit = false;
      record.isNew = false;
      this.showNotifSuccess(
        record.id ? "notif_update_success" : "notif_create_success"
      );
      this.getTaxBaseVariableList();
    } catch {
      this.showNotifError(
        record.id ? "notif_update_fail" : "notif_create_fail"
      );
    } finally {
      record.loadingCreate = false;
    }
  }

  async deleteVariable(record: FormState) {
    if (!record.id) return;
    try {
      record.loadingDelete = true;
      await taxBaseVariableService.deleteVariable(record.id);
      this.showNotifSuccess("notif_delete_success");
      this.getTaxBaseVariableList();
    } catch {
      this.showNotifError("notif_delete_failed");
    } finally {
      record.loadingDelete = false;
    }
  }

  async deleteRecord(record: FormState): Promise<void> {
    if (record.id) {
      await this.deleteVariable(record);
      return;
    }

    const notFound = -1;
    const index = this.dataSource.findIndex(item => item.key === record.key);
    if (index === notFound) return;

    this.dataSource.splice(index, 1);
    this.countNumber();
  }

  countNumber(): void {
    this.dataSource.forEach((item, i) => {
      item.no = i + 1;
    });
  }

  addRecord(): void {
    const record = buildFormState();
    record.isEdit = true;
    record.isNew = true;
    record.no = this.dataSource.length + 1;
    this.dataSource.push(record);
  }

  effectiveDateRules(current: Moment): boolean {
    return current.isBefore(moment().endOf("days"));
  }
}
