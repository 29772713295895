import { DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { SalesOrderLine } from "@/store/salesOrder.store";
import { Decimal } from "decimal.js-light";

export class SalesOrderUtils {
  static countPrice(arg: {
    row: SalesOrderLine;
    taxType: TAX_CALCULATION;
    salesInclusiveTaxRate: number;
    isLuxury: boolean;
    taxBaseVariable: number;
  }) {
    const isTaxExcl = arg.taxType === TAX_CALCULATION.EXCLUSIVE;
    const isTaxIncl = arg.taxType === TAX_CALCULATION.INCLUSIVE;

    const grossValue = new Decimal(arg.row.price || 0)
      .times(arg.row.qty || 0)
      .minus(arg.row.discountValue || 0)
      .toDecimalPlaces(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP);
    arg.row.grossValue = grossValue.toNumber();

    if (isTaxExcl) {
      const taxBase: number = new Decimal(arg.row.grossValue)
        .times(arg.taxBaseVariable)
        .toDecimalPlaces(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP)
        .toNumber();
      arg.row.taxableValue = taxBase;

      const taxRate = new Decimal(arg.row.taxRate || 0).dividedBy(100);
      const taxAmount = new Decimal(taxBase).times(taxRate);
      arg.row.taxValue = taxAmount
        .todp(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP)
        .toNumber();

      const subtotal = new Decimal(arg.row.grossValue).plus(taxAmount);
      arg.row.subTotal = subtotal
        .todp(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP)
        .toNumber();
    } else if (isTaxIncl && arg.isLuxury) {
      const selectedTaxRate = new Decimal(arg.row.taxRate || 0)
        .dividedBy(100)
        .plus(1);
      const beforeDpp = new Decimal(arg.row.grossValue)
        .dividedBy(selectedTaxRate)
        .todp(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP)
        .toNumber();
      arg.row.grossValue = beforeDpp;
      const taxBase = new Decimal(beforeDpp)
        .times(arg.taxBaseVariable)
        .toDecimalPlaces(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP)
        .toNumber();
      arg.row.taxableValue = taxBase;

      const gross = new Decimal(arg.row.price)
        .times(arg.row.qty)
        .minus(arg.row.discountValue);
      const taxAmount = gross.minus(beforeDpp);
      arg.row.taxValue = taxAmount
        .todp(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP)
        .toNumber();

      const subtotal = new Decimal(beforeDpp).plus(taxAmount);
      arg.row.subTotal = subtotal.toNumber();
    } else if (isTaxIncl && !arg.isLuxury) {
      const inclusiveRate = new Decimal(arg.salesInclusiveTaxRate || 0)
        .dividedBy(100)
        .plus(1);
      const beforeDpp = new Decimal(grossValue)
        .dividedBy(inclusiveRate)
        .todp(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP)
        .toNumber();
      arg.row.grossValue = beforeDpp;
      const taxBase = new Decimal(beforeDpp)
        .times(arg.taxBaseVariable)
        .toDecimalPlaces(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP)
        .toNumber();
      arg.row.taxableValue = taxBase;
      const gross = new Decimal(arg.row.price)
        .times(arg.row.qty)
        .minus(arg.row.discountValue);
      const taxAmount = new Decimal(gross).minus(beforeDpp);
      arg.row.taxValue = taxAmount
        .todp(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_UP)
        .toNumber();

      const subtotal = new Decimal(beforeDpp).plus(taxAmount);
      arg.row.subTotal = subtotal.toNumber();
    } else {
      arg.row.taxableValue = grossValue.toNumber();
      arg.row.taxValue = 0;
      arg.row.subTotal = grossValue.toNumber();
    }
  }
}
