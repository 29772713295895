


































































































































import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import {
  useFindMasterTaxType,
  useLocalFilter,
  useMapMasterTypeToOptionAlt,
  useTax,
} from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataTax } from "@/models/interface/settings.interface";
import { State } from "@/store/ApPrepayment.store";
import {
  checkTaxNumber,
  formatTaxNumber,
  formatTaxNumbertoNumber,
} from "@/validator/globalvalidator";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

const Props = Vue.extend({
  props: ["disabled"],
});

@Component({
  inject: {
    formTable: {
      from: "formTable",
    },
  },
  computed: {
    ...mapState({
      store: (state: any) => state.aPPrepaymentStore,
    }),
    ...mapGetters({
      inclusiveTaxRate: "aPPrepaymentStore/getInclusiveTaxRate",
    }),
  },
  methods: {
    ...mapMutations({
      setTaxRate: "aPPrepaymentStore/setTaxRate",
      setInclusiveTaxRate: "aPPrepaymentStore/setInclusiveTaxRate",
      setFormMeta: "aPPrepaymentStore/setFormMeta",
    }),
    ...mapActions({
      doCalculation: "aPPrepaymentStore/doCalculation",
    }),
  },
})
export default class TableTaxDetailsPrepayment extends Props {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  useLocalFilter = useLocalFilter;
  store!: State;
  formTable!: WrappedFormUtils;
  setTaxRate!: (payload: number) => void;
  doCalculation!: (payload: {
    amount: number;
    taxType: TAX_CALCULATION;
  }) => void;
  setFormMeta!: (payload: State["formMeta"]) => void;

  optTaxType: Array<Option> = [];
  optTaxCode: Array<Option<DataTax>> = [];
  optInclusiveTaxRate: Array<Option<DataTax>> = [];

  loading = {
    taxType: false,
    taxCode: false,
    inclusiveTaxRate: false,
  };

  get formRules() {
    return {
      taxType: {
        label: "lbl_tax_type",
        name: "taxType",
        placeholder: "lbl_tax_type",
        decorator: [
          "taxType",
          {
            initialValue: TAX_CALCULATION.EXCLUSIVE,
            rules: [
              {
                required: true,
                message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
              },
            ],
          },
        ],
      },
      taxCode: {
        label: "lbl_tax_code",
        name: "taxCode",
        placeholder: "lbl_tax_code",
        decorator: [
          "taxCode",
          {
            rules: [
              {
                required: true,
                message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
              },
            ],
          },
        ],
      },
      taxRegistrationNumber: {
        label: "lbl_tax_registration_number",
        name: "taxRegistrationNumber",
        placeholder: "lbl_tax_registration_number",
        decorator: [
          "taxRegistrationNumber",
          {
            rules: [
              {
                required: true,
                message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
              },
              {
                validator: checkTaxNumber,
              },
            ],
          },
        ],
      },
      taxRegistrationName: {
        label: "lbl_tax_registration_name",
        name: "taxRegistrationName",
        placeholder: "lbl_tax_registration_name",
        decorator: ["taxRegistrationName"],
      },
      taxInvoiceDate: {
        label: "lbl_tax_invoice_date",
        name: "taxInvoiceDate",
        placeholder: "lbl_tax_invoice_date",
        decorator: [
          "taxInvoiceDate",
          {
            rules: [
              {
                required: true,
                message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
              },
            ],
          },
        ],
      },
      taxInvoiceNumber: {
        label: "lbl_tax_invoice_number",
        name: "taxInvoiceNumber",
        placeholder: "lbl_tax_invoice_number",
        decorator: ["taxInvoiceNumber"],
      },
      inclusiveTaxRate: {
        label: "lbl_inclusive_tax_rate",
        name: "inclusiveTaxRate",
        placeholder: "lbl_inclusive_tax_rate",
        decorator: [
          "inclusiveTaxRate",
          {
            rules: [
              {
                required: this.isInclusive,
                message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
              },
            ],
          },
        ],
      },
    };
  }

  get isInclusive() {
    return this.store.formMeta.taxType === TAX_CALCULATION.INCLUSIVE;
  }

  created(): void {
    this.onSearchTaxCode = debounceProcess(this.onSearchTaxCode);
    this.onSearchInclusiveTaxRate = debounceProcess(
      this.onSearchInclusiveTaxRate
    );

    this.fetchTaxType();
    this.fetchInitialTaxCode();
  }

  fetchInitialTaxCode(): void {
    this.loading.taxCode = true;
    this.loading.inclusiveTaxRate = true;

    this.fetchTaxCode(new RequestQueryParams())
      .then(response => {
        this.optTaxCode = response;
        this.optInclusiveTaxRate = response;
      })
      .finally(() => {
        this.loading.taxCode = false;
        this.loading.inclusiveTaxRate = false;
      });
  }

  onBlurTaxRegNumb(val: string): void {
    this.formTable.setFieldsValue({
      taxRegistrationNumber: formatTaxNumber(val),
    });
  }

  onFocusTaxRegNumb(val: string): void {
    this.formTable.setFieldsValue({
      taxRegistrationNumber: formatTaxNumbertoNumber(val),
    });
  }

  fetchTaxType(): void {
    this.loading.taxType = true;
    useFindMasterTaxType()
      .then(response => {
        const filtered = response.filter(e => e.value.toUpperCase() !== "NONE");
        this.optTaxType = useMapMasterTypeToOptionAlt(filtered);
      })
      .finally(() => {
        this.loading.taxType = false;
      });
  }

  async fetchTaxCode(
    params: RequestQueryParamsModel
  ): Promise<Array<Option<DataTax>>> {
    const { findAll } = useTax();
    const builder = new SearchBuilder();

    // find only VAT_IN
    const q: Array<string> = [
      builder.push(["taxType", "VAT_IN"], { like: "both" }).build(),
    ];

    const copy = { ...params };
    if (copy.search) {
      q.unshift(copy.search);
    }

    params.search = q.join(builder.AND);

    const res = await findAll(params);
    return res.data.map(item => ({
      key: item.id,
      label: item.code || "-",
      value: item.code || "",
      meta: item,
    }));
  }

  onSearchTaxCode(search = ""): void {
    const { filterBy } = useTax();
    const params = new RequestQueryParams();
    params.search = filterBy({ code: search });

    this.loading.taxCode = true;
    this.fetchTaxCode(params)
      .then(response => {
        this.optTaxCode = response;
      })
      .finally(() => {
        this.loading.taxCode = false;
      });
  }

  onSearchInclusiveTaxRate(search = ""): void {
    const { filterBy } = useTax();
    const params = new RequestQueryParams();
    params.search = filterBy({ code: search });

    this.loading.inclusiveTaxRate = true;
    this.fetchTaxCode(params)
      .then(response => {
        this.optInclusiveTaxRate = response;
      })
      .finally(() => {
        this.loading.inclusiveTaxRate = false;
      });
  }

  onChangeTaxCode(e: string | null): void {
    this.formTable.setFieldsValue({
      taxCode: e,
    });

    // side effect !!
    // set tax rate into store whenever tax code is changed
    const opt = this.optTaxCode.find(item => item.value === e);
    if (opt && opt.meta) {
      this.setTaxRate(opt.meta.rate);
    }

    const { amount, taxType } = this.formTable.getFieldsValue();
    this.doCalculation({ amount, taxType });
  }

  onChangeInclusiveTaxRate(e: string | null): void {
    this.formTable.setFieldsValue({
      inclusiveTaxRate: e,
    });

    // side effect !!
    // set tax rate into store whenever tax code is changed
    const opt = this.optInclusiveTaxRate.find(item => item.value === e);
    if (opt && opt.meta) {
      this.setInclusiveTaxRate(opt.meta.rate);
    }

    const { amount, taxType } = this.formTable.getFieldsValue();
    this.doCalculation({ amount, taxType });
  }

  onChangeTaxType(e: string | null): void {
    this.formTable.setFieldsValue({
      taxType: e,
    });
    this.setFormMeta({ taxType: e as TAX_CALCULATION });
    const { amount, taxType } = this.formTable.getFieldsValue();

    if (taxType !== "Inclusive") {
      // If not inclusive, reset the inclusive tax rate and remove the required validation
      this.formTable.setFieldsValue({
        inclusiveTaxRate: undefined,
      });
    } else {
      // If inclusive, make it required
    }

    this.doCalculation({ amount, taxType });
  }
}
