// Core
// Class
import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
// Interfaces
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  RequestReceivingItems,
  ResponseListOfReceivingItems,
} from "@/models/interface/logistic.interface";
import {
  SupplierReturnCreateResponseDto,
  SupplierReturnDetailDto,
  SupplierReturnListDto,
  SupplierReturnRequestDto,
} from "@/models/interface/supplier-return";
import { HttpClient } from "@/services/HttpClient.service";
import { AxiosError } from "axios";

class SupplierReturnService extends HttpClient {
  getListReturnToSupplier(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<SupplierReturnListDto>> {
    return this.get<Pagination<SupplierReturnListDto>>(Api.ReturnToSupplier, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailReturnToSupplier(id: string): Promise<SupplierReturnDetailDto> {
    return this.get<SupplierReturnDetailDto>(`${Api.ReturnToSupplier}/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createReturnToSupplier(
    payload: SupplierReturnRequestDto
  ): Promise<SupplierReturnCreateResponseDto> {
    return this.post<SupplierReturnCreateResponseDto, SupplierReturnRequestDto>(
      Api.ReturnToSupplier,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadReturnToSupplier(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.ReturnToSupplierDownload, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListGrReturnToSupplier(
    params: RequestQueryParamsModel
  ): Promise<ResponseListOfReceivingItems> {
    return this.get<ResponseListOfReceivingItems>(Api.GrReturnToSupplier, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailGrReturnToSupplier(path: string): Promise<RequestReceivingItems> {
    return this.get<RequestReceivingItems>(
      `${Api.GrDetailReturnToSupplier}/${path}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const supplierReturnService = new SupplierReturnService();
