import { initDTO } from "@/builder/supplier-return/SupplierReturnDtoBuilder";
import { DATE_FORMAT_YYYY_MM_DD } from "@/models/constants/date.constant";
import {
  SupplierReturnSourceTypeEnum,
  SupplierReturnTypeEnum,
} from "@/models/enums/supplier-return.enum";
import {
  FormFinderValues,
  FormValues,
  SupplierReturnLineLocationState,
  SupplierReturnLineRequestDto,
  SupplierReturnLineState,
  SupplierReturnLocationDto,
  SupplierReturnRequestDto,
} from "@/models/interface/supplier-return";
import { PropsModel } from "@/models/interfaces/common.interface";
import moment from "moment";

export class SupplierReturnMapper {
  static toLocationLineDTO(
    lines: SupplierReturnLineLocationState[]
  ): SupplierReturnLocationDto[] {
    return lines
      .filter(item => item.qtyAvailable > 0 && item.qtyReturn > 0)
      .map<SupplierReturnLocationDto>(item => ({
        location: item.location,
        locationId: item.locationId,
        qtyAvailable: item.qtyAvailable || 0,
        qtyReturn: item.qtyReturn || 0,
        serialNumber: item.serialNumber ?? null,
      }));
  }

  static toLineDto(
    lines: SupplierReturnLineState[]
  ): SupplierReturnLineRequestDto[] {
    return lines.map<SupplierReturnLineRequestDto>(item => ({
      grNumber: item.grNumber,
      grLineId: item.grLineId,
      apLineId: item.apLineId,
      invoiceNumber: item.invoiceNumber,
      productId: item.productId,
      uomId: item.uomId,
      qty: item.qtyReceivedInvoiced || 0,
      price: item.price || 0,
      qtyReturn: item.qtyReturn || 0,
      totalPrice: item.total || 0,
      locationDataList: SupplierReturnMapper.toLocationLineDTO(
        item.listLocation
      ),
    }));
  }

  static transformReturnFrom(
    params: SupplierReturnSourceTypeEnum
  ): SupplierReturnTypeEnum {
    if (params === "Good Receipt") {
      return "GR_PRICE";
    } else {
      return "INVOICE_AP";
    }
  }

  static toCreateDto(
    form: {
      finder: FormFinderValues;
      detail: FormValues;
      additional: PropsModel;
    },
    lines: SupplierReturnLineState[]
  ): SupplierReturnRequestDto {
    const { finder, detail, additional } = form;
    const request: SupplierReturnRequestDto = initDTO();
    request.supplierId = finder.supplierId;
    request.poNumber = detail.poNumber;
    request.branchId = additional.branchId;
    request.returnDate = moment(detail.returnDate).format(
      DATE_FORMAT_YYYY_MM_DD
    );
    request.currency = detail.currency;
    request.rates = detail.rate || 1;
    request.returnFrom = SupplierReturnMapper.transformReturnFrom(
      finder.returnFrom
    );
    request.refundOptions = detail.refundOption || "NONE";
    request.notes = detail.notes;

    if (finder.returnFrom === "Good Receipt") {
      request.grDate = moment(detail.grDate).format(DATE_FORMAT_YYYY_MM_DD);
      request.grNumber = detail.grNumber;
    } else if (finder.returnFrom === "Invoice") {
      request.invoiceNumber = detail.invoiceNumber;
      request.invoiceDate = detail.invoiceDate;
    }

    request.lineRequestDTO = SupplierReturnMapper.toLineDto(lines).filter(
      item => item.locationDataList.length
    );

    return request;
  }
}
