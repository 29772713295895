// Class
import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  InvoiceArCreateDto,
  InvoiceArResponseDto,
  InvoiceArUpdateDto,
} from "@/models/interface/account-receivable";
import { ArReceiptReportHeadDto } from "@/models/interface/ar-receipt-report";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { ReportInvControllingResponseDto } from "@/models/interface/invoice-controlling-report";
import {
  ApproveInvoice,
  CancelRejectInvoice,
  DataResponseGenerateInvoiceRecurring,
  GenerateReceipt,
  ParamsPrintInvoiceAr,
  PostInvoice,
  PrintTitleRequestDto,
  PrintTitleResponseDto,
  ReceivablesMutationReportDTO,
  RequestCreateInputPph,
  RequestGenerateInvoiceRecurring,
  RequestPayloadJoinInvoice,
  ResponseCreateInputPph,
  ResponseDetailInputPph,
  ResponseDetailInvoiceAR,
  ResponseJoinInvoiceDetail,
  ResponseListArInvoice,
  ResponseListInputPph,
  ResponseListInvoiceARAvailableInputPph,
  ResponseListInvoiceARForReportTax,
  ResponseListJoinInvoiceAndPrepayment,
  ResponseListJoinInvoiceNumber,
  ResponsePostInvoice,
  ResponseSummaryInvoiceAR,
} from "@/models/interface/invoice.interface";
import { MarginUnitHeadReportResponseDTO } from "@/models/interface/ReportMarginUnit.interface";
import {
  IcBillingResponseDto,
  InvoiceIcBillingLineCreateDto,
  InvoiceIcBillingResponseDto,
} from "@interface/ic-billing";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class InvoiceServices extends HttpClient {
  getListInvoiceARAvailableForInputPph = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListInvoiceARAvailableInputPph[]> => {
    return this.get<ResponseListInvoiceARAvailableInputPph[]>(
      Api.ARAvailableInputPPh,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDetailInputPph(id: string): Promise<ResponseDetailInputPph> {
    return this.get<ResponseDetailInputPph>(Api.InputPPh + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListInputPph = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListInputPph> => {
    return this.get<ResponseListInputPph>(Api.InputPPh, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListJoinInvoiceNumber = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListJoinInvoiceNumber> => {
    return this.get<ResponseListJoinInvoiceNumber>(Api.JoinInvoice, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getInvoiceDetailSummary(id: string): Promise<ResponseSummaryInvoiceAR> {
    return this.get<ResponseSummaryInvoiceAR>(`${Api.InvoiceARSummary}/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailJoinInvoiceNumber = (
    id: string
  ): Promise<ResponseJoinInvoiceDetail> => {
    return this.get<ResponseJoinInvoiceDetail>(Api.JoinInvoice + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListJoinInvoiceAndPrepayment = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListJoinInvoiceAndPrepayment> => {
    return this.get<ResponseListJoinInvoiceAndPrepayment>(
      Api.JoinInvoiceAndPrepayment,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListReportInvoiceControlling(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<ReportInvControllingResponseDto>> {
    return this.get<Pagination<ReportInvControllingResponseDto>>(
      Api.UnitControllingV2,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadReportInvoiceControlling(
    params?: RequestQueryParamsModel
  ): Promise<Blob> {
    return this.get<Blob>(Api.UnitControllingDownloadV2, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListInvoiceARV2(
    params?: RequestQueryParamsModel
  ): Promise<ResponseListArInvoice> {
    return this.get<ResponseListArInvoice>(Api.InvoiceAR + "/list", { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListInvoiceAR(
    params?: RequestQueryParamsModel
  ): Promise<ResponseListArInvoice> {
    return this.get<ResponseListArInvoice>(Api.InvoiceAR, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListInvoiceARForReportTax = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListInvoiceARForReportTax> => {
    return this.get<ResponseListInvoiceARForReportTax>(
      Api.InvoiceAR + "/tax-invoice-list",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  downloadReportReceivableMutation = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(
      Api.InvoiceAR + "/report-receivables-mutation/download",
      { params, responseType: "arraybuffer" }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListReceivableMutation(params?: RequestQueryParamsModel) {
    return this.get<ReceivablesMutationReportDTO>(
      Api.InvoiceAR + "/report-receivables-mutation",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListReportMarginUnit(
    params: RequestQueryParamsModel
  ): Promise<MarginUnitHeadReportResponseDTO> {
    return this.get<MarginUnitHeadReportResponseDTO>(Api.ReportMarginUnitV2, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadReportMarginUnit(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.ReportMarginUnitV2 + "/report/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * use {@linkcode getDetailInvoiceAr}
   */
  getDetailInvoiceAR(id: string): Promise<ResponseDetailInvoiceAR> {
    return this.get<ResponseDetailInvoiceAR>(Api.InvoiceAR + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailInvoiceAr(id: string): Promise<InvoiceArResponseDto> {
    return this.get<InvoiceArResponseDto>(`${Api.InvoiceAR}/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailInvoiceRecurring(
    ids: Array<string>
  ): Promise<InvoiceIcBillingResponseDto> {
    return this.get<InvoiceIcBillingResponseDto>(
      `${Api.InvoiceArDetailIcBillings}/${ids}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListInvoiceRecurringBap(
    params: RequestQueryParamsModel
  ): Promise<Pagination<InvoiceIcBillingLineCreateDto>> {
    return this.get<Pagination<InvoiceIcBillingLineCreateDto>>(
      Api.InvoiceArListBap,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  validateGenerateBap(ids: string[]): Promise<IcBillingResponseDto[]> {
    return this.get<IcBillingResponseDto[]>(
      `${Api.InvoiceArBapValidation}/${ids}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  generateInvoiceRecurringBap = (
    payload: RequestGenerateInvoiceRecurring
  ): Promise<DataResponseGenerateInvoiceRecurring> => {
    return this.post<
      DataResponseGenerateInvoiceRecurring,
      RequestGenerateInvoiceRecurring
    >(Api.InvoiceRecurring + "/generate", payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  createInputPph = (
    payload: RequestCreateInputPph
  ): Promise<ResponseCreateInputPph> => {
    return this.post<ResponseCreateInputPph, RequestCreateInputPph>(
      Api.InputPPh,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  cancelPphByIds(payload: string): Promise<Array<ResponseCreateInputPph>> {
    return this.put<Array<ResponseCreateInputPph>, unknown>(
      Api.InputPPh + "/cancel",
      null,
      { params: { id: payload } }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createJoinInvoice = (
    payload: RequestPayloadJoinInvoice
  ): Promise<ResponseJoinInvoiceDetail> => {
    return this.post<ResponseJoinInvoiceDetail, RequestPayloadJoinInvoice>(
      Api.JoinInvoice,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * use {@linkcode createInvoiceAr}
   */
  createInvoice(payload: PostInvoice): Promise<ResponsePostInvoice> {
    return this.post<ResponsePostInvoice>(Api.InvoiceAR, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createInvoiceAr(payload: InvoiceArCreateDto): Promise<InvoiceArResponseDto> {
    return this.post<InvoiceArResponseDto, InvoiceArCreateDto>(
      Api.InvoiceAR,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  generateReceipt = (data: string): Promise<GenerateReceipt> => {
    return this.post<any, GenerateReceipt>(Api.GenerateArReceipt + `/${data}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  printInvoice(id: string, params: ParamsPrintInvoiceAr): Promise<Blob> {
    return this.get<Blob>(Api.InvoiceAR + `/print/${id}`, {
      responseType: "blob",
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  printTaxInvoice = (params: RequestQueryParamsModel): Promise<ArrayBuffer> => {
    return this.get<ArrayBuffer>(Api.ReportTaxInvoice, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getInvoiceCode = (
    id: string,
    params: RequestQueryParamsModel
  ): Promise<any> => {
    return this.get<any>(Api.InvoiceARCode + "/" + id, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  printReceipt(
    payload: GenerateReceipt,
    params: RequestQueryParamsModel
  ): Promise<Blob> {
    return this.post<Blob, GenerateReceipt>(Api.PrintArReceipt, payload, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  approveInvoice = (payload: ApproveInvoice[], id: string): Promise<any> => {
    return this.post<any, any>(Api.InvoiceARApprove + `/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * use {@linkcode updateInvoiceAr}
   */
  updateInvoice(
    payload: PostInvoice,
    id: string
  ): Promise<ResponsePostInvoice> {
    return this.put<ResponsePostInvoice, PostInvoice>(
      Api.InvoiceAR + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateInvoiceAr(
    id: string,
    payload: InvoiceArUpdateDto
  ): Promise<InvoiceArResponseDto> {
    return this.put<InvoiceArResponseDto, InvoiceArUpdateDto>(
      `${Api.InvoiceAR}/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  postJournal(id: string): Promise<ResponsePostInvoice> {
    return this.post<ResponsePostInvoice, string>(
      Api.InvoiceAR + `/post-journal/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * use {@linkcode submitInvoiceAr}
   */
  submitInvoice(
    payload: PostInvoice,
    id: string
  ): Promise<ResponsePostInvoice> {
    return this.post<ResponsePostInvoice, PostInvoice>(
      Api.InvoiceARSubmit + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  submitInvoiceAr(
    id: string,
    payload: InvoiceArUpdateDto
  ): Promise<InvoiceArResponseDto> {
    return this.post<InvoiceArResponseDto, InvoiceArUpdateDto>(
      `${Api.InvoiceARSubmit}/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * use {@linkcode cancelArInvoice}
   */
  cencelInvoice(
    payload: CancelRejectInvoice,
    id: string
  ): Promise<ResponsePostInvoice> {
    return this.post<ResponsePostInvoice, CancelRejectInvoice>(
      Api.InvoiceARCancel + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  cancelArInvoice(id: string): Promise<InvoiceArResponseDto> {
    return this.post<InvoiceArResponseDto>(`${Api.InvoiceARCancel}/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * use {@linkcode rejectInvoiceAr}
   */
  rejectInvoice = (
    payload: CancelRejectInvoice,
    id: string
  ): Promise<ResponsePostInvoice> => {
    return this.post<ResponsePostInvoice, CancelRejectInvoice>(
      Api.InvoiceARReject + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  rejectInvoiceAr(id: string): Promise<InvoiceArResponseDto> {
    return this.post<InvoiceArResponseDto>(`${Api.InvoiceARReject}/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  cancelJoinInvoice = (id: string): Promise<boolean> => {
    return this.post<boolean, string>(Api.JoinInvoice + `/cancel/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  deleteJoinInvoice = (id: string): Promise<boolean> => {
    return this.delete<boolean>(Api.JoinInvoice + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getAllArReceiptReport(
    params?: RequestQueryParamsModel
  ): Promise<ArReceiptReportHeadDto> {
    return this.get<ArReceiptReportHeadDto>(
      Api.InvoiceAR + "/report-ar-receipt",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadArReceiptReport(params?: RequestQueryParamsModel) {
    return this.get<Blob>(Api.InvoiceAR + "/report-ar-receipt/download", {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getPrintTitle(): Promise<PrintTitleResponseDto[]> {
    return this.get<PrintTitleResponseDto[]>(Api.InvoiceAR + "/print-title")
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  addPrintTitle(payload: PrintTitleRequestDto): Promise<string> {
    return this.post<string, PrintTitleRequestDto>(
      Api.InvoiceAR + "/print-title",
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}
export const invoiceServices = new InvoiceServices();
