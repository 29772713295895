






































































































































import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectProduct from "@/components/custom/select/SelectProduct.vue";
import SelectProductCategory from "@/components/custom/select/SelectProductCategory.vue";
import SelectRack from "@/components/custom/select/SelectRack.vue";
import SelectWarehouse from "@/components/custom/select/SelectWarehouse.vue";
import { APagination, useBlob, usePrint } from "@/hooks";
import useInventory, { FormFilter } from "@/hooks/useInventory";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { InventoryLineResponseDto } from "@/models/interface/inventory";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { ProductResponseDto } from "@/models/interface/master-product";
import { ProductCategoryResponseDto } from "@/models/interface/product-category";
import { WarehouseResponseDto } from "@/models/interface/warehouse";
import { FormModel } from "ant-design-vue";
import moment from "moment";
import { Component, Mixins, Ref } from "vue-property-decorator";

@Component({
  components: {
    SelectBranch,
    SelectWarehouse,
    SelectRack,
    SelectProductCategory,
    SelectProduct,
  },
})
export default class IndexPage extends Mixins(MNotificationVue) {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  @Ref("formModel")
  formModel!: FormModel;

  form: FormFilter = {
    branchId: "",
    branchName: "",
    warehouseId: "",
    warehouseName: "",
    rack: undefined,
    partCategoryId: "",
    partCategoryName: "",
    partId: "",
    partName: "",
    partNumber: "",
    brand: "",

    reset(): void {
      this.branchId = "";
      this.branchName = "";
      this.warehouseId = "";
      this.warehouseName = "";
      this.rack = undefined;
      this.partCategoryId = "";
      this.partCategoryName = "";
      this.partId = "";
      this.partName = "";
      this.partNumber = "";
      this.brand = "";
    },
  };

  loading = {
    print: false,
    download: false,
    find: false,
  };

  dataReport: Pagination<InventoryLineResponseDto> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  pagination = {
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
    reset(): void {
      this.page = DEFAULT_PAGE;
    },
  };

  columns = [
    {
      title: this.$t("lbl_location"),
      dataIndex: "warehouseLocationName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "product.code",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "product.name",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "uom.unit",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_stock_on_order"),
      dataIndex: "onOrder",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_stock_on_hand_available_plus_reserved"),
      dataIndex: "onHand",
      width: 300,
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_stock_available"),
      dataIndex: "available",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_stock_reserved"),
      dataIndex: "reserved",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_stock_on_transit"),
      dataIndex: "onTransit",
      scopedSlots: { customRender: "number" },
    },
  ];

  get now(): string {
    return moment().format();
  }

  buildParams(): RequestQueryParams {
    const { buildQuerySearch } = useInventory();
    const params = new RequestQueryParams();
    const { limit, page } = this.pagination;
    const {
      branchId,
      branchName,
      warehouseName,
      partId,
      partNumber,
      partName,
      brand,
      partCategoryId,
      partCategoryName,
      warehouseId,
      rack,
    } = this.form;
    const { filter } = buildQuerySearch({
      branchId,
      brand,
      warehouseId,
      rackId: rack?.key,
      productCategoryId: partCategoryId,
      productId: partId,
    });

    params.search = filter;
    params.page = page - ONE;
    params.limit = limit;

    const company = this.$store.state.authStore.authData.companyName;
    const reportHeader: Array<string> = [
      company?.replaceAll(", ", " - ") || "ALL",
      branchName?.replaceAll(", ", " - ") || "ALL",
      warehouseName?.replaceAll(", ", " - ") || "ALL",
      rack?.label.replaceAll(", ", " - ") || "ALL",
      partCategoryName?.replaceAll(", ", " - ") || "ALL",
      partName?.replaceAll(", ", " - ") || "ALL",
      brand?.replaceAll(", ", " - ") || "ALL",
      partNumber?.replaceAll(", ", " - ") || "ALL",
    ];
    params.params = reportHeader.join(",");

    return params;
  }

  handleFind(): void {
    this.pagination.reset();
    const { params, ...rest } = this.buildParams();
    this.findData(rest);
  }

  findData(params?: RequestQueryParamsModel): void {
    const { findAllInventoryLine } = useInventory();
    this.loading.find = true;
    findAllInventoryLine(params)
      .then(res => {
        this.dataReport = res;
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  onTableChange({ current, pageSize }: APagination): void {
    this.pagination.page =
      pageSize !== this.pagination.limit ? DEFAULT_PAGE : current;
    this.pagination.limit = pageSize;

    const { params, ...rest } = this.buildParams();
    this.findData(rest);
  }

  onChangeBranch(e?: Option<DataWarehouseBranch>): void {
    this.form.branchName = e?.label || "";
  }

  onChangeWarehouse(e?: Option<WarehouseResponseDto>): void {
    this.form.warehouseName = e?.label || "";
  }

  onChangePartCategory(e?: Option<ProductCategoryResponseDto>): void {
    this.form.partCategoryName = e?.label || "";
  }

  onChangePart(e?: Option<ProductResponseDto>): void {
    this.form.partId = e?.meta?.id || "";
    this.form.partNumber = e?.meta?.code || "";
    this.form.partName = e?.meta?.name || "";
    this.form.brand = e?.meta?.merk || "";
  }

  handleDownload(): void {
    const { toDownload } = useBlob();
    const { download } = useInventory();
    const { search, params } = this.buildParams();
    this.loading.download = true;
    download({ search, params })
      .then(res => {
        toDownload(res, "list_of_product.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  handlePrint(): void {
    const { print: printReport } = useInventory();
    const { print } = usePrint();
    const { toObjectUrl } = useBlob();
    const { search, params } = this.buildParams();
    this.loading.print = true;
    printReport({ search, params })
      .then(res => {
        print(toObjectUrl(res));
      })
      .finally(() => {
        this.loading.print = false;
      });
  }

  handleReset(): void {
    this.formModel.resetFields();
    this.form.reset();
  }
}
